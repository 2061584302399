var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useQueueAPI } from '../../queue/selectors';
import { useTrainerQaAPI } from '../selectors';
import { StaffCalculate } from '../types';
import { CalculatorForm } from './calculatorForm';
import { MONITOR_INPUT, QA_ROLE } from './constants';
var calculateMonitorQARatio = function (formData) {
    var monitorPerAgent = formData.monitorPerAgent || 0;
    var numberMonitorReq = Math.ceil(monitorPerAgent * formData.numberReqAgent);
    var evalCompletionTime = formData.evalCompletionTime || 0;
    var totalEvalTime = formData.baseInteractionAHT + evalCompletionTime;
    var productionHour = (numberMonitorReq * totalEvalTime) / 3600;
    var shrinkage = formData.shrinkage || 0;
    var staffEstimate = Math.ceil(productionHour / 150 / (1 - shrinkage * 0.01));
    var qaRatio = staffEstimate ? Math.ceil(formData.numberReqAgent / staffEstimate) : null;
    return __assign(__assign({}, formData), { monitorPerAgent: monitorPerAgent, evalCompletionTime: evalCompletionTime, shrinkage: shrinkage, numberMonitorReq: Math.ceil(numberMonitorReq), totalEvalTime: Math.round(totalEvalTime), productionHour: Math.round(productionHour), staffEstimate: staffEstimate, qaRatio: qaRatio });
};
export var MonitorForm = function (_a) {
    var _b, _c;
    var setValue = _a.setValue, watch = _a.watch;
    var queueData = useQueueAPI()[0];
    var roles = (_c = (_b = queueData.data) === null || _b === void 0 ? void 0 : _b.roles) !== null && _c !== void 0 ? _c : [];
    var qaData = useTrainerQaAPI()[0];
    var _d = useForm(), control = _d.control, reset = _d.reset, handleSubmit = _d.handleSubmit;
    var qaIdx = roles.findIndex(function (item) { return item.role === QA_ROLE; });
    var isFTEBased = watch("roles.".concat(qaIdx, ".staffCalculate")) === StaffCalculate.FTE;
    useEffect(function () {
        if (qaData.data) {
            reset({
                baseInteractionAHT: qaData.data.interactionAht,
                interactionAHT: Math.round(qaData.data.interactionAht),
                numberReqAgent: isFTEBased ? qaData.data.agentsFte : qaData.data.headcountFte,
            });
        }
    }, [isFTEBased, reset, qaData.data]);
    var handleFormSubmit = handleSubmit(function (values) {
        var newValues = calculateMonitorQARatio(values);
        reset(newValues);
        if (newValues.qaRatio) {
            setValue("roles.".concat(qaIdx, ".ratio"), newValues.qaRatio, {
                shouldDirty: true,
            });
        }
    });
    return _jsx(CalculatorForm, { data: MONITOR_INPUT, control: control, onSubmit: handleFormSubmit });
};
