var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Grid, Paper, styled } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { FallbackComponent } from '../../common/fallback';
import { useWizardParams } from '../selectors';
export var StepPanel = styled(Paper)(function (_a) {
    var theme = _a.theme;
    return ({
        position: 'relative',
        padding: theme.spacing(8),
        borderRadius: theme.shape.borderRadius,
        height: '100%',
        overflow: 'auto',
    });
});
export var StepContainer = function (_a) {
    var leftContent = _a.leftContent, children = _a.children;
    var step = useWizardParams().step;
    var watch = useFormContext().watch;
    return (_jsxs(Grid, __assign({ container: true, spacing: 6 }, { children: [leftContent && (_jsx(Grid, __assign({ item: true, xs: 4, lg: 3 }, { children: _jsx(StepPanel, __assign({ sx: { bgcolor: 'primary.light' } }, { children: leftContent })) }))), _jsx(Grid, __assign({ item: true, xs: leftContent ? 8 : 12, lg: leftContent ? 9 : 12 }, { children: step && !watch('id') ? _jsx(FallbackComponent, { requesting: true }) : children }))] })));
};
