var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a, _b, _c;
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { PlanType } from '../../enum';
import { FallbackComponent } from '../common';
import { useDialog } from '../hooks';
import { ViewType } from '../report/resourcing';
import { useReportAPI, useViewTypeParams } from '../report/selectors';
import { useIsCompletedSimulation } from '../toastify/selector';
import { WizardStep } from '../wizard/constants';
import { FinancialTable, ResourcingTable } from './annualTable';
import { DeleteScenarioDialog } from './deleteDialog';
import { EditScenarioDialog } from './editDialog';
import { FinancialGraphical, ResourcingGraphical } from './graphical';
import { MultiskillDeleteDialog } from './multiskillDeleteDialog';
import { MultiskillDialog } from './multiskillDialog';
import { RenameScenarioDialog } from './renameDialog';
import { useSummaryReportAPI } from './selectors';
import { SwitchScenarioDialog } from './switchDialog';
import { DialogMode } from './types';
export var downloadFileName = (_a = {},
    _a[ViewType.Graphical] = (_b = {},
        _b[PlanType.Monthly] = 'Graphical_Output_Annual',
        _b[PlanType.Weekly] = 'Graphical_Output_Summary',
        _b),
    _a[ViewType.Table] = (_c = {},
        _c[PlanType.Monthly] = 'Forecast_Output_Annual',
        _c[PlanType.Weekly] = 'Forecast_Output_Summary',
        _c),
    _a);
var AnnualReport = function (props) {
    var _a = useReportAPI(), reportData = _a[0], fetchReportData = _a[1];
    var basePlanId = useParams().basePlanId;
    var _b = useSummaryReportAPI(), summaryReportData = _b[0], fetchSummaryReport = _b[1], clearSummaryReportData = _b[2];
    var isCompletedSimulation = useIsCompletedSimulation(basePlanId);
    var needFetchData = useRef(true);
    useEffect(function () {
        needFetchData.current = true;
        return clearSummaryReportData;
    }, [clearSummaryReportData, fetchReportData, fetchSummaryReport]);
    useEffect(function () {
        if (needFetchData.current || isCompletedSimulation) {
            needFetchData.current = false;
            fetchSummaryReport();
            fetchReportData();
        }
    }, [clearSummaryReportData, fetchReportData, fetchSummaryReport, isCompletedSimulation]);
    return (_jsx(FallbackComponent, __assign({ requesting: !!summaryReportData.requesting || !!reportData.requesting }, { children: props.children })));
};
export var AnnualResourcingReport = function () {
    var viewType = useViewTypeParams()[0];
    var _a = useDialog(), dialogMode = _a.dialogMode, selectedScenario = _a.data, handleOpenDialog = _a.handleOpenDialog, handleCloseDialog = _a.handleCloseDialog;
    return (_jsxs(AnnualReport, { children: [viewType === ViewType.Table ? _jsx(ResourcingTable, { handleOpenDialog: handleOpenDialog }) : _jsx(ResourcingGraphical, {}), _jsx(DeleteScenarioDialog, { open: dialogMode === DialogMode.DeleteScenario, scenario: selectedScenario, onClose: handleCloseDialog }), _jsx(RenameScenarioDialog, { type: dialogMode, open: dialogMode === DialogMode.RenameScenario || dialogMode === DialogMode.RenameAfterSwitch, scenario: selectedScenario, onClose: handleCloseDialog }), _jsx(SwitchScenarioDialog, { handleOpenDialog: handleOpenDialog, open: dialogMode === DialogMode.SwitchScenario, scenario: selectedScenario, onClose: handleCloseDialog }), _jsx(MultiskillDialog, { open: dialogMode === DialogMode.Multiskill, onClose: handleCloseDialog, scenario: selectedScenario }), _jsx(MultiskillDeleteDialog, { open: dialogMode === DialogMode.MultiskillDelete, scenario: selectedScenario, onClose: handleCloseDialog }), _jsx(EditScenarioDialog, { open: dialogMode === DialogMode.DeleteAllMultiskill, scenario: selectedScenario, to: "/wizard/".concat(selectedScenario === null || selectedScenario === void 0 ? void 0 : selectedScenario.basePlanId, "/").concat(selectedScenario === null || selectedScenario === void 0 ? void 0 : selectedScenario.id, "?step=").concat(WizardStep.Review), onClose: handleCloseDialog })] }));
};
export var AnnualFinancialReport = function () {
    var viewType = useViewTypeParams()[0];
    var _a = useDialog(), dialogMode = _a.dialogMode, selectedScenario = _a.data, handleOpenDialog = _a.handleOpenDialog, handleCloseDialog = _a.handleCloseDialog;
    return (_jsxs(AnnualReport, { children: [viewType === ViewType.Table ? _jsx(FinancialTable, { handleOpenDialog: handleOpenDialog }) : _jsx(FinancialGraphical, {}), _jsx(RenameScenarioDialog, { type: DialogMode.RenameFinancial, open: dialogMode === DialogMode.RenameScenario, scenario: selectedScenario, onClose: handleCloseDialog })] }));
};
