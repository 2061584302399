var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Outlet } from '../common';
import { PageBody } from '../layout/styled';
import { useDisabledFinancial, useReportOutletContext } from './hooks';
import { ReportControl, ViewType } from './resourcing';
var FinancialControl = function () {
    var t = useTranslation('report').t;
    var isDisabledGraphical = useDisabledFinancial(ViewType.Graphical);
    return (_jsx(ReportControl, __assign({ disableDaily: true, disableGraphical: isDisabledGraphical }, { children: _jsx(Typography, { children: t('financial.description') }) })));
};
export var FinancialLayout = function () {
    var context = useReportOutletContext();
    return (_jsxs(PageBody, { children: [_jsx(FinancialControl, {}), _jsx(Outlet, { context: context })] }));
};
