var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { LoadingButton } from '@mui/lab';
import { Stack } from '@mui/material';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { StatusPermission } from '../../enum';
import { usePlatformContext, useUserPermissionsAPI } from '../app/selectors';
import { ConfirmDialog } from '../common';
import { STEPS_LENGTH } from './constants';
import { WizardContext } from './context';
import { useCompletedStep } from './queue/selectors';
import { useWizardParams } from './selectors';
export var Actions = function () {
    var t = useTranslation(['wizard', 'translation']).t;
    var permission = useUserPermissionsAPI();
    var step = useWizardParams().step;
    var _a = useState(false), open = _a[0], setOpen = _a[1];
    var _b = useState(false), openDialog = _b[0], setOpenDialog = _b[1];
    var _c = useSearchParams(), searchParams = _c[0], setSearchParams = _c[1];
    var _d = useCompletedStep(), completedStep = _d[0], type = _d[1];
    var activeStep = Math.min(step, completedStep);
    var _e = useContext(WizardContext), handleSave = _e.handleSave, actionRef = _e.actionRef, requesting = _e.requesting, goToStep = _e.goToStep, isDirty = _e.methods.formState.isDirty;
    var context = usePlatformContext();
    useEffect(function () {
        if (completedStep !== -1 && step !== activeStep) {
            searchParams.set('step', "".concat(activeStep));
            setSearchParams(searchParams, { replace: true });
        }
    }, [activeStep, completedStep, searchParams, setSearchParams, step]);
    var doNext = useCallback(function (_completedStep) {
        if (_completedStep === void 0) { _completedStep = completedStep; }
        if (step === _completedStep) {
            setOpen(true);
            return;
        }
        goToStep(step + 1);
    }, [completedStep, goToStep, step]);
    var handleClick = function () {
        if (isDirty) {
            setOpenDialog(true);
            return;
        }
        handleSave('previous', function () { return goToStep(step - 1); })();
    };
    var handleConfirm = function () {
        setOpenDialog(false);
        handleSave('previous', function () { return goToStep(step - 1); })();
    };
    return (_jsxs(Stack, __assign({ direction: "row", spacing: 4, justifyContent: "flex-end" }, { children: [!!step && (permission === null || permission === void 0 ? void 0 : permission.capacityPermission) === StatusPermission.Edit && (_jsx(LoadingButton, __assign({ loading: actionRef.current === 'previous' && requesting, variant: "outlined", onClick: handleClick }, { children: t('translation:action.previous') }))), step !== STEPS_LENGTH - 1 && (_jsx(LoadingButton, __assign({ loading: actionRef.current === 'next' && requesting, variant: "contained", onClick: handleSave('next', doNext) }, { children: t('translation:action.next') }))), _jsx(ConfirmDialog, { open: open, onClose: function () { return setOpen(false); }, message: t("message.".concat(type, "Incomplete"), { defaultValue: t('message.incomplete', { context: context }) }), cancelButtonText: "ok" }), isDirty && (_jsx(ConfirmDialog, { open: openDialog, onClose: function () { return setOpenDialog(false); }, title: t('translation:confirmation.unsavedChange'), message: t('translation:confirmation.confirmExit'), confirmButtonText: "saveContinue", skipSaveButtonText: "skipSave", onConfirm: handleConfirm, onSkipSaveConfirm: function () { return goToStep(step - 1); } }))] })));
};
