var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Grid, Paper, Stack, Typography, styled } from '@mui/material';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { ErrorMessageBox, FallbackComponent } from '../common';
import { useReportAPI } from '../report/selectors';
import { useExportAPI, usePrintTableAPI, useTrainingReportAPI, useUpdateHeaderTrainingAPI, useUpdateTableTrainingAPI, } from './selectors';
import { SummaryDialog } from './summary';
import { TrainingRecruitmentForm } from './trainingForm';
import { TrainingTable } from './trainingTable';
export var LeftPanel = styled(Paper)(function (_a) {
    var theme = _a.theme;
    return ({
        position: 'relative',
        padding: theme.spacing(8),
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.primary.light,
        height: '100%',
        overflow: 'auto',
    });
});
var validateForm = yup.object().shape({
    scenarioId: yup.number(),
    queueId: yup.number(),
    trainingLength: yup.number(),
    forecastedGraduationRate: yup.number(),
    classSize: yup.number(),
    recruitingLeadTime: yup.number(),
    trainings: yup
        .array()
        .of(yup.object().shape({
        id: yup.number().nullable(),
        trainingLength: yup.number().nullable(),
        forecastedGraduationRate: yup.number().nullable(),
        classSize: yup.number().nullable(),
        recruitingLeadTime: yup.number().nullable(),
        adjustedAttritionRate: yup.number().nullable(),
    }))
        .required(),
});
export var useDefaultValues = function (currentScenarioId, currentQueue) {
    var trainingHeader = currentQueue === null || currentQueue === void 0 ? void 0 : currentQueue.trainings.find(function (item) { return item.month === -1; });
    return useMemo(function () { return ({
        scenarioId: currentScenarioId,
        queueId: currentQueue === null || currentQueue === void 0 ? void 0 : currentQueue.id,
        trainingLength: trainingHeader === null || trainingHeader === void 0 ? void 0 : trainingHeader.trainingLength,
        forecastedGraduationRate: trainingHeader === null || trainingHeader === void 0 ? void 0 : trainingHeader.forecastedGraduationRate,
        classSize: trainingHeader === null || trainingHeader === void 0 ? void 0 : trainingHeader.classSize,
        recruitingLeadTime: trainingHeader === null || trainingHeader === void 0 ? void 0 : trainingHeader.recruitingLeadTime,
        trainings: _.map(currentQueue === null || currentQueue === void 0 ? void 0 : currentQueue.trainings.filter(function (item) { return item.month !== -1; }), function (_a) {
            var id = _a.id, trainingLength = _a.trainingLength, forecastedGraduationRate = _a.forecastedGraduationRate, classSize = _a.classSize, recruitingLeadTime = _a.recruitingLeadTime, adjustedAttritionRate = _a.adjustedAttritionRate;
            return ({
                id: id,
                trainingLength: trainingLength,
                forecastedGraduationRate: forecastedGraduationRate,
                classSize: classSize,
                recruitingLeadTime: recruitingLeadTime,
                adjustedAttritionRate: adjustedAttritionRate,
            });
        }),
    }); }, [
        currentQueue === null || currentQueue === void 0 ? void 0 : currentQueue.id,
        currentQueue === null || currentQueue === void 0 ? void 0 : currentQueue.trainings,
        currentScenarioId,
        trainingHeader === null || trainingHeader === void 0 ? void 0 : trainingHeader.classSize,
        trainingHeader === null || trainingHeader === void 0 ? void 0 : trainingHeader.forecastedGraduationRate,
        trainingHeader === null || trainingHeader === void 0 ? void 0 : trainingHeader.recruitingLeadTime,
        trainingHeader === null || trainingHeader === void 0 ? void 0 : trainingHeader.trainingLength,
    ]);
};
export var TrainingRecruitment = function () {
    var _a, _b, _c, _d, _e, _f;
    var t = useTranslation(['report']).t;
    var _g = useState(false), openSummary = _g[0], setOpenSummary = _g[1];
    var _h = useState(), currentScenarioId = _h[0], setCurrentScenarioId = _h[1];
    var _j = useState(), currentQueueId = _j[0], setCurrentQueueId = _j[1];
    var _k = useReportAPI(), reportData = _k[0], fetchReport = _k[1];
    var printData = usePrintTableAPI(currentScenarioId)[0];
    var exportData = useExportAPI()[0];
    var _l = useTrainingReportAPI(currentScenarioId), trainingReportData = _l[0], fetchTrainingReport = _l[1], clearTrainingReport = _l[2];
    var _m = useUpdateHeaderTrainingAPI(), updateHeaderTrainingData = _m[0], updateHeaderTraining = _m[1];
    var _o = useUpdateTableTrainingAPI(), updateTableTrainingData = _o[0], updateTableTraining = _o[1];
    var currenScenario = (_b = (_a = reportData.data) === null || _a === void 0 ? void 0 : _a.scenarios) === null || _b === void 0 ? void 0 : _b.find(function (sc) { return sc.id === currentScenarioId; });
    var currentQueue = (_d = (_c = trainingReportData.data) === null || _c === void 0 ? void 0 : _c.queues) === null || _d === void 0 ? void 0 : _d.find(function (sc) { return sc.id === currentQueueId; });
    var trainingDataTable = (_e = currentQueue === null || currentQueue === void 0 ? void 0 : currentQueue.trainings) === null || _e === void 0 ? void 0 : _e.filter(function (item) { return item.month !== -1; });
    var isDeferrableQueue = currentQueue === null || currentQueue === void 0 ? void 0 : currentQueue.isDeferrable;
    var defaultValues = useDefaultValues(currentScenarioId, currentQueue);
    var _p = useForm({
        defaultValues: defaultValues,
        resolver: yupResolver(validateForm),
    }), control = _p.control, reset = _p.reset, handleSubmit = _p.handleSubmit, setValue = _p.setValue, watch = _p.watch;
    var _q = watch(), trainingLength = _q.trainingLength, forecastedGraduationRate = _q.forecastedGraduationRate, classSize = _q.classSize, recruitingLeadTime = _q.recruitingLeadTime, trainings = _q.trainings;
    var isEmptyHeaderForm = trainingLength === null || !forecastedGraduationRate || !classSize || !recruitingLeadTime;
    var isEmptyTableForm = _.some(trainings, function (obj) { return _.some(obj, function (prop) { return prop === null; }); });
    var isHeaderFormChange = !_.isEqual({ trainingLength: trainingLength, forecastedGraduationRate: forecastedGraduationRate, classSize: classSize, recruitingLeadTime: recruitingLeadTime }, _.pick(defaultValues, ['trainingLength', 'forecastedGraduationRate', 'classSize', 'recruitingLeadTime']));
    var isTableFormChange = !_.isEqual(trainings, defaultValues.trainings);
    var isEnableSubmit = function () {
        if (isHeaderFormChange && !isEmptyHeaderForm) {
            return true;
        }
        if (isTableFormChange && !isEmptyTableForm && !isEmptyHeaderForm) {
            return true;
        }
        return false;
    };
    useEffect(function () {
        fetchReport();
    }, [fetchReport]);
    useEffect(function () {
        var _a;
        if (reportData.data) {
            var defaultScenario = (_a = reportData.data.scenarios) === null || _a === void 0 ? void 0 : _a[0].id;
            setCurrentScenarioId(defaultScenario);
        }
    }, [reportData.data, setValue]);
    useEffect(function () {
        fetchTrainingReport();
        return function () {
            clearTrainingReport();
        };
    }, [fetchTrainingReport, clearTrainingReport]);
    useEffect(function () {
        if (currentScenarioId) {
            setCurrentQueueId(undefined);
        }
    }, [currentScenarioId]);
    useEffect(function () {
        if (trainingReportData.data) {
            var defaultQueue = currentQueueId !== null && currentQueueId !== void 0 ? currentQueueId : trainingReportData.data.queues[0].id;
            setCurrentQueueId(defaultQueue);
        }
    }, [currentQueueId, trainingReportData.data]);
    useEffect(function () {
        reset(defaultValues);
    }, [reset, defaultValues]);
    useEffect(function () {
        if (updateHeaderTrainingData.data) {
            fetchTrainingReport();
        }
    }, [fetchTrainingReport, updateHeaderTrainingData.data]);
    useEffect(function () {
        if (updateTableTrainingData.data) {
            fetchTrainingReport();
        }
    }, [fetchTrainingReport, updateTableTrainingData.data]);
    var handleUpdateTrainingRecruitment = handleSubmit(function (values) {
        if (isHeaderFormChange) {
            updateHeaderTraining(_.omit(values, ['scenarioId', 'trainings']));
            return;
        }
        if (isTableFormChange) {
            updateTableTraining(_.pick(values, ['queueId', 'trainings']));
        }
    });
    return (_jsxs(_Fragment, { children: [_jsx(ErrorMessageBox, { errors: [printData.error, exportData.error] }), _jsx(FallbackComponent, __assign({ overlay: true, requesting: !!trainingReportData.requesting || !!reportData.requesting }, { children: _jsxs(Box, __assign({ padding: 5, component: "form", onSubmit: handleUpdateTrainingRecruitment }, { children: [_jsxs(Grid, __assign({ container: true, spacing: 5 }, { children: [_jsx(Grid, __assign({ item: true, xs: 4, lg: 3 }, { children: _jsx(LeftPanel, { children: _jsx(TrainingRecruitmentForm, { reportData: reportData.data, queueData: (_f = trainingReportData.data) === null || _f === void 0 ? void 0 : _f.queues, control: control, disableButton: !isEnableSubmit(), setCurrentScenario: setCurrentScenarioId, setCurrentQueue: setCurrentQueueId }) }) })), _jsx(Grid, __assign({ item: true, xs: 8, lg: 9 }, { children: _jsxs(Stack, __assign({ gap: 4 }, { children: [_jsxs(Stack, __assign({ gap: 4, direction: "row", justifyContent: "space-between", alignItems: "center" }, { children: [_jsx(Typography, { children: t('training.trainingDescriptions') }), _jsx(Button, __assign({ onClick: function () { return setOpenSummary(true); }, sx: { minWidth: '10rem' }, variant: "outlined" }, { children: t('training.viewSummary') }))] })), trainingDataTable && currenScenario && currentQueue && (_jsx(TrainingTable, { currentScenario: currenScenario, trainings: trainingDataTable, control: control, setValue: setValue, isDeferrableQueue: isDeferrableQueue }))] })) }))] })), _jsx(SummaryDialog, { open: openSummary, onClose: function () { return setOpenSummary(false); }, scenarioId: currentScenarioId })] })) }))] }));
};
