import { createSelector } from '@reduxjs/toolkit';
import _ from 'lodash';
import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useAPI, useCachedAPI } from '../../../state';
import { toInt } from '../../../util';
import { useCancelSimulation } from '../../report/selectors';
import { STATUS_BY_STEP, STEPS_LENGTH } from '../constants';
import { useDeleteAllMultiskillAPI, useWizardParams } from '../selectors';
import { Completed } from './types';
var WORK_TYPE_API = '/api/worktype';
export var QUEUE_LIST_API = '/api/queue/findAll/{planId}';
var QUEUES_API = '/api/queue';
var QUEUE_API = '/api/queue/{id}';
export var selectQueues = function (queues) { return queues; };
export var selectQueueMap = createSelector(selectQueues, function (queues) { return _.keyBy(queues, 'id'); });
export var useWorkTypeAPI = function () {
    return useCachedAPI(WORK_TYPE_API);
};
export var useQueuesAPI = function () {
    var planId = useParams().id;
    var _a = useAPI(QUEUE_LIST_API, { planId: planId }), data = _a[0], fetchData = _a[1], clearData = _a[2];
    var fetchDataWrapper = useCallback(function () {
        if (planId) {
            fetchData();
        }
    }, [fetchData, planId]);
    return [data, fetchDataWrapper, clearData];
};
export var useCreateQueueAPI = function () {
    return useAPI(QUEUES_API, undefined, 'POST');
};
export var useDeleteQueueAPI = function (id) {
    return useAPI(QUEUE_API, { id: id }, 'DELETE');
};
export var useRenameQueueAPI = function (id) {
    return useAPI(QUEUE_API, { id: id }, 'PUT');
};
export var useSelectedQueue = function (id) {
    var queueId = useWizardParams().queueId;
    id = id !== null && id !== void 0 ? id : queueId;
    var queueData = useQueuesAPI()[0];
    var queueMap = selectQueueMap(queueData.data);
    return id ? queueMap[id] : undefined;
};
export var useQueueStatus = function (id) {
    var _a;
    var status = ((_a = useSelectedQueue(id)) !== null && _a !== void 0 ? _a : {}).status;
    return status;
};
export var usePlanStatus = function (type) {
    var _a;
    var queuesData = useQueuesAPI()[0];
    return (_a = queuesData.data) === null || _a === void 0 ? void 0 : _a.some(function (_a) {
        var status = _a.status;
        return status[type];
    });
};
export var useQueueIncomplete = function (id) {
    var step = useWizardParams().step;
    var status = useQueueStatus(id);
    return useMemo(function () { var _a; return (_a = STATUS_BY_STEP[step]) === null || _a === void 0 ? void 0 : _a.some(function (type) { return (status === null || status === void 0 ? void 0 : status[type]) === Completed.No; }); }, [step, status]);
};
export var useCompletedStep = function () {
    var queuesData = useQueuesAPI()[0];
    return useMemo(function () {
        var _a;
        if (!queuesData.data) {
            return [-1];
        }
        if (!queuesData.data.length) {
            return [0];
        }
        for (var step = 0; step < STEPS_LENGTH; step++) {
            var incompleteType = (_a = STATUS_BY_STEP[step]) === null || _a === void 0 ? void 0 : _a.find(function (type) { var _a; return (_a = queuesData.data) === null || _a === void 0 ? void 0 : _a.some(function (_a) {
                var status = _a.status;
                return status[type] === Completed.No;
            }); });
            if (incompleteType) {
                return [step, incompleteType];
            }
        }
        return [STEPS_LENGTH - 1];
    }, [queuesData.data]);
};
export var useQueuesCopyFrom = function (statusType) {
    var queuesData = useQueuesAPI()[0];
    var queueId = useWizardParams().queueId;
    return useMemo(function () { var _a; return (_a = queuesData.data) === null || _a === void 0 ? void 0 : _a.filter(function (_a) {
        var id = _a.id, status = _a.status;
        return id !== queueId && status[statusType] !== Completed.No;
    }).reverse(); }, [queuesData.data, queueId, statusType]);
};
export var useQueueAPI = function () {
    var queueId = useWizardParams().queueId;
    var _a = useAPI(QUEUE_API, { id: queueId }), data = _a[0], fetchData = _a[1], clearData = _a[2];
    var fetchDataWrapper = useCallback(function () {
        if (queueId) {
            fetchData();
        }
    }, [fetchData, queueId]);
    return [data, fetchDataWrapper, clearData];
};
export var useRefreshQueueData = function () {
    var _a = useQueueAPI(), fetchQueue = _a[1];
    var _b = useQueuesAPI(), fetchQueues = _b[1];
    return useCallback(function () {
        fetchQueue();
        fetchQueues();
    }, [fetchQueue, fetchQueues]);
};
export var useSaveQueueAPI = function (type) {
    if (type === void 0) { type = ''; }
    return useAPI(QUEUES_API, undefined, 'PATCH', { key: "".concat(QUEUES_API, ":GET:").concat(type) });
};
export var useQueueHasMultiskill = function () {
    var _a, _b;
    var queuesData = useQueuesAPI()[0];
    var queueData = useQueueAPI()[0];
    return (_b = (_a = queuesData.data) === null || _a === void 0 ? void 0 : _a.find(function (d) { var _a; return d.id === ((_a = queueData.data) === null || _a === void 0 ? void 0 : _a.id); })) === null || _b === void 0 ? void 0 : _b.hasMultiskill;
};
export var useQueuesHasMultiskill = function () {
    var _a;
    var queuesData = useQueuesAPI()[0];
    return (_a = queuesData.data) === null || _a === void 0 ? void 0 : _a.some(function (d) { return d.hasMultiskill; });
};
export var useCancelAndDeleteMultiskill = function () {
    var _a = useParams(), basePlanId = _a.basePlanId, id = _a.id;
    var _b = useDeleteAllMultiskillAPI(), fetchDeleteAllMultiskillData = _b[1];
    var _c = useCancelSimulation(toInt(id), toInt(basePlanId)), cancelSimulation = _c[1];
    return useCallback(function () {
        if (basePlanId && id) {
            cancelSimulation({ isCancelAll: true });
            fetchDeleteAllMultiskillData();
        }
    }, [basePlanId, cancelSimulation, fetchDeleteAllMultiskillData, id]);
};
