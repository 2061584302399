var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from '@mui/material';
import moment from 'moment';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { DatePicker, ErrorMessageBox } from '../common';
import { FormField } from '../common/formField';
import { useCreateExportLogAPI } from './selectors';
var defaultValues = {
    start: moment().utc().startOf('y'),
    end: moment().utc(),
};
var validateForm = yup.object().shape({
    start: yup.object().nullable().required(),
    end: yup.object().nullable().required(),
    startError: yup.string().optional().max(0, 'Invalid'),
    endError: yup.string().optional().max(0, 'Invalid'),
});
export var ExportLogDialog = function (_a) {
    var open = _a.open, onClose = _a.onClose;
    var t = useTranslation(['manageUser', 'translation']).t;
    var _b = useCreateExportLogAPI(), exportData = _b[0], fetchExportData = _b[1];
    var _c = useForm({
        defaultValues: defaultValues,
        resolver: yupResolver(validateForm),
    }), control = _c.control, watch = _c.watch, reset = _c.reset, handleSubmit = _c.handleSubmit;
    var start = watch().start;
    useEffect(function () {
        if (open) {
            reset(defaultValues);
        }
    }, [open, reset]);
    var handleFormSubmit = function (formData) {
        fetchExportData(formData);
    };
    return (_jsx(Dialog, __assign({ open: open, onClose: onClose }, { children: _jsxs(Box, __assign({ component: "form", onSubmit: handleSubmit(handleFormSubmit) }, { children: [_jsx(DialogTitle, __assign({ variant: "h2" }, { children: t('exportLog.title') })), _jsxs(DialogContent, { children: [_jsx(Typography, __assign({ variant: "body1" }, { children: t('exportLog.subtitle') })), _jsx(ErrorMessageBox, { errors: [exportData.error] }), _jsxs(Stack, __assign({ direction: "row", justifyContent: 'space-between', marginTop: 4 }, { children: [_jsx(FormField, __assign({ required: true, name: "start", label: t('exportLog.startDate'), control: control }, { children: _jsx(DatePicker, { disableFuture: true, control: control }) })), _jsx(FormField, __assign({ required: true, name: "end", label: t('exportLog.endDate'), control: control }, { children: _jsx(DatePicker, { minDate: start, disableFuture: true, control: control }) }))] }))] }), _jsxs(DialogActions, { children: [_jsx(Button, __assign({ variant: "outlined", onClick: onClose }, { children: t('translation:action.cancel') })), _jsx(LoadingButton, __assign({ variant: "contained", type: "submit", loading: exportData.requesting }, { children: t('translation:action.export') }))] })] })) })));
};
