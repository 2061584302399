var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { formControlClasses, styled, tableCellClasses } from '@mui/material';
import _ from 'lodash';
import { useFormContext } from 'react-hook-form';
import { FormField, NativeNumberInput, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, } from '../../common';
import { DAY_NAMES } from '../constants';
import { DOUBLE_HYPHEN, useVolumeMatrix } from './selectors';
var OverallCell = styled(TableCell)(function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = {
            ':first-of-type': {
                padding: theme.spacing(0, 1),
            }
        },
        _b["&.".concat(tableCellClasses.root)] = {
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.text.primary,
        },
        _b["input:not([aria-invalid=\"true\"])"] = {
            borderColor: 'rgba(0, 0, 0, 0.23)',
        },
        _b);
});
var StyledNativeNumberInput = styled(NativeNumberInput)(function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = {},
        _b["&.".concat(formControlClasses.root)] = {
            margin: theme.spacing(0, 1),
        },
        _b);
});
var StyledTableFooter = styled(TableFooter)(function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = {},
        _b["th.".concat(tableCellClasses.footer, ".").concat(tableCellClasses.root)] = {
            backgroundColor: theme.palette.grey[500],
        },
        _b);
});
var CellContent = function (_a) {
    var month = _a.month, value = _a.value, disabled = _a.disabled;
    var _b = useFormContext(), control = _b.control, setValue = _b.setValue;
    if (typeof value === 'string')
        return _jsx("span", { children: value });
    return (_jsx(FormField, __assign({ disableHelperText: true, control: control, name: "wapVolumes.month".concat(month, ".").concat(value[0], ".").concat(value[1], ".workVolume") }, { children: _jsx(StyledNativeNumberInput, { disabled: disabled, decimal: 4, max: 100, setValue: setValue, suffix: "%" }) })));
};
var VolumeTableHead = function (_a) {
    var month = _a.month, disabled = _a.disabled;
    var overallRow = useVolumeMatrix(month)[0];
    var watch = useFormContext().watch;
    var overallValues = watch("wapVolumes.month".concat(month, ".0"));
    var total = _.sumBy(overallValues, function (_a) {
        var workVolume = _a.workVolume;
        return Number(workVolume);
    });
    return (_jsxs(TableHead, { children: [_jsxs(TableRow, { children: [_jsx(TableCell, { children: "\u00A0" }), DAY_NAMES.map(function (name) { return (_jsx(TableCell, { children: name }, name)); }), _jsx(TableCell, { children: "TOTAL" })] }), _jsxs(TableRow, { children: [overallRow.map(function (cell, cellIdx) { return (_jsx(OverallCell, __assign({ padding: "none" }, { children: _jsx(CellContent, { disabled: disabled, month: month, value: cell }) }), cellIdx)); }), _jsx(OverallCell, { children: "".concat(_.round(total, 4), "%") })] })] }));
};
var VolumeTableBody = function (_a) {
    var month = _a.month, disabled = _a.disabled;
    var _b = useVolumeMatrix(month), volumeMatrix = _b.slice(1);
    return (_jsx(TableBody, { children: volumeMatrix.map(function (cells, rowIdx) { return (_jsxs(TableRow, { children: [cells.map(function (cell, cellIdx) { return (_jsx(TableCell, __assign({ component: cellIdx ? 'td' : 'th', padding: cellIdx ? 'none' : undefined, greyColor: cell === DOUBLE_HYPHEN || disabled }, { children: _jsx(CellContent, { disabled: disabled, month: month, value: cell }) }), cellIdx)); }), _jsx(TableCell, __assign({ greyColor: true }, { children: DOUBLE_HYPHEN }))] }, rowIdx)); }) }));
};
var VolumeTableFoot = function (_a) {
    var _b;
    var month = _a.month;
    var _c = useVolumeMatrix(month)[0], overallRow = _c.slice(1);
    var watch = useFormContext().watch;
    var _d = (_b = watch("wapVolumes.month".concat(month))) !== null && _b !== void 0 ? _b : [], dayValues = _d.slice(1);
    var sum = dayValues.map(function (v) { return _.sumBy(v, function (_a) {
        var workVolume = _a.workVolume;
        return Number(workVolume);
    }); });
    return (_jsx(StyledTableFooter, { children: _jsxs(TableRow, { children: [__spreadArray(['TOTAL'], overallRow, true).map(function (cell, cellIdx) { return (_jsx(TableCell, __assign({ component: "th", percentage: true }, { children: typeof cell === 'string' ? cell : "".concat(_.round(sum[cell[1]], 4), "%") }), cellIdx)); }), _jsx(TableCell, __assign({ component: "th" }, { children: DOUBLE_HYPHEN }))] }) }));
};
export var DailyVolumeTable = function (props) {
    return (_jsx(TableContainer, __assign({ maxHeight: "35.125rem" }, { children: _jsxs(Table, __assign({ sx: { tableLayout: 'fixed', minWidth: '75rem' } }, { children: [_jsx(VolumeTableHead, __assign({}, props)), _jsx(VolumeTableBody, __assign({}, props)), _jsx(VolumeTableFoot, __assign({}, props))] })) }), props.month));
};
