var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFormlessContext } from '../../hooks';
import { Completed } from '../../wizard/queue/types';
export var MultiskillWarningDialog = function (_a) {
    var openWarning = _a.openWarning, setOpenWarning = _a.setOpenWarning;
    var t = useTranslation(['translation', 'multiSkill']).t;
    var control = useFormlessContext();
    return (_jsxs(Dialog, __assign({ open: openWarning }, { children: [_jsxs(DialogTitle, __assign({ variant: "h1", display: "flex", flexDirection: "row", alignItems: "center", gap: 3 }, { children: [_jsx(Typography, __assign({ color: "warning.main" }, { children: _jsx(FontAwesomeIcon, { icon: "triangle-exclamation", size: "2x" }) })), t('multiSkill:edit.warning')] })), _jsx(DialogContent, { children: _jsx(Typography, { children: t('multiSkill:edit.warningIncludeWapMessage') }) }), _jsxs(DialogActions, { children: [_jsx(Button, __assign({ variant: "outlined", onClick: function () {
                            control === null || control === void 0 ? void 0 : control.setValue('isUseWAP', Completed.No);
                            setOpenWarning(false);
                        } }, { children: t('translation:action.cancel') })), _jsx(Button, __assign({ variant: "contained", sx: { ml: 3 }, onClick: function () { return setOpenWarning(false); } }, { children: t("translation:action.continue") }))] })] })));
};
