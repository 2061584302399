var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Paper, Stack, Typography, buttonClasses, styled, useTheme } from '@mui/material';
import _ from 'lodash';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import 'swiper/css';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import { MULTISKILL_COLOR } from '../annual/constants';
import { Autocomplete, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow } from '../common';
import { QueueType } from '../multiskill/types';
import { DETAIL_LABELS, PERCENTAGES_DETAIL_INDEX } from './constants';
import { useDailyContext } from './context';
import { selectDailyDataTransformed, selectTimes, useDailyAPI, useGetQueueNames, usePlanColor, useReportTimelineOptions, } from './selectors';
var FOOTER_KEYS = ['total', 'staffHours'];
var TableCellStyled = styled(TableCell)(function (_a) {
    var theme = _a.theme;
    return ({
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: theme.typography.pxToRem(100),
    });
});
var Legend = styled(Box)(function (_a) {
    var theme = _a.theme;
    return ({
        backgroundColor: theme.palette.grey[400],
        border: "1px solid ".concat(theme.palette.grey[600]),
        padding: theme.spacing(1.5, 6),
    });
});
var TableNav = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '0 auto',
});
var ContentNav = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
});
var TableNavButton = styled(Button)(function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = {
            paddingTop: theme.typography.pxToRem(10),
            paddingBottom: theme.typography.pxToRem(10)
        },
        _b["&.".concat(buttonClasses.root, ".").concat(buttonClasses.disabled)] = {
            borderWidth: 0,
            backgroundColor: 'unset',
            opacity: 0.5,
        },
        _b);
});
var NavTitle = styled(Typography)(function (_a) {
    var theme = _a.theme;
    return ({
        textTransform: 'uppercase',
        fontWeight: theme.typography.fontWeightBold,
    });
});
var StyledJumpTo = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    position: 'absolute',
    right: 0,
});
var StyledAutocomplete = styled(Autocomplete)(function (_a) {
    var theme = _a.theme;
    return ({
        width: theme.typography.pxToRem(220),
        marginLeft: theme.typography.pxToRem(10),
    });
});
var TableNavBarType;
(function (TableNavBarType) {
    TableNavBarType["Week"] = "week";
    TableNavBarType["Day"] = "day";
})(TableNavBarType || (TableNavBarType = {}));
export var TableNavBar = function (_a) {
    var type = _a.type, title = _a.title, onNext = _a.onNext, onPrev = _a.onPrev, disabledNext = _a.disabledNext, disabledPrev = _a.disabledPrev;
    var theme = useTheme();
    var variantBtn = TableNavBarType.Week === type ? 'text' : 'contained';
    var bgcolor = TableNavBarType.Week === type ? '' : theme.palette.text.primary;
    var color = TableNavBarType.Week === type ? theme.palette.text.primary : theme.palette.common.white;
    var fontSizeTitle = TableNavBarType.Week === type ? theme.typography.pxToRem(18) : theme.typography.pxToRem(16);
    return (_jsxs(TableNav, __assign({ bgcolor: bgcolor }, { children: [_jsx(TableNavButton, __assign({ "data-testid": "prev-btn", disabled: disabledPrev, onClick: function () { return onPrev(); }, variant: variantBtn, disableElevation: true }, { children: _jsx(FontAwesomeIcon, { icon: "chevron-left", size: "xl" }) })), _jsx(NavTitle, __assign({ sx: { fontSize: fontSizeTitle }, color: color }, { children: title })), _jsx(TableNavButton, __assign({ "data-testid": "next-btn", disabled: disabledNext, onClick: function () { return onNext(); }, variant: variantBtn, disableElevation: true }, { children: _jsx(FontAwesomeIcon, { icon: "chevron-right", size: "xl" }) }))] })));
};
var DayTableHead = function (_a) {
    var hasMultiskill = _a.hasMultiskill;
    var t = useTranslation('report', { keyPrefix: 'daily' }).t;
    var headerColor = usePlanColor()[0];
    var queueNames = useGetQueueNames();
    var singleSkills = queueNames.filter(function (queue) { return queue.queueType === QueueType.SingleSkill; });
    var getColSpan = function (title) {
        if (!hasMultiskill)
            return 2;
        return ['staffRequired', 'occupancy'].includes(title) ? queueNames.length : singleSkills.length;
    };
    return (_jsxs(_Fragment, { children: [_jsxs(TableRow, { children: [_jsx(TableCell, __assign({ component: "td", rowSpan: 2, sx: { width: '8rem' }, color: headerColor }, { children: t('interval') })), _jsx(TableCell, __assign({ component: "td", rowSpan: hasMultiskill ? 1 : 2, colSpan: hasMultiskill ? singleSkills.length : 1, color: headerColor }, { children: t('workVolume') })), DETAIL_LABELS.filter(function (title) { return title !== 'workVolume'; }).map(function (title) { return (_jsx(TableCell, __assign({ component: "td", colSpan: getColSpan(title), color: headerColor }, { children: t(title) }), title)); })] }), _jsx(TableRow, { children: hasMultiskill
                    ? _.times(5).map(function (i) {
                        return (i === 1 || i === 4 ? queueNames : singleSkills).map(function (queue) { return (_jsx(TableCellStyled, __assign({ component: "td", title: queue.queueName }, { children: queue.queueName }), queue.queueName)); });
                    })
                    : _.times(4).map(function (i) { return (_jsxs(Fragment, { children: [_jsx(TableCell, __assign({ component: "td" }, { children: t('model') })), _jsx(TableCell, __assign({ component: "td" }, { children: t('adjusted') }))] }, i)); }) })] }));
};
var DayTable = function (_a) {
    var _b, _c;
    var time = _a.time, index = _a.index, data = _a.data;
    var swiper = useSwiper();
    var _d = usePlanColor(), bodyColor = _d[1];
    var t = useTranslation(['report', 'translation']).t;
    var state = useDailyContext()[0];
    var dailyData = useDailyAPI()[0];
    var hasMultiskill = ((_b = state === null || state === void 0 ? void 0 : state.uniqueId) === null || _b === void 0 ? void 0 : _b.includes('-')) && !((_c = dailyData.data) === null || _c === void 0 ? void 0 : _c.detail);
    var queueNames = useGetQueueNames();
    var renderRowEl = function (arrData) {
        return arrData.map(function (key) { return (_jsxs(TableRow, { children: [_jsx(TableCell, __assign({ component: "th" }, { children: t(key, { defaultValue: key }) })), hasMultiskill
                    ? DETAIL_LABELS.map(function (label, detailIdx) {
                        var queues = ['staffRequired', 'occupancy'].includes(label)
                            ? queueNames
                            : queueNames.filter(function (queue) { return queue.queueType === QueueType.SingleSkill; });
                        return queues.map(function (_a) {
                            var queueName = _a.queueName, queueType = _a.queueType;
                            var v = data[key].find(function (item) { return item.queueName === queueName && item.queueType === queueType; })
                                .detail[detailIdx];
                            return (_jsx(TableCell, { decimal: 0, color: queueType === QueueType.Multiskill ? MULTISKILL_COLOR : '', percentage: ['serviceLevel', 'occupancy'].includes(label), value: v }, "".concat(queueName, "-").concat(label, "-").concat(detailIdx)));
                        });
                    })
                    : data[key].map(function (v, idx) { return (_jsx(TableCell, { decimal: 0, color: idx & idx % 2 ? bodyColor : '', percentage: PERCENTAGES_DETAIL_INDEX.includes(idx), value: v, component: !idx ? 'th' : 'td' }, idx)); })] }, key)); });
    };
    var bodyRowEl = renderRowEl(time);
    var footerRowEl = renderRowEl(FOOTER_KEYS);
    return (_jsxs(_Fragment, { children: [_jsx(TableNavBar, { onNext: function () { return swiper.slideNext(); }, onPrev: function () { return swiper.slidePrev(); }, type: "day", title: t("translation:days.".concat(index)) }), _jsx(TableContainer, __assign({ maxHeight: "32.425rem", sx: { width: 'initial' } }, { children: _jsxs(Table, __assign({ sx: { tableLayout: hasMultiskill ? 'auto' : 'fixed' } }, { children: [_jsx(TableHead, { children: _jsx(DayTableHead, { hasMultiskill: hasMultiskill }) }), _jsx(TableBody, { children: bodyRowEl }), _jsx(TableFooter, { children: footerRowEl })] })) }))] }));
};
export var DetailTable = function () {
    var _a;
    var t = useTranslation('report', { keyPrefix: 'daily' }).t;
    var timelines = useReportTimelineOptions();
    var dailyData = useDailyAPI()[0];
    var _b = useDailyContext('month'), _c = _b[0], periodIndex = _c === void 0 ? 0 : _c, setPeriodIndex = _b[1];
    var times = selectTimes(dailyData.data);
    var slideData = selectDailyDataTransformed(dailyData === null || dailyData === void 0 ? void 0 : dailyData.data);
    return (_jsxs(_Fragment, { children: [_jsxs(ContentNav, { children: [_jsxs(Stack, __assign({ direction: "row", alignItems: "center", gap: 1, position: "absolute" }, { children: [_jsx(Legend, { children: "-" }), _jsxs(Typography, { children: ["= ", t('queueClosed')] })] })), _jsx(TableNavBar, { onNext: function () { return setPeriodIndex(function (curr) {
                            if (curr === void 0) { curr = 0; }
                            return curr + 1;
                        }); }, onPrev: function () { return setPeriodIndex(function (curr) {
                            if (curr === void 0) { curr = 0; }
                            return curr - 1;
                        }); }, title: (_a = timelines[periodIndex]) === null || _a === void 0 ? void 0 : _a.name, disabledNext: !!dailyData.requesting || periodIndex === timelines.length - 1, disabledPrev: !!dailyData.requesting || !periodIndex, type: "week" }), _jsxs(StyledJumpTo, { children: [_jsxs(Typography, __assign({ fontWeight: 600 }, { children: [t('jumpTo'), ":"] })), _jsx(StyledAutocomplete, { options: timelines, value: periodIndex, onChange: setPeriodIndex, disabled: dailyData.requesting })] })] }), _jsx(Paper, __assign({ elevation: 0 }, { children: _jsx(Swiper, __assign({ loop: true }, { children: _.map(slideData, function (item, index) { return (_jsx(SwiperSlide, __assign({ style: { transform: 'none' } }, { children: _jsx(DayTable, { index: index, time: times !== null && times !== void 0 ? times : [], data: item }) }), index)); }) })) }))] }));
};
