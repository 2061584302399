var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Typography, styled } from '@mui/material';
import { FluidContainer } from '../layout';
var StyledTitle = styled(Typography)(function (_a) {
    var theme = _a.theme;
    return ({
        margin: theme.spacing(15, 0),
        color: theme.palette.error.dark,
    });
});
var StyledContent = styled(Typography)(function (_a) {
    var theme = _a.theme;
    return ({
        marginBottom: theme.spacing(15),
        width: theme.typography.pxToRem(400),
    });
});
export var ErrorPage = function (_a) {
    var error = _a.error;
    return (_jsxs(FluidContainer, { children: [_jsx(StyledTitle, __assign({ variant: "subtitle1" }, { children: error.message })), (error === null || error === void 0 ? void 0 : error.helperText) && _jsx(StyledContent, { children: error.helperText })] }));
};
