import { useAPI } from '../../state';
var ALL_PLAN_API = '/api/plan';
var PLANS_API = '/api/plan';
var PLAN_API = '/api/plan/{basePlanId}';
var DUPLICATE_PLAN_API = '/api/plan/duplicate';
var VALIDATE_PLAN_API = '/api/plan/validatePlan';
var UPLOAD_DATA_API = '/api/upload';
export var usePlansAPI = function () {
    return useAPI(ALL_PLAN_API);
};
export var useCreatePlanAPI = function () {
    return useAPI(PLANS_API, {}, 'POST');
};
export var useDeletePlanAPI = function (basePlanId) {
    return useAPI(PLAN_API, { basePlanId: basePlanId }, 'DELETE');
};
export var useDuplicatePlanAPI = function () {
    return useAPI(DUPLICATE_PLAN_API, {}, 'POST');
};
export var useValidatePlanAPI = function () {
    return useAPI(VALIDATE_PLAN_API, {}, 'POST');
};
export var useUploadDataAPI = function () {
    return useAPI(UPLOAD_DATA_API, {}, 'POST', { contentType: 'multipart/form-data' });
};
