var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, IconButton, Link as MuiLink, Typography, styled } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePlatformContext } from '../../app/selectors';
import { ConfirmDialog, ErrorMessageBox, FallbackComponent } from '../../common';
import { useDialog } from '../../hooks';
import { StepContainer, StepPanel } from '../common/stepContainer';
import { CreateQueueForm } from './createForm';
import { DialogMode, RenameQueueDialog } from './renameQueueDialog';
import { useCancelAndDeleteMultiskill, useCreateQueueAPI, useDeleteQueueAPI, useQueuesAPI } from './selectors';
var Title = styled(Typography)(function (_a) {
    var theme = _a.theme;
    return ({
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(2),
        fontWeight: theme.typography.fontWeightBold,
    });
});
var Item = styled(Box)(function (_a) {
    var theme = _a.theme;
    return ({
        padding: theme.spacing(2),
        borderBottom: "".concat(theme.typography.pxToRem(1), " solid ").concat(theme.palette.divider),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    });
});
var BusinessAreaLabel = function () {
    var t = useTranslation('wizard', { keyPrefix: 'queue.form' }).t;
    var context = usePlatformContext();
    return _jsx(Title, { children: t('businessArea.label', { context: context }) });
};
export var QueuesStep = function () {
    var _a, _b, _c;
    var _d = useState(), queueId = _d[0], setQueueId = _d[1];
    var t = useTranslation('wizard', { keyPrefix: 'queue.list' }).t;
    var _e = useQueuesAPI(), queuesData = _e[0], fetchQueues = _e[1];
    var _f = useDeleteQueueAPI(queueId), deleteQueueData = _f[0], deleteQueue = _f[1], clearDeleteQueue = _f[2];
    var fetchCancelAndDeleteMultiskill = useCancelAndDeleteMultiskill();
    var createQueueData = useCreateQueueAPI()[0];
    var context = usePlatformContext();
    var _g = useDialog(), dialogMode = _g.dialogMode, selectedQueue = _g.data, handleOpenDialog = _g.handleOpenDialog, handleCloseDialog = _g.handleCloseDialog;
    var handleDeteleQuery = function (queue) {
        if (queue.hasMultiskill) {
            handleOpenDialog(DialogMode.Delete, queue);
        }
        else {
            setQueueId(queue.id);
        }
    };
    var handleContinue = function () {
        fetchCancelAndDeleteMultiskill();
        setQueueId(selectedQueue === null || selectedQueue === void 0 ? void 0 : selectedQueue.id);
        handleCloseDialog();
    };
    useEffect(function () {
        if (deleteQueueData.data) {
            fetchQueues();
            setQueueId(undefined);
        }
        return clearDeleteQueue;
    }, [fetchQueues, deleteQueueData.data, clearDeleteQueue]);
    useEffect(function () {
        if (queueId) {
            deleteQueue();
        }
    }, [deleteQueue, queueId]);
    return (_jsxs(_Fragment, { children: [_jsx(ErrorMessageBox, { errors: [createQueueData.error] }), _jsx(StepContainer, __assign({ leftContent: _jsx(CreateQueueForm, {}) }, { children: _jsxs(StepPanel, __assign({ sx: { height: '27rem' } }, { children: [_jsx(ErrorMessageBox, { errors: [deleteQueueData.error] }), _jsxs(FallbackComponent, __assign({ overlay: true, requesting: !!queuesData.requesting || !!deleteQueueData.requesting }, { children: [_jsx(Typography, __assign({ variant: "h3", gutterBottom: true }, { children: t('title', { context: context }) })), !((_a = queuesData.data) === null || _a === void 0 ? void 0 : _a.length) && _jsx(Typography, __assign({ gutterBottom: true }, { children: t('noQueue', { context: context }) })), !!((_b = queuesData.data) === null || _b === void 0 ? void 0 : _b.length) && (_jsxs(_Fragment, { children: [_jsx(Typography, __assign({ gutterBottom: true, sx: { marginBottom: '1rem' } }, { children: t('description', { context: context }) })), _jsx(BusinessAreaLabel, {}), _jsx(Box, { children: (_c = queuesData.data) === null || _c === void 0 ? void 0 : _c.map(function (queue) { return (_jsxs(Item, { children: [_jsxs(MuiLink, __assign({ "data-testid": "button-open", component: "button", onClick: function () { return handleOpenDialog(DialogMode.Rename, queue); } }, { children: [queue.businessArea, ": ", queue.worktype.name] })), _jsx(MuiLink, __assign({ "data-testid": "button-delete", component: IconButton, size: "small", onClick: function () { return handleDeteleQuery(queue); } }, { children: _jsx(FontAwesomeIcon, { icon: "trash-can" }) }))] }, queue.id)); }) })] }))] }))] })) })), _jsx(RenameQueueDialog, { queueData: selectedQueue, open: dialogMode === DialogMode.Rename, onClose: handleCloseDialog }), _jsx(ConfirmDialog, { open: dialogMode === DialogMode.Delete, onClose: handleCloseDialog, message: t('confirm', { context: context }), confirmButtonText: "continue", onConfirm: handleContinue })] }));
};
