import { Box, Typography, styled } from '@mui/material';
export var PageBody = styled(Box)(function (_a) {
    var theme = _a.theme;
    return ({
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(10),
        gap: theme.spacing(5),
        position: 'relative',
    });
});
export var SubTitle = styled(Typography)(function (_a) {
    var theme = _a.theme;
    return ({
        marginBottom: theme.spacing(3),
    });
});
