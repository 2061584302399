var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Dialog, DialogActions, DialogContent, FormControlLabel, IconButton, Radio, styled, } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorMessageBox, FallbackComponent, RadioGroup } from '../../../common';
import { useTrainerQaAPI } from '../selectors';
import { InteractionForm } from './interactionForm';
import { MonitorForm } from './monitorForm';
import { TrainerForm } from './trainerForm';
var ButtonTab = styled(Button, {
    shouldForwardProp: function (prop) { return prop !== 'isInactive'; },
})(function (_a) {
    var isInactive = _a.isInactive;
    return ({
        opacity: isInactive ? 0.4 : 1,
    });
});
var ExitButton = styled(IconButton)(function (_a) {
    var theme = _a.theme;
    return ({
        position: 'absolute',
        top: theme.spacing(5),
        right: theme.spacing(5),
    });
});
var QAForm = function (props) {
    var t = useTranslation('wizard', { keyPrefix: 'calculator' }).t;
    var _a = useState(true), isInteractionType = _a[0], setIsInteractionType = _a[1];
    return (_jsxs(_Fragment, { children: [_jsxs(RadioGroup, __assign({ row: true }, { children: [_jsx(FormControlLabel, { onClick: function () { return setIsInteractionType(true); }, checked: isInteractionType, control: _jsx(Radio, {}), label: t("percentInteraction") }), _jsx(FormControlLabel, { onClick: function () { return setIsInteractionType(false); }, checked: !isInteractionType, control: _jsx(Radio, {}), label: t("monitorPerAgent") })] })), isInteractionType ? (_jsx(InteractionForm, { setValue: props.setValue, watch: props.watch })) : (_jsx(MonitorForm, { setValue: props.setValue, watch: props.watch }))] }));
};
export var CalculatorDialog = function (_a) {
    var open = _a.open, onClose = _a.onClose, setValue = _a.setValue, watch = _a.watch;
    var _b = useTrainerQaAPI(), _c = _b[0], requesting = _c.requesting, error = _c.error, fetchTrainerQaData = _b[1], clearData = _b[2];
    var t = useTranslation('wizard', { keyPrefix: 'calculator' }).t;
    var _d = useState(true), isTrainerForm = _d[0], setIsTrainerForm = _d[1];
    useEffect(function () {
        if (open) {
            fetchTrainerQaData();
        }
        return clearData;
    }, [clearData, fetchTrainerQaData, open]);
    return (_jsxs(Dialog, __assign({ open: open, onClose: onClose }, { children: [_jsx(ExitButton, __assign({ color: "inherit", size: "medium", onClick: onClose, sx: { width: '2.5rem' } }, { children: _jsx(FontAwesomeIcon, { icon: "xmark" }) })), _jsx(DialogContent, __assign({ sx: { textAlign: 'center' } }, { children: _jsxs(FallbackComponent, __assign({ requesting: requesting, overlay: true }, { children: [_jsxs(DialogActions, __assign({ sx: { justifyContent: 'center' } }, { children: [_jsx(ButtonTab, __assign({ variant: "contained", onClick: function () { return setIsTrainerForm(true); }, isInactive: !isTrainerForm }, { children: t("trainerRatioTitle") })), _jsx(ButtonTab, __assign({ variant: "outlined", onClick: function () { return setIsTrainerForm(false); }, isInactive: isTrainerForm }, { children: t("qaRatioTitle") }))] })), _jsx(ErrorMessageBox, { errors: [error] }), isTrainerForm ? (_jsx(TrainerForm, { setValue: setValue, watch: watch })) : (_jsx(QAForm, { setValue: setValue, watch: watch }))] })) }))] })));
};
