var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Typography } from '@mui/material';
import { createSelector } from '@reduxjs/toolkit';
import _ from 'lodash';
import moment from 'moment';
import { useMemo } from 'react';
import { useAPI } from '../../../state';
import { useQueueAPI, useSaveQueueAPI } from '../queue/selectors';
import { selectChunkSize, selectTimelineOptions, usePlanAPI, useWizardParams } from '../selectors';
var WAT_API = '/api/wap/time';
var INTERVAL_API = '/api/wap/interval';
var WAT_COPY_API = '/api/wap/time/copy/{dest}';
var WAV_CLEAR_API = '/api/wap/volume/{queueId}';
var WAV_COPY_API = '/api/wap/volume/copy/{dest}';
var WAV_SAVE_KEY = 'STANDALONE_WAV_SAVE';
var TIME_FORMAT = 'hh:mmA';
export var CLOSED = 'CLOSED';
export var FULL_TIME = '24 hours';
export var START_OF_DATE = moment().startOf('D').format(TIME_FORMAT);
export var DEFAULT_TIME_INTERVAL = 15;
export var OVERALL_TIME = 'Total Daily Volume';
export var DOUBLE_HYPHEN = '--';
var selectExceptionList = createSelector(selectChunkSize, selectTimelineOptions, function (chunkSize, options) {
    if (options === void 0) { options = []; }
    return options.map(function (_a) {
        var id = _a.id, name = _a.name;
        return ({
            id: id,
            name: (options === null || options === void 0 ? void 0 : options.length) === chunkSize ? name.replace(/\s\d{4}$/, '') : name,
        });
    });
});
var selectExceptionOptions = createSelector(selectExceptionList, selectChunkSize, function (options, chunkSize) {
    return __spreadArray([], options, true).sort(function (a, b) { return (a.id % chunkSize) - (b.id % chunkSize); });
});
export var selectQueueDetail = function (queue) { return queue; };
var selectWATGroup = createSelector(selectQueueDetail, function (queue) {
    return _.chain(queue === null || queue === void 0 ? void 0 : queue.wapTimes)
        .map(function (_a) {
        var id = _a.id, month = _a.month, day = _a.day, openTime = _a.openTime, closeTime = _a.closeTime;
        return ({
            id: id,
            month: month,
            day: day,
            openTime: openTime && openTime !== CLOSED ? moment(openTime, TIME_FORMAT).format(TIME_FORMAT) : openTime,
            closeTime: closeTime && closeTime !== CLOSED ? moment(closeTime, TIME_FORMAT).format(TIME_FORMAT) : closeTime,
        });
    })
        .groupBy(function (_a) {
        var month = _a.month;
        return "month".concat(month);
    })
        .value();
});
export var extractMonthFromKey = function (str) { return Number(str.replace(/^month/, '')); };
var selectMonths = createSelector(selectWATGroup, function (watGroup) { return Object.keys(watGroup).map(extractMonthFromKey); });
var selectVolumeMonths = createSelector(selectMonths, selectQueueDetail, function (months, queue) { var _a; return !!(queue === null || queue === void 0 ? void 0 : queue.modifyOperatingHours) || ((_a = queue === null || queue === void 0 ? void 0 : queue.wapTimes) === null || _a === void 0 ? void 0 : _a.some(function (wat) { return !wat.closeTime; })) ? [] : months; });
export var getTimes = function (interval, openTime, closeTime) {
    if (interval === void 0) { interval = DEFAULT_TIME_INTERVAL; }
    if (openTime === void 0) { openTime = START_OF_DATE; }
    if (closeTime === void 0) { closeTime = START_OF_DATE; }
    if (openTime === CLOSED) {
        return [];
    }
    var open = moment(openTime, TIME_FORMAT);
    var close = moment(closeTime, TIME_FORMAT).add(closeTime === START_OF_DATE ? 1 : 0, 'day');
    var diff = Math.floor(close.diff(open, 'minute') / interval);
    return __spreadArray([
        OVERALL_TIME
    ], _.times(diff, function (n) {
        return open
            .clone()
            .add(n * interval, 'minute')
            .format(TIME_FORMAT);
    }), true);
};
var selectVolumeMatrix = createSelector(selectWATGroup, selectQueueDetail, function (grouped, queue) {
    return _.mapValues(grouped, function (wats) {
        var maxCloseTime = _.chain(wats)
            .map(function (_a) {
            var closeTime = _a.closeTime;
            return closeTime;
        })
            .filter(function (closeTime) { return closeTime !== CLOSED; })
            .maxBy(function (closeTime) { return moment(closeTime, TIME_FORMAT).add(closeTime === START_OF_DATE ? 1 : 0, 'day'); })
            .value();
        var allTimes = _.chain(wats)
            .map(function (wat) { return getTimes(queue === null || queue === void 0 ? void 0 : queue.timeInterval, wat.openTime, maxCloseTime); })
            .flatten()
            .uniq()
            .sort(function (a, b) { return moment(a, TIME_FORMAT).diff(moment(b, TIME_FORMAT), 'minute'); })
            .value();
        var openDays = wats.filter(function (wat) { return wat.openTime !== CLOSED; }).map(function (_a) {
            var day = _a.day;
            return day;
        });
        var timesPerDay = wats.map(function (_a) {
            var openTime = _a.openTime, closeTime = _a.closeTime, day = _a.day;
            return ({
                dayIdx: openDays.indexOf(day),
                times: getTimes(queue === null || queue === void 0 ? void 0 : queue.timeInterval, openTime, closeTime !== null && closeTime !== void 0 ? closeTime : undefined),
            });
        });
        return allTimes.map(function (time) { return __spreadArray([
            time
        ], _.times(7, function (day) {
            var _a = timesPerDay[day], dayIdx = _a.dayIdx, times = _a.times;
            var timeIdx = times.indexOf(time);
            if (timeIdx === -1)
                return DOUBLE_HYPHEN;
            if (time === OVERALL_TIME)
                return [0, dayIdx];
            return [dayIdx + 1, timeIdx - 1];
        }), true); });
    });
});
var selectWAVMap = createSelector(selectQueueDetail, function (queue) {
    return _.keyBy(queue === null || queue === void 0 ? void 0 : queue.wapVolumes, function (v) { return "".concat(v.month, "-").concat(v.day, "-").concat(v.time); });
});
var selectVolumeDefaultValues = createSelector(selectQueueDetail, selectWAVMap, function (queue, volume) { return ({
    id: queue === null || queue === void 0 ? void 0 : queue.id,
    wapVolumes: _.chain(queue === null || queue === void 0 ? void 0 : queue.wapTimes)
        .map(function (_a) {
        var month = _a.month, day = _a.day, openTime = _a.openTime, closeTime = _a.closeTime;
        return getTimes(queue === null || queue === void 0 ? void 0 : queue.timeInterval, openTime, closeTime !== null && closeTime !== void 0 ? closeTime : undefined).map(function (time) {
            var _a, _b;
            return ({
                month: month,
                day: day,
                time: time,
                workVolume: (_b = (_a = volume["".concat(month, "-").concat(day, "-").concat(time)]) === null || _a === void 0 ? void 0 : _a.workVolume) !== null && _b !== void 0 ? _b : null,
            });
        });
    })
        .flatten()
        .groupBy(function (_a) {
        var month = _a.month;
        return "month".concat(month);
    })
        .mapValues(function (arr) {
        return _.chain(arr)
            .groupBy(function (i) { return (i.time === OVERALL_TIME ? OVERALL_TIME : "day".concat(i.day)); })
            .values()
            .value();
    })
        .value(),
}); });
var selectTimeInterval = function (timeInterval) { return timeInterval; };
var selectTimeOptions = createSelector(selectTimeInterval, function (timeInterval) {
    return getTimes(timeInterval).map(function (name) { return ({ id: name, name: name }); });
});
export var selectOpenTimeOptions = createSelector(selectTimeOptions, function (options) { return __spreadArray([
    { id: CLOSED, name: CLOSED },
    { id: FULL_TIME, name: FULL_TIME }
], options.slice(1), true); });
var createCloseTimeOptionsSelector = function (openTime) {
    return createSelector(selectTimeInterval, function (interval) {
        if (!openTime) {
            return [];
        }
        if (openTime === CLOSED) {
            return [{ id: CLOSED, name: CLOSED }];
        }
        var times = getTimes(interval, openTime);
        var idx = times.indexOf(openTime);
        var last = moment(times[times.length - 1], TIME_FORMAT)
            .add(interval, 'minute')
            .format(TIME_FORMAT);
        return idx === -1 ? [] : __spreadArray(__spreadArray([], times.slice(idx + 1), true), [last], false).map(function (name) { return ({ id: name, name: name }); });
    });
};
export var useCloseTimeOptions = function (timeInterval, openTime) {
    var selectCloseTimeOptions = useMemo(function () { return createCloseTimeOptionsSelector(openTime); }, [openTime]);
    return selectCloseTimeOptions(timeInterval);
};
export var useWATGroup = function () {
    var queueData = useQueueAPI()[0];
    return selectWATGroup(queueData.data);
};
export var useWAVMonths = function () {
    var queueData = useQueueAPI()[0];
    return selectVolumeMonths(queueData.data);
};
export var useEditableMonth = function (month) {
    var months = useWAVMonths();
    return useMemo(function () { return !months.includes(month); }, [month, months]);
};
export var useCreateWapAPI = function () {
    return useAPI(WAT_API, undefined, 'POST');
};
export var useChangeIntervalAPI = function () {
    return useAPI(INTERVAL_API, undefined, 'PUT');
};
export var useDeleteWapAPI = function () {
    return useAPI(WAT_API, undefined, 'DELETE');
};
export var useTimeCopyAPI = function () {
    var queueId = useWizardParams().queueId;
    return useAPI(WAT_COPY_API, { dest: queueId }, 'POST');
};
export var useExceptionTitle = function (month) {
    var _a, _b, _c;
    var data = usePlanAPI()[0].data;
    var list = selectExceptionList(data);
    var inline = (_a = list[month]) === null || _a === void 0 ? void 0 : _a.name;
    var node = (_jsx(_Fragment, { children: (_c = (_b = list[month]) === null || _b === void 0 ? void 0 : _b.name) === null || _c === void 0 ? void 0 : _c.replace(' (', '__splitting__(').split('__splitting__').map(function (label) { return (_jsx(Typography, __assign({ textTransform: "uppercase", variant: "subtitle2" }, { children: label }), label)); }) }));
    return [node, inline];
};
export var useExceptionOptions = function (existing) {
    if (existing === void 0) { existing = []; }
    var data = usePlanAPI()[0].data;
    return selectExceptionOptions(data).filter(function (_a) {
        var id = _a.id;
        return !existing.includes("month".concat(id));
    });
};
export var useClearDailyVolumeAPI = function () {
    var queueId = useWizardParams().queueId;
    return useAPI(WAV_CLEAR_API, { queueId: queueId }, 'DELETE');
};
export var useDailyVolumeCopyAPI = function () {
    var queueId = useWizardParams().queueId;
    return useAPI(WAV_COPY_API, { dest: queueId }, 'POST');
};
export var useDailyVolumeDefaultValues = function () {
    var queueData = useQueueAPI()[0];
    return selectVolumeDefaultValues(queueData.data);
};
export var useVolumeMatrix = function (month) {
    var queueData = useQueueAPI()[0];
    return selectVolumeMatrix(queueData.data)["month".concat(month)];
};
export var useSaveDailyVolumeAPI = function () {
    return useSaveQueueAPI(WAV_SAVE_KEY);
};
