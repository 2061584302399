var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { round as precisionRound } from 'lodash';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useQueueAPI } from '../../queue/selectors';
import { useTrainerQaAPI } from '../selectors';
import { StaffCalculate } from '../types';
import { CalculatorForm } from './calculatorForm';
import { TRAINER_INPUT, TRAINER_ROLE } from './constants';
var calculateTrainerRatio = function (formData) {
    var trainingLength = formData.trainingLength || 1;
    var classSize = formData.classSize || 1;
    var supportStaffShrinkage = precisionRound(formData.shrinkage || 0, 1);
    var numberTrainer = Math.ceil((((formData.attrition * formData.numberReqAgent) / classSize) * trainingLength) /
        4 /
        (1 - supportStaffShrinkage * 0.01));
    var numberOfRequiredAgent = Math.round(formData.numberReqAgent);
    var trainerRatio = numberTrainer ? Math.ceil(numberOfRequiredAgent / numberTrainer) : null;
    return __assign(__assign({}, formData), { trainingLength: trainingLength, classSize: classSize, shrinkage: supportStaffShrinkage, numberTrainer: numberTrainer, numberReqAgent: numberOfRequiredAgent, trainerRatio: trainerRatio });
};
export var TrainerForm = function (_a) {
    var _b, _c;
    var setValue = _a.setValue, watch = _a.watch;
    var queueData = useQueueAPI()[0];
    var roles = (_c = (_b = queueData.data) === null || _b === void 0 ? void 0 : _b.roles) !== null && _c !== void 0 ? _c : [];
    var trainerData = useTrainerQaAPI()[0];
    var _d = useForm(), control = _d.control, reset = _d.reset, handleSubmit = _d.handleSubmit;
    var trainerIdx = roles.findIndex(function (item) { return item.role === TRAINER_ROLE; });
    var isFTEBased = watch("roles.".concat(trainerIdx, ".staffCalculate")) === StaffCalculate.FTE;
    useEffect(function () {
        if (trainerData.data) {
            var value = {
                attrition: trainerData.data.attrition,
                avgNewHire: Math.ceil(trainerData.data.attrition * (isFTEBased ? trainerData.data.agentsFte : trainerData.data.headcountFte)),
                numberReqAgent: isFTEBased ? trainerData.data.agentsFte : trainerData.data.headcountFte,
            };
            reset(value);
        }
    }, [isFTEBased, reset, trainerData.data]);
    var handleFormSubmit = handleSubmit(function (values) {
        var newValues = calculateTrainerRatio(values);
        reset(newValues);
        if (newValues.trainerRatio) {
            setValue("roles.".concat(trainerIdx, ".ratio"), newValues.trainerRatio, {
                shouldDirty: true,
            });
        }
    });
    return _jsx(CalculatorForm, { data: TRAINER_INPUT, control: control, onSubmit: handleFormSubmit });
};
