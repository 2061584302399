var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField } from '@mui/material';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { usePlatformContext } from '../../app/selectors';
import { Autocomplete, ErrorMessageBox, FallbackComponent } from '../../common';
import { FormField, InputLabel } from '../../common/formField';
import { WORK_TIMES } from './createForm';
import { useQueuesAPI, useRenameQueueAPI, useWorkTypeAPI } from './selectors';
export var DialogMode;
(function (DialogMode) {
    DialogMode[DialogMode["Rename"] = 0] = "Rename";
    DialogMode[DialogMode["Delete"] = 1] = "Delete";
})(DialogMode || (DialogMode = {}));
var validateForm = yup.object().shape({
    businessArea: yup.string().required().max(24).trim().specialCharacter().quotesNotAllowed().label('Name'),
});
export var RenameQueueDialog = function (_a) {
    var queueData = _a.queueData, open = _a.open, onClose = _a.onClose;
    var t = useTranslation(['wizard', 'translation']).t;
    var workTypeData = useWorkTypeAPI()[0];
    var _b = useRenameQueueAPI(queueData === null || queueData === void 0 ? void 0 : queueData.id), renameData = _b[0], renameScenario = _b[1], clearRenameData = _b[2];
    var _c = useQueuesAPI(), fetchQueues = _c[1];
    var context = usePlatformContext();
    var defaultValues = useMemo(function () {
        var _a;
        return ({
            businessArea: (_a = queueData === null || queueData === void 0 ? void 0 : queueData.businessArea) !== null && _a !== void 0 ? _a : '',
        });
    }, [queueData === null || queueData === void 0 ? void 0 : queueData.businessArea]);
    var _d = useForm({
        defaultValues: defaultValues,
        resolver: yupResolver(validateForm),
    }), control = _d.control, handleSubmit = _d.handleSubmit, reset = _d.reset;
    useEffect(function () {
        if (open) {
            reset(defaultValues);
        }
    }, [defaultValues, open, reset]);
    useEffect(function () {
        if (open && renameData.data) {
            onClose();
            fetchQueues();
        }
        return clearRenameData;
    }, [renameData.data, onClose, open, clearRenameData, fetchQueues]);
    var handleFormSubmit = function (values) {
        renameScenario(values);
    };
    return (_jsx(Dialog, __assign({ open: open, onClose: onClose }, { children: _jsxs(Box, __assign({ component: "form", onSubmit: handleSubmit(handleFormSubmit) }, { children: [_jsx(DialogTitle, __assign({ variant: "h2" }, { children: t('queue.form.editQueueName', { context: context }) })), _jsx(DialogContent, { children: _jsxs(FallbackComponent, __assign({ requesting: renameData.requesting, overlay: true }, { children: [_jsx(ErrorMessageBox, { errors: [renameData.error] }), _jsxs(Stack, __assign({ direction: "column", gap: 5 }, { children: [_jsx(FormField, __assign({ required: true, name: "businessArea", label: t('queue.form.businessArea.label', { context: context }), control: control }, { children: _jsx(TextField, { inputProps: {
                                                maxLength: 24,
                                                placeholder: t('queue.form.businessArea.placeholder', { context: context }),
                                            } }) })), _jsxs(Stack, __assign({ direction: "row", gap: 5, justifyContent: "space-between" }, { children: [_jsxs(Stack, __assign({ flex: 1 }, { children: [_jsx(InputLabel, __assign({ required: true }, { children: t('queue.form.workType', { context: context }) })), _jsx(Autocomplete, { disabled: true, options: workTypeData.data, value: queueData === null || queueData === void 0 ? void 0 : queueData.worktypeId })] })), _jsxs(Stack, __assign({ flex: 1 }, { children: [_jsx(InputLabel, __assign({ required: true }, { children: t('queue.form.workTime') })), _jsx(Autocomplete, { disabled: true, options: WORK_TIMES, value: queueData === null || queueData === void 0 ? void 0 : queueData.worktime })] }))] }))] }))] })) }), _jsxs(DialogActions, { children: [_jsx(Button, __assign({ variant: "outlined", onClick: onClose }, { children: t('translation:action.cancel') })), _jsx(Button, __assign({ variant: "contained", type: "submit", disabled: renameData.requesting }, { children: t('translation:action.save') }))] })] })) })));
};
