import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { UserStatus } from '../../enum';
import { ConfirmDialog } from '../common';
import { useChangeStatusAPI, useUsersAPI } from './selectors';
export var ChangeStatusDialog = function (_a) {
    var open = _a.open, user = _a.user, onClose = _a.onClose;
    var t = useTranslation(['manageUser', 'translation']).t;
    var _b = useUsersAPI(), fetchUsers = _b[1];
    var _c = useChangeStatusAPI(), changeStatusData = _c[0], changeStatus = _c[1], clearChangeStatusData = _c[2];
    useEffect(function () {
        if (open && changeStatusData.data) {
            onClose();
            fetchUsers();
            return clearChangeStatusData;
        }
    }, [changeStatusData.data, onClose, fetchUsers, clearChangeStatusData, open]);
    var handleChangeStatus = function () {
        var payload = {
            id: user === null || user === void 0 ? void 0 : user.id,
            status: (user === null || user === void 0 ? void 0 : user.status) === UserStatus.Active ? UserStatus.Inactive : UserStatus.Active,
        };
        changeStatus(payload);
    };
    return (_jsx(ConfirmDialog, { open: open, onClose: onClose, title: t('changeStatusTitle', { username: user === null || user === void 0 ? void 0 : user.name }), message: t('translation:confirmation.yesChange'), onConfirm: handleChangeStatus, loading: !!changeStatusData.requesting, errors: [changeStatusData.error] }));
};
