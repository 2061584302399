var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faFileChartColumn } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AppBar, Box, Button, IconButton, Menu, MenuItem, buttonClasses, styled } from '@mui/material';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { useAuthStrategy } from '../../authentication';
import { Role } from '../../enum';
import { Authenticated } from '../../router/auth';
import { ROUTER_URL } from '../../router/constants';
import { useUserSessionAPI } from '../app/selectors';
import { SettingMFA } from '../mfa';
var StyledHeader = styled(AppBar)(function (_a) {
    var theme = _a.theme;
    return ({
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        padding: theme.typography.pxToRem(15),
        paddingLeft: 0,
        fontSize: theme.typography.body2.fontSize,
        color: theme.palette.common.white,
        gap: theme.spacing(14),
        minHeight: theme.typography.pxToRem(60),
    });
});
var ImageLogo = styled('img')(function (_a) {
    var theme = _a.theme;
    return ({
        display: 'block',
        height: theme.typography.pxToRem(29),
    });
});
var RightPanel = styled(Box)(function (_a) {
    var theme = _a.theme;
    return ({
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        marginBottom: "-".concat(theme.spacing(1.5)),
    });
});
var NavItem = styled(Link)((_a = {},
    _a["&.".concat(buttonClasses.root, ".").concat(buttonClasses.disabled)] = {
        color: 'unset',
        opacity: 0.5,
    },
    _a));
var NavIcon = styled(FontAwesomeIcon)(function (_a) {
    var theme = _a.theme;
    return ({
        color: 'inherit',
        height: theme.typography.pxToRem(18),
        paddingBottom: theme.spacing(1),
    });
});
var UserButton = styled(IconButton)({
    fontSize: 'inherit',
    marginLeft: 'auto',
});
export var Header = function () {
    var authStrategy = useAuthStrategy();
    var data = useUserSessionAPI()[0].data;
    var _a = useState(), anchorEl = _a[0], setAnchorEl = _a[1];
    var t = useTranslation().t;
    var location = useLocation();
    var _b = useState(false), openMFA = _b[0], setOpenMFA = _b[1];
    var shouldDisplayMyPlan = location.pathname === ROUTER_URL.Home || location.pathname === ROUTER_URL.Users;
    var enableUserMenu = process.env.REACT_APP_USER_MENU !== 'false';
    var handleClick = function (event) {
        setAnchorEl(event.currentTarget);
    };
    var handleClose = function () {
        setAnchorEl(undefined);
    };
    var handleCloseMFADialog = useCallback(function () {
        setOpenMFA(false);
    }, []);
    return (_jsxs(StyledHeader, { children: [_jsx(ImageLogo, { src: "/assets/images/whiteBrand.png" }), _jsxs(RightPanel, { children: [shouldDisplayMyPlan && (_jsxs(Button, __assign({ component: NavItem, to: ROUTER_URL.Home, color: "inherit", size: "small", disabled: location.pathname === ROUTER_URL.Home }, { children: [_jsx(NavIcon, { icon: faFileChartColumn }), "\u00A0\u00A0", t('layout.myPlans')] }))), enableUserMenu && (_jsxs(UserButton, __assign({ color: "inherit", onClick: handleClick, size: "small" }, { children: [_jsx(FontAwesomeIcon, { icon: "user" }), "\u00A0\u00A0", data === null || data === void 0 ? void 0 : data.name, "\u00A0\u00A0", _jsx(FontAwesomeIcon, { icon: "chevron-down" })] })))] }), _jsxs(Menu, __assign({ anchorEl: anchorEl, open: !!anchorEl, onClick: handleClose, onClose: handleClose, anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                } }, { children: [_jsx(Authenticated, __assign({ roles: [Role.Superadmin] }, { children: _jsx(MenuItem, __assign({ component: Link, to: ROUTER_URL.Users }, { children: t('layout.managerUsers') })) })), enableUserMenu && _jsx(MenuItem, __assign({ onClick: function () { return setOpenMFA(true); } }, { children: t('layout.settingMFA') })), _jsx(MenuItem, __assign({ onClick: authStrategy.logout }, { children: t('layout.logout') }))] })), _jsx(SettingMFA, { open: openMFA, onClose: handleCloseMFADialog })] }));
};
