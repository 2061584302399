import _ from 'lodash';
import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useAPI } from '../../../state';
var QUEUE_AVERAGE_API = '/api/multiskill/{planId}/average';
var QUEUE_API = '/api/queue/{id}';
var MULTI_SKILL_API = '/api/multiskill/{planId}';
var MULTISKILL_SIMULATION_API = '/api/simulation/{planId}';
var useAverageAPI = function (queueIds) {
    var id = useParams().id;
    var _a = useAPI(QUEUE_AVERAGE_API, { planId: id }), data = _a[0], fetchData = _a[1], clearData = _a[2];
    var fetchDataWrapper = useCallback(function () {
        fetchData(undefined, { queueIds: queueIds === null || queueIds === void 0 ? void 0 : queueIds.join(',') });
    }, [fetchData, queueIds]);
    return [data, fetchDataWrapper, clearData];
};
var useQueueAPI = function (id) {
    var _a = useAPI(QUEUE_API, { id: id }), data = _a[0], fetchData = _a[1], clearData = _a[2];
    var fetchDataWrapper = useCallback(function () {
        if (id) {
            fetchData();
        }
    }, [fetchData, id]);
    return [data, fetchDataWrapper, clearData];
};
export var useGroupDetailAPI = function (data) {
    var queueAverage = useAverageAPI(data === null || data === void 0 ? void 0 : data.children);
    var queueDetail = useQueueAPI(!(data === null || data === void 0 ? void 0 : data.shrinkages) ? data === null || data === void 0 ? void 0 : data.id : undefined);
    var shouldUseAverage = useMemo(function () { return !!_.xor(data === null || data === void 0 ? void 0 : data._children, data === null || data === void 0 ? void 0 : data.children).length; }, [data === null || data === void 0 ? void 0 : data._children, data === null || data === void 0 ? void 0 : data.children]);
    return shouldUseAverage ? queueAverage : queueDetail;
};
export var useMultiSkillAPI = function () {
    var planId = useParams().id;
    return useAPI(MULTI_SKILL_API, { planId: planId }, 'POST');
};
export var useSimulationAPI = function () {
    var planId = useParams().id;
    return useAPI(MULTISKILL_SIMULATION_API, { planId: planId }, 'POST');
};
