var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FallbackComponent } from '../common';
import { useResourcingContext } from '../report/hooks';
import { ViewType } from '../report/resourcing';
import { useReportAPI, useViewTypeParams } from '../report/selectors';
import { FinancialGraphical, ResourcingGraphical } from './graphical';
import { FinancialTable, ResourcingTable } from './periodTable';
import { usePeriodReportAPI } from './selectors';
var PeriodReport = function (props) {
    var _a = useReportAPI(), reportData = _a[0], fetchReportData = _a[1];
    var _b = usePeriodReportAPI(), periodReportData = _b[0], fetchPeriodReport = _b[1], clearPeriodReportData = _b[2];
    useEffect(function () {
        fetchPeriodReport();
        fetchReportData();
        return clearPeriodReportData;
    }, [fetchPeriodReport, clearPeriodReportData, fetchReportData]);
    return (_jsx(FallbackComponent, __assign({ requesting: !!periodReportData.requesting || !!reportData.requesting }, { children: props.children })));
};
export var PeriodResourcingReport = function () {
    var t = useTranslation('report', { keyPrefix: 'resourcing' }).t;
    var setDescription = useResourcingContext().setDescription;
    var viewType = useViewTypeParams()[0];
    useEffect(function () {
        setDescription(t('description'));
        return setDescription;
    }, [setDescription, t]);
    return _jsx(PeriodReport, { children: viewType === ViewType.Table ? _jsx(ResourcingTable, {}) : _jsx(ResourcingGraphical, {}) });
};
export var PeriodFinancialReport = function () {
    var viewType = useViewTypeParams()[0];
    return _jsx(PeriodReport, { children: viewType === ViewType.Table ? _jsx(FinancialTable, {}) : _jsx(FinancialGraphical, {}) });
};
