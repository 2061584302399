var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog, DialogContent, IconButton, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useScenarioSelected } from './selectors';
import { SummaryTable } from './summaryTable';
export var SummaryDialog = function (_a) {
    var open = _a.open, onClose = _a.onClose, scenarioId = _a.scenarioId;
    var t = useTranslation('report', { keyPrefix: 'training.summary' }).t;
    var name = useScenarioSelected(scenarioId).name;
    return (_jsxs(Dialog, __assign({ maxWidth: "xl", open: open, onClose: onClose }, { children: [_jsxs(Stack, __assign({ direction: "row", px: "1rem", paddingTop: "1rem", alignItems: "center", justifyContent: "space-between" }, { children: [_jsx(Typography, __assign({ variant: "h2" }, { children: t('title', { name: name }) })), _jsx(IconButton, __assign({ "aria-label": "close", onClick: onClose, sx: { width: '2.5rem' } }, { children: _jsx(FontAwesomeIcon, { icon: "xmark" }) }))] })), _jsx(DialogContent, __assign({ sx: { pt: 2 } }, { children: _jsx(SummaryTable, { scenarioId: scenarioId }) }))] })));
};
