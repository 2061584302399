var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CircularProgress, IconButton, Link as MuiLink, Stack, styled } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useDeleteMultiskill, useSummaryReportAPI } from '../annual/selectors';
import { useSimulatingInProgress, useToastMessagesAPI } from '../toastify/selector';
import { useCancelSimulation } from './selectors';
var StyledLink = styled(MuiLink)(function (_a) {
    var theme = _a.theme;
    return ({
        color: theme.palette.common.white,
        textDecorationColor: theme.palette.common.white,
        ':hover': {
            color: theme.palette.common.white,
        },
    });
});
var SimulationMenuItem = React.memo(function (props) {
    var t = useTranslation().t;
    var basePlanId = useParams().basePlanId;
    var scenarioId = props.sim.details.scenarioId;
    var scenarioName = props.sim.scenarioName;
    var _a = useCancelSimulation(scenarioId, Number(basePlanId)), cancelSimulationData = _a[0], cancelSimulation = _a[1], clearCancelSimulationData = _a[2];
    var _b = useDeleteMultiskill(scenarioId), deleteMultiskillData = _b[0], deleteMultiskill = _b[1], clearDeleteMultiskillData = _b[2];
    var _c = useSummaryReportAPI(), fetchSummaryReport = _c[1];
    var _d = useToastMessagesAPI(), fetchToastMessages = _d[1];
    var requesting = !!deleteMultiskillData.requesting || !!cancelSimulationData.requesting;
    var cancelDone = !!deleteMultiskillData.data && !!cancelSimulationData.data;
    useEffect(function () {
        if (deleteMultiskillData.data && cancelSimulationData.data) {
            fetchSummaryReport();
            fetchToastMessages();
            return function () {
                clearCancelSimulationData();
                clearDeleteMultiskillData();
            };
        }
    }, [
        cancelSimulationData.data,
        deleteMultiskillData.data,
        fetchSummaryReport,
        fetchToastMessages,
        clearCancelSimulationData,
        clearDeleteMultiskillData,
    ]);
    var handleStop = function () {
        deleteMultiskill();
        cancelSimulation({});
    };
    if (cancelDone)
        return null;
    return (_jsx(Stack, __assign({ direction: "row", spacing: 2, padding: 2, paddingLeft: 0 }, { children: _jsxs(Stack, __assign({ direction: "row", gap: 3, alignItems: "center", justifyContent: "flex-end", width: "100%" }, { children: [scenarioName, ":", requesting ? (_jsx(CircularProgress, { color: "inherit", size: "1rem" })) : (_jsx(StyledLink, __assign({ component: "button", onClick: handleStop }, { children: t('toastify.stopSimulation') })))] })) })));
});
export var ProgressToastContent = function () {
    var t = useTranslation().t;
    var basePlanId = useParams().basePlanId;
    var simulatingInProgress = useSimulatingInProgress(basePlanId);
    var _a = useState(false), expanded = _a[0], setExpanded = _a[1];
    return (_jsxs(Stack, __assign({ direction: "column-reverse", spacing: 1 }, { children: [_jsxs(Stack, __assign({ direction: "row", gap: 2, alignItems: "center", justifyContent: "space-between", width: "100%" }, { children: [_jsx(CircularProgress, { color: "inherit", size: "1.5rem" }), t('toastify.calculating'), _jsx(IconButton, __assign({ onClick: function () { return setExpanded(!expanded); } }, { children: _jsx(FontAwesomeIcon, { color: "white", icon: expanded ? 'chevron-down' : 'chevron-up' }) }))] })), expanded && _jsx(Stack, { children: simulatingInProgress === null || simulatingInProgress === void 0 ? void 0 : simulatingInProgress.map(function (sim) { return _jsx(SimulationMenuItem, { sim: sim }, sim.id); }) })] })));
};
