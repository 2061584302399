var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Typography } from '@mui/material';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Accordion } from '../../common';
import { useQueueAPI } from '../queue/selectors';
import { usePlanType } from '../selectors';
import { WorkArrivalTimeContainer } from './timeContainer';
import { DailyWorkVolumeContainer } from './volumeContainer';
var WAPAccordionControl = function (props) {
    var _a;
    var t = useTranslation('wizard', { keyPrefix: 'wapTime' }).t;
    var queueData = useQueueAPI()[0];
    var defaultExpanded = useRef(!!((_a = queueData.data) === null || _a === void 0 ? void 0 : _a.wapTimes.length));
    if (!queueData.data)
        return null;
    return _jsx(Accordion, __assign({ title: t('title'), defaultExpanded: defaultExpanded.current }, props));
};
export var WAPAccordion = function () {
    var _a;
    var t = useTranslation('wizard', { keyPrefix: 'wapTime' }).t;
    var planType = usePlanType();
    var queueData = useQueueAPI()[0];
    return (_jsxs(WAPAccordionControl, { children: [_jsx(Typography, { children: t('description', { planType: planType }) }), _jsx(WorkArrivalTimeContainer, {}), _jsx(DailyWorkVolumeContainer, {})] }, (_a = queueData.data) === null || _a === void 0 ? void 0 : _a.id));
};
