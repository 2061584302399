var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Stack } from '@mui/material';
import { StatusPermission } from '../../../enum';
import { useUserPermissionsAPI } from '../../app/selectors';
import { QueuePanel } from '../common/queuePanel';
import { StepContainer, StepPanel } from '../common/stepContainer';
import { OvertimeCost } from './overtimeCost';
import { StaffSalaries } from './staffSalaries';
export var FinancialStep = function () {
    var permission = useUserPermissionsAPI();
    return (_jsx(StepContainer, __assign({ leftContent: _jsx(QueuePanel, {}) }, { children: _jsx(StepPanel, { children: _jsxs(Stack, __assign({ direction: "column", spacing: 8 }, { children: [(permission === null || permission === void 0 ? void 0 : permission.financialPermission) !== StatusPermission.Hide && _jsx(StaffSalaries, {}), _jsx(OvertimeCost, {})] })) }) })));
};
