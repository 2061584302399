var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormControl, FormHelperText, RadioGroup as MuiRadioGroup } from '@mui/material';
export var RadioGroup = function (_a) {
    var children = _a.children, helperText = _a.helperText, _b = _a.value, value = _b === void 0 ? null : _b, inputRef = _a.inputRef, error = _a.error, props = __rest(_a, ["children", "helperText", "value", "inputRef", "error"]);
    return (_jsxs(FormControl, __assign({ component: "fieldset" }, { children: [_jsx(MuiRadioGroup, __assign({}, props, { ref: inputRef, value: value }, { children: children })), _jsx(FormHelperText, __assign({ error: error }, { children: helperText }))] })));
};
