var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { usePlatformContext } from '../app/selectors';
import { SubTitle } from '../layout/styled';
import { usePlanType, useWizardParams } from './selectors';
export var Description = function () {
    var t = useTranslation('wizard').t;
    var _a = useWizardParams(), step = _a.step, stepName = _a.stepName;
    var planType = usePlanType();
    var context = usePlatformContext();
    return (_jsxs(_Fragment, { children: [_jsxs(Typography, __assign({ variant: "h1" }, { children: [t('message.stepCount', { index: step + 1 }), t("".concat(stepName, ".fullName"), { context: context })] })), _jsx(SubTitle, { children: t("".concat(stepName, ".description"), { planType: planType, context: context }) })] }));
};
