var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useMemo } from 'react';
var WizardSimpleContext = createContext(null);
export var useWizardSimpleContext = function () {
    return useContext(WizardSimpleContext);
};
export var WizardSimpleProvider = function (props) {
    var plan = props.plan, queue = props.queue;
    var value = useMemo(function () { return ({ plan: plan, queue: queue }); }, [plan, queue]);
    return _jsx(WizardSimpleContext.Provider, __assign({ value: value }, { children: props.children }));
};
