var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Checkbox, FormControlLabel, Link, Typography, styled } from '@mui/material';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useToggle } from 'usehooks-ts';
import { useUserSessionAPI } from '../app/selectors';
import { FallbackComponent } from '../common';
import { FluidContainer } from '../layout';
import { TERMS_OF_USE_URL } from '../layout/footer';
import { useUpdateTouAPI } from './selectors';
var StyledContent = styled(Typography)(function (_a) {
    var theme = _a.theme;
    return ({
        width: theme.typography.pxToRem(576),
        margin: theme.spacing(4, 0, 6),
    });
});
export var TermOfUse = function () {
    var t = useTranslation().t;
    var _a = useToggle(), isAccepted = _a[0], toggle = _a[1];
    var _b = useUpdateTouAPI(), _c = _b[0], data = _c.data, requesting = _c.requesting, updateTou = _b[1], clearTouData = _b[2];
    var _d = useUserSessionAPI(), fetchUserSession = _d[1];
    var handleSubmitTou = function () {
        updateTou();
    };
    useEffect(function () {
        if (data) {
            fetchUserSession();
            return clearTouData;
        }
    }, [clearTouData, data, fetchUserSession]);
    return (_jsx(FallbackComponent, __assign({ overlay: true, requesting: requesting }, { children: _jsxs(FluidContainer, { children: [_jsx(StyledContent, { children: t('tou.mainContent') }), _jsx(FormControlLabel, { control: _jsx(Checkbox, { size: "small", onChange: toggle }), label: _jsxs(Typography, __assign({ variant: "subtitle1", sx: { userSelect: 'none' } }, { children: [t('tou.acceptText'), _jsx(Link, __assign({ href: TERMS_OF_USE_URL, target: "_blank" }, { children: t('footer.termsOfUse') }))] })) }), _jsx(Button, __assign({ variant: "contained", disabled: !isAccepted || requesting, onClick: handleSubmitTou }, { children: t('action.continue') }))] }) })));
};
