var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, Box, Collapse, FormHelperText, IconButton } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
export var ErrorMessageBox = function (_a) {
    var errors = _a.errors, onClose = _a.onClose, props = __rest(_a, ["errors", "onClose"]);
    var oldError = useRef();
    var oldErrors = useRef([]);
    var _b = useState(), error = _b[0], setError = _b[1];
    var handleChangeError = function (e) {
        oldError.current = e;
        setError(e);
    };
    var handleClose = function () {
        handleChangeError(undefined);
        onClose === null || onClose === void 0 ? void 0 : onClose();
    };
    useEffect(function () {
        var e = errors.find(function (value, index) { return value !== oldErrors.current[index]; });
        oldErrors.current = errors;
        if (!!oldError.current && e) {
            handleChangeError(undefined);
            var handler_1 = setTimeout(function () { return handleChangeError(e); }, 500);
            return function () {
                clearTimeout(handler_1);
            };
        }
        else {
            handleChangeError(e);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, __spreadArray([], errors, true));
    return (_jsx(Collapse, __assign({}, props, { in: !!(error === null || error === void 0 ? void 0 : error.message), unmountOnExit: true }, { children: _jsx(Alert, __assign({ severity: "error", action: _jsx(IconButton, __assign({ color: "inherit", size: "small", onClick: handleClose }, { children: _jsx(FontAwesomeIcon, { icon: "xmark" }) })), sx: { mb: 4, border: '1px solid', borderColor: 'error.main' } }, { children: error === null || error === void 0 ? void 0 : error.message })) })));
};
export var FormErrorText = function (_a) {
    var message = _a.message, _b = _a.scrollIntoView, scrollIntoView = _b === void 0 ? true : _b, _c = _a.bigText, bigText = _c === void 0 ? true : _c;
    var t = useTranslation('translation', { keyPrefix: 'form' }).t;
    var ref = useRef(null);
    var displayMsg = message === 'required' ? undefined : message;
    var messageRef = useRef();
    useEffect(function () {
        var _a;
        if (displayMsg && !messageRef.current && scrollIntoView) {
            (_a = ref.current) === null || _a === void 0 ? void 0 : _a.scrollIntoView();
        }
        messageRef.current = displayMsg;
    }, [displayMsg, scrollIntoView]);
    return (_jsx(Box, __assign({ ref: ref }, { children: displayMsg && (_jsx(FormHelperText, __assign({ sx: { fontSize: bigText ? '0.875rem' : undefined }, error: true }, { children: t(displayMsg, { defaultValue: displayMsg }) }))) })));
};
