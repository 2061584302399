var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Link, Typography, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAuthStrategy } from '../../authentication';
import { FluidContainer } from '../layout';
import { SUPPORT_EMAIL } from '../layout/footer';
var StyledTitle = styled(Typography)(function (_a) {
    var theme = _a.theme;
    return ({
        margin: theme.spacing(15, 0),
        color: theme.palette.error.dark,
    });
});
var StyledContent = styled(Typography)(function (_a) {
    var theme = _a.theme;
    return ({
        marginBottom: theme.spacing(15),
        width: theme.typography.pxToRem(400),
    });
});
export var ExpiredUser = function () {
    var t = useTranslation().t;
    var authStrategy = useAuthStrategy();
    return (_jsxs(FluidContainer, { children: [_jsx(StyledTitle, __assign({ variant: "subtitle1" }, { children: t('expired.message') })), _jsxs(StyledContent, { children: [t('expired.pleaseContact'), _jsx(Link, __assign({ href: "mailto:".concat(SUPPORT_EMAIL) }, { children: SUPPORT_EMAIL })), t('expired.forFurtherAssistance')] }), _jsx(Button, __assign({ variant: "contained", onClick: authStrategy.logout }, { children: t('action.backToLogin') }))] }));
};
