var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export var TICK_GAP = '  ';
export var BAR_BUTTONS = [['zoom2d', 'pan2d', 'zoomIn2d', 'zoomOut2d', 'resetScale2d']];
export var LINE_BUTTONS = [['hoverClosestCartesian', 'hoverCompareCartesian', 'resetScale2d']];
export var traceBarConfig = {
    type: 'bar',
    textposition: 'outside',
    insidetextanchor: 'middle',
    showlegend: true,
    cliponaxis: false,
    textangle: 0,
    textfont: { size: 12 },
};
export var traceStackConfig = __assign(__assign({}, traceBarConfig), { textposition: 'inside', showlegend: false });
export var configDefault = {
    displaylogo: false,
    responsive: true,
    showAxisDragHandles: false,
};
export var traceLineConfig = {
    type: 'scatter',
    mode: 'lines+markers',
    showlegend: true,
    line: {
        width: 4,
    },
    marker: {
        size: 12,
    },
};
export var layoutDefault = {
    uniformtext: {
        mode: 'show',
    },
    titlefont: {
        size: 20,
    },
    margin: {
        l: 100,
        r: 30,
    },
    xaxis: {
        tickfont: {
            size: 14,
        },
        showgrid: false,
        tickmode: 'array',
        automargin: true,
        gridcolor: '#f2f2f2',
        gridwidth: 50,
        constrain: 'range',
        range: [-0.5, 11.5],
        autorange: true,
    },
    yaxis: {
        automargin: true,
        zeroline: true,
        zerolinecolor: '#D7D7D7',
        zerolinewidth: 1,
        gridcolor: '#D7D7D7',
        tickfont: { size: 14 },
        ticksuffix: TICK_GAP,
        titlefont: {
            size: 18,
        },
    },
    legend: {
        x: 0.5,
        y: -0.24,
        yanchor: 'bottom',
        orientation: 'h',
        itemclick: false,
        itemdoubleclick: false,
        font: {
            size: 16,
        },
        xanchor: 'center',
        itemsizing: 'trace',
    },
    bargroupgap: 0.1,
    colorway: ['#333366', '#b25c03', '#136a21', '#994f4f', '#666633', '#560276'],
};
export var bold = function (text) { return "<b>".concat(text, "</b>"); };
