import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { Currency, PlanType } from '../../enum';
import { usePlatformContext } from '../app/selectors';
import { Chart, ErrorMessageBox } from '../common';
import { BAR_BUTTONS, LINE_BUTTONS, bold, layoutDefault, traceBarConfig, traceLineConfig, } from '../common/chart/config';
import { PdfType } from '../report/constants';
import { usePrintChartAPI, useReportAPI, useReportPlanType } from '../report/selectors';
import { MONTH_OF_YEAR } from '../wizard/selectors';
import { FINANCIAL_CHARTS, RESOURCING_CHARTS } from './constants';
import { usePeriodGraphical, usePeriodReportAPI } from './selectors';
import { generateColorByIndex, hoverTemplate, textTemplate } from './util';
export var PeriodGraphical = function (props) {
    var _a, _b;
    var t = useTranslation('report', { keyPrefix: 'period.chart' }).t;
    var reportData = useReportAPI()[0].data;
    var periodData = usePeriodReportAPI()[0];
    var planType = useReportPlanType();
    var printData = usePrintChartAPI(PdfType.PeriodChart, planType !== PlanType.Monthly)[0];
    var charts = usePeriodGraphical(props.types);
    var context = usePlatformContext();
    var periodLength = (_a = reportData === null || reportData === void 0 ? void 0 : reportData.periodLength) !== null && _a !== void 0 ? _a : 0;
    var currency = (_b = reportData === null || reportData === void 0 ? void 0 : reportData.currency) !== null && _b !== void 0 ? _b : Currency.Dollar;
    var traceConfig = planType === PlanType.Weekly ? traceLineConfig : traceBarConfig;
    var modeBarButtons = planType === PlanType.Weekly ? LINE_BUTTONS : BAR_BUTTONS;
    var yearSuffix = function (year) {
        return planType === PlanType.Weekly || periodLength <= MONTH_OF_YEAR ? '' : t('yearSuffix', { year: year + 1 });
    };
    var handleData = function (data, weekly, key) {
        var layout = _.cloneDeep(layoutDefault);
        var colorway = generateColorByIndex(reportData === null || reportData === void 0 ? void 0 : reportData.scenarios);
        layout.showlegend = true;
        layout.colorway = colorway;
        if (planType === PlanType.Weekly && layout.xaxis) {
            var autoRange = periodLength <= MONTH_OF_YEAR;
            layout.xaxis.tickvals = _.range(periodLength);
            layout.xaxis.ticktext = weekly;
            layout.xaxis.autorange = autoRange;
            layout.xaxis.automargin = false;
            layout.xaxis.fixedrange = !autoRange;
            layout.xaxis.showgrid = true;
            layout.xaxis.rangeslider = {
                visible: false,
                thickness: 0.04,
                bgcolor: '#f1f1f1',
                bordercolor: '#c3c3c3',
                borderwidth: 1,
            };
            _.set(layout, 'xaxis.rangeslider.visible', !autoRange);
            _.set(layout, 'xaxis.rangeslider.range', [0, periodLength - 0.5]);
            _.set(layout, 'legend.yanchor', 'top');
            _.set(layout, 'legend.y', autoRange ? -0.2 : -0.34);
        }
        traceConfig.texttemplate = textTemplate(key, currency);
        traceConfig.textfont = { size: 12 };
        var chartData = _.map(data, function (obj) {
            return _.assign({}, obj, traceConfig, { hovertemplate: hoverTemplate(obj.name, key, traceConfig === null || traceConfig === void 0 ? void 0 : traceConfig.type, currency) });
        });
        return {
            chartData: chartData,
            layout: layout,
        };
    };
    return (_jsxs(_Fragment, { children: [_jsx(ErrorMessageBox, { errors: [periodData.error, printData.error] }), charts === null || charts === void 0 ? void 0 : charts.map(function (_a) {
                var key = _a.key, year = _a.year, data = _a.data, weekly = _a.weekly;
                var _b = handleData(data, weekly, key), chartData = _b.chartData, layout = _b.layout;
                return (_jsx(Chart, { title: t("".concat(key, ".title"), { planType: t("plan".concat(planType)), context: context }) + yearSuffix(year), yAxisTitle: bold(t("".concat(key, ".yAxisTitle"), { context: context })), data: chartData, yTickPrefix: key === 'avgCostPerWork' || key === 'totalBudgets' ? currency : '', layout: layout, maxXAxis: periodLength, config: { modeBarButtons: props.disableBarButtons ? [[]] : modeBarButtons } }, key + year));
            })] }));
};
export var ResourcingGraphical = function () {
    return _jsx(PeriodGraphical, { types: RESOURCING_CHARTS });
};
export var FinancialGraphical = function () {
    return _jsx(PeriodGraphical, { types: FINANCIAL_CHARTS });
};
