var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { TrainingColumn } from './enum';
export var TRAINING_KEY_EDITABLE = [
    TrainingColumn.AdjustedAttritionRate,
    TrainingColumn.ForecastedGraduationRate,
    TrainingColumn.TrainingLength,
    TrainingColumn.ClassSize,
    TrainingColumn.RecruitingLeadTime,
];
export var NON_DEFERRABLE_BOTTOM_KEY = [
    TrainingColumn.ForecastedServiceLevel,
    TrainingColumn.ForecatedAnswerSpeed,
    TrainingColumn.ForecastedOccupancy,
];
export var TRAINING_KEY_BOTTOM = __spreadArray([TrainingColumn.StaffOverUnder], NON_DEFERRABLE_BOTTOM_KEY, true);
export var SUMMARY_TRAINING_KEY = [
    TrainingColumn.TotalNewHireRequired,
    TrainingColumn.RecruitingStartDate,
    TrainingColumn.NewHireClassStartDate,
    TrainingColumn.TrainerRequire,
];
export var TRAINING_KEY = __spreadArray(__spreadArray(__spreadArray(__spreadArray([
    TrainingColumn.ForecastAttritionRate,
    TrainingColumn.AdjustedAttritionRate,
    TrainingColumn.RosteredStaffPlanned,
    TrainingColumn.ForecastedAgentAttrition
], TRAINING_KEY_EDITABLE.filter(function (key) { return key !== TrainingColumn.AdjustedAttritionRate; }), true), [
    TrainingColumn.TotalLeadTimeToGraduation
], false), SUMMARY_TRAINING_KEY, true), TRAINING_KEY_BOTTOM, true);
export var SUMMARY_TRAINING_KEY_BOTTOM = [
    TrainingColumn.TotalNewHireRequired,
    TrainingColumn.StaffOverUnder,
    TrainingColumn.TrainerRequire,
];
export var PERCENTAGE_KEYS = [
    TrainingColumn.ForecastAttritionRate,
    TrainingColumn.AdjustedAttritionRate,
    TrainingColumn.ForecastedGraduationRate,
    TrainingColumn.ForecastedServiceLevel,
    TrainingColumn.ForecastedOccupancy,
];
export var MAX_KEY = [
    TrainingColumn.TrainingLength,
    TrainingColumn.ForecastedGraduationRate,
    TrainingColumn.AdjustedAttritionRate,
];
export var BOLD_KEYS = __spreadArray(__spreadArray(__spreadArray([], SUMMARY_TRAINING_KEY, true), TRAINING_KEY_BOTTOM, true), [TrainingColumn.ForecastAttritionRate], false);
