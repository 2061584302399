export var Completed;
(function (Completed) {
    Completed[Completed["No"] = 0] = "No";
    Completed[Completed["Yes"] = 1] = "Yes";
})(Completed || (Completed = {}));
export var QueueStatusType;
(function (QueueStatusType) {
    QueueStatusType["Volume"] = "volume";
    QueueStatusType["HandleTime"] = "handleTime";
    QueueStatusType["LearnCurve"] = "learnCurve";
    QueueStatusType["Attrition"] = "attrition";
    QueueStatusType["Shrinkages"] = "shrinkages";
    QueueStatusType["WorkingDays"] = "workingDays";
    QueueStatusType["SupportStaff"] = "supportStaff";
    QueueStatusType["Salary"] = "salary";
    QueueStatusType["WAP"] = "wap";
    QueueStatusType["WAT"] = "wat";
    QueueStatusType["WAV"] = "wav";
    QueueStatusType["Rate"] = "rate";
})(QueueStatusType || (QueueStatusType = {}));
export var WorkType;
(function (WorkType) {
    WorkType[WorkType["InboundCalls"] = 1] = "InboundCalls";
    WorkType[WorkType["OutboundCalls"] = 2] = "OutboundCalls";
    WorkType[WorkType["Email"] = 3] = "Email";
    WorkType[WorkType["Chat"] = 4] = "Chat";
    WorkType[WorkType["SMS"] = 5] = "SMS";
    WorkType[WorkType["Social"] = 6] = "Social";
    WorkType[WorkType["LetterMail"] = 7] = "LetterMail";
    WorkType[WorkType["Fax"] = 8] = "Fax";
    WorkType[WorkType["WorkItems"] = 9] = "WorkItems";
    WorkType[WorkType["Other"] = 10] = "Other";
})(WorkType || (WorkType = {}));
