import { useCallback } from 'react';
import { substituteParams } from '../../state';
import { getDownloadFileName, useDownload } from '../hooks';
var PDF_API = '/api/report/pdf?state={state}';
export var usePdfAPI = function (url, fileName, substitutions) {
    var state = encodeURIComponent(btoa(substituteParams(url, substitutions)));
    var _a = useDownload(PDF_API, fileName, {
        substitutions: { state: state },
        responseType: 'json',
    }), data = _a[0], fetchData = _a[1], clearData = _a[2];
    var fetchDataWrapper = useCallback(function () {
        fetchData(undefined, { fileName: getDownloadFileName(fileName) });
    }, [fetchData, fileName]);
    return [data, fetchDataWrapper, clearData];
};
