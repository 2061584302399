var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { createSelector } from '@reduxjs/toolkit';
import axios, { HttpStatusCode } from 'axios';
import { useCallback, useMemo } from 'react';
import { useAuthStrategy } from '../../authentication';
import { useAppDispatch, useAppSelector } from '../hooks';
import { fetchFailed, fetchReset, fetchStart, fetchSuccess } from './reducer';
export var CHALLENGE_STATUS = 'challengeStatus';
var apiSelector = function (state) { return state.api; };
var defaultData = {};
var createDataSelector = function (key) { return createSelector(apiSelector, function (api) { var _a; return (_a = api[key]) !== null && _a !== void 0 ? _a : defaultData; }); };
export var substituteParams = function (str, params) {
    return params !== undefined
        ? Object.keys(params).reduce(function (current, key) { var _a; return current.replace(new RegExp("{".concat(key, "}|:").concat(key)), "".concat((_a = params[key]) !== null && _a !== void 0 ? _a : '')); }, str)
        : str;
};
var APIClient = /** @class */ (function () {
    function APIClient(authStrategy, url, key, method, responseType, contentType) {
        this.authStrategy = authStrategy;
        this.url = url;
        this.key = "".concat(key, ":").concat(method);
        this.method = method;
        this.responseType = responseType;
        this.contentType = contentType;
    }
    APIClient.prototype.getKey = function () {
        return this.key;
    };
    APIClient.prototype.abort = function (state) {
        var abortController = (state.api[this.key] || {}).abortController;
        abortController === null || abortController === void 0 ? void 0 : abortController.abort();
    };
    APIClient.prototype.resetState = function () {
        var _this = this;
        return function (dispatch, getState) {
            _this.abort(getState());
            dispatch(fetchReset({ key: _this.key }));
        };
    };
    APIClient.prototype.request = function (options) {
        var _this = this;
        return function (dispatch, getState) { return __awaiter(_this, void 0, void 0, function () {
            var abortController, requestOptions, _a, data, statusCode, e_1, internalError, busyError, _b, data, statusCode, error, _c, _d, __1;
            var _e, _f, _g, _h, _j;
            return __generator(this, function (_k) {
                switch (_k.label) {
                    case 0:
                        if (!this.url)
                            return [2 /*return*/];
                        this.abort(getState());
                        abortController = new AbortController();
                        dispatch(fetchStart({
                            key: this.key,
                            abortController: abortController,
                        }));
                        _k.label = 1;
                    case 1:
                        _k.trys.push([1, 3, , 11]);
                        requestOptions = this.authStrategy.getRequestOptions();
                        return [4 /*yield*/, axios(__assign(__assign(__assign({ url: this.url, method: this.method, signal: abortController.signal, responseType: this.responseType }, requestOptions), options), { headers: __assign(__assign({}, requestOptions.headers), { 'Content-Type': (_e = this.contentType) !== null && _e !== void 0 ? _e : (_f = requestOptions.headers) === null || _f === void 0 ? void 0 : _f['Content-Type'] }) }))];
                    case 2:
                        _a = _k.sent(), data = _a.data, statusCode = _a.status;
                        dispatch(fetchSuccess({ key: this.key, data: data, statusCode: statusCode }));
                        return [3 /*break*/, 11];
                    case 3:
                        e_1 = _k.sent();
                        internalError = { message: 'Something went wrong. Please try again in a few minutes.' };
                        busyError = {
                            message: 'The system is currently busy, please try again in a couple of minutes',
                        };
                        if (!axios.isCancel(e_1)) return [3 /*break*/, 4];
                        return [3 /*break*/, 10];
                    case 4:
                        if (!axios.isAxiosError(e_1)) return [3 /*break*/, 9];
                        _b = (_g = e_1.response) !== null && _g !== void 0 ? _g : {}, data = _b.data, statusCode = _b.status;
                        error = data || internalError;
                        if (statusCode === HttpStatusCode.Unauthorized) {
                            this.authStrategy.onUnauthorized();
                            return [2 /*return*/, dispatch(fetchReset({ key: this.key }))];
                        }
                        if (statusCode === HttpStatusCode.Gone || statusCode === HttpStatusCode.PreconditionFailed) {
                            window.dispatchEvent(new StorageEvent('storage', { key: CHALLENGE_STATUS, newValue: statusCode.toString() }));
                            return [2 /*return*/, dispatch(fetchReset({ key: this.key }))];
                        }
                        if (statusCode === HttpStatusCode.BadGateway || statusCode === HttpStatusCode.GatewayTimeout) {
                            error = busyError;
                        }
                        if (statusCode === HttpStatusCode.InternalServerError) {
                            error = internalError;
                        }
                        if (!(this.responseType === 'blob')) return [3 /*break*/, 8];
                        _k.label = 5;
                    case 5:
                        _k.trys.push([5, 7, , 8]);
                        _d = (_c = JSON).parse;
                        return [4 /*yield*/, ((_j = (_h = e_1.response) === null || _h === void 0 ? void 0 : _h.data) === null || _j === void 0 ? void 0 : _j.text())];
                    case 6:
                        error = _d.apply(_c, [_k.sent()]);
                        return [3 /*break*/, 8];
                    case 7:
                        __1 = _k.sent();
                        return [3 /*break*/, 8];
                    case 8:
                        dispatch(fetchFailed({ key: this.key, error: error, statusCode: statusCode }));
                        return [3 /*break*/, 10];
                    case 9:
                        console.error(e_1);
                        dispatch(fetchFailed({ key: this.key, error: internalError }));
                        _k.label = 10;
                    case 10: return [3 /*break*/, 11];
                    case 11: return [2 /*return*/];
                }
            });
        }); };
    };
    return APIClient;
}());
export var useAPI = function (url, substitutions, method, _a) {
    if (substitutions === void 0) { substitutions = {}; }
    if (method === void 0) { method = 'GET'; }
    var _b = _a === void 0 ? {} : _a, _c = _b.responseType, responseType = _c === void 0 ? 'json' : _c, _d = _b.key, key = _d === void 0 ? url : _d, contentType = _b.contentType;
    var dispatch = useAppDispatch();
    var substitutedUrl = substituteParams(url, substitutions);
    var substitutedKey = substituteParams(key, substitutions);
    var authStrategy = useAuthStrategy();
    var client = useMemo(function () { return new APIClient(authStrategy, substitutedUrl, substitutedKey, method, responseType, contentType); }, [authStrategy, substitutedUrl, substitutedKey, method, responseType, contentType]);
    var doRequest = useCallback(function (data, params) { return dispatch(client.request({ data: data, params: params })); }, [client, dispatch]);
    var dataKey = client.getKey();
    var dataSelector = useMemo(function () { return createDataSelector(dataKey); }, [dataKey]);
    var data = useAppSelector(function (state) { return dataSelector(state); });
    var resetStateAPI = useCallback(function () { return dispatch(client.resetState()); }, [client, dispatch]);
    return [data, doRequest, resetStateAPI];
};
export var useCachedAPI = function (url, substitutions, method, options) {
    if (substitutions === void 0) { substitutions = {}; }
    if (method === void 0) { method = 'GET'; }
    if (options === void 0) { options = {}; }
    var _a = useAPI(url, substitutions, method, options), data = _a[0], fetchData = _a[1], clearData = _a[2];
    var fetchDataWrapper = useCallback(function (force) {
        if (force || data.requesting === undefined) {
            fetchData();
        }
    }, [data.requesting, fetchData]);
    return [data, fetchDataWrapper, clearData];
};
