import { createSelector } from '@reduxjs/toolkit';
import _ from 'lodash';
import { useAPI } from '../../state';
import { TaskStatus } from '../hooks';
var GET_USER_TASK_API = '/api/report/process';
var UPDATE_IS_NOTIFIED_API = '/api/report/process/{id}';
var selectToastMessages = function (messages) { return messages; };
var selectSimulationInProgress = createSelector(selectToastMessages, function (messages) {
    return _.chain(messages).filter({ status: TaskStatus.Processing }).reverse().groupBy('details.basePlanId').value();
});
var selectCompletedSimulations = createSelector(selectToastMessages, function (messages) {
    return _.chain(messages)
        .filter(function (_a) {
        var status = _a.status;
        return status !== TaskStatus.Processing;
    })
        .keyBy('details.basePlanId')
        .value();
});
export var useToastMessagesAPI = function () {
    return useAPI(GET_USER_TASK_API);
};
export var useUpdateStatusAPI = function (id) {
    return useAPI(UPDATE_IS_NOTIFIED_API, { id: id }, 'PUT');
};
export var useSimulatingInProgress = function (basePlanId) {
    if (basePlanId === void 0) { basePlanId = ''; }
    var data = useToastMessagesAPI()[0].data;
    return selectSimulationInProgress(data)[basePlanId];
};
export var useIsCompletedSimulation = function (basePlanId) {
    if (basePlanId === void 0) { basePlanId = ''; }
    var data = useToastMessagesAPI()[0].data;
    return !!selectCompletedSimulations(data)[basePlanId];
};
