var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Autocomplete as RawAutocomplete, TextField, } from '@mui/material';
import { useMemo, useRef } from 'react';
export var Autocomplete = function (_a) {
    var _b = _a.options, options = _b === void 0 ? [] : _b, _c = _a.getOptionValue, getOptionValue = _c === void 0 ? function (o) { var _a; return (_a = o.id) !== null && _a !== void 0 ? _a : o; } : _c, multiple = _a.multiple, error = _a.error, inputRef = _a.inputRef, helperText = _a.helperText, placeholder = _a.placeholder, props = __rest(_a, ["options", "getOptionValue", "multiple", "error", "inputRef", "helperText", "placeholder"]);
    var getOptionValueRef = useRef(getOptionValue);
    var selectedValue = useMemo(function () {
        var getSelectOption = function (v) { var _a; return (_a = options.find(function (o) { return getOptionValueRef.current(o) === v; })) !== null && _a !== void 0 ? _a : null; };
        return multiple ? props.value.map(getSelectOption) : getSelectOption(props.value);
    }, [multiple, options, props.value]);
    var handleChange = function (event, newValue) {
        var _a;
        newValue = newValue && multiple ? newValue.map(getOptionValue) : getOptionValue(newValue);
        (_a = props.onChange) === null || _a === void 0 ? void 0 : _a.call(props, newValue);
    };
    return (_jsx(RawAutocomplete, __assign({ fullWidth: true, disableClearable: true, getOptionLabel: function (o) { var _a; return (_a = o.name) !== null && _a !== void 0 ? _a : o.toString(); }, "data-testid": "autocomplete" }, props, { multiple: multiple, value: selectedValue, onChange: handleChange, options: options, renderInput: function (params) { return (_jsx(TextField, __assign({ error: error, helperText: helperText, placeholder: placeholder }, params))); }, ref: inputRef })));
};
