var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, Stack, Typography, styled } from '@mui/material';
import _ from 'lodash';
import { useContext, useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { StatusPermission } from '../../../enum';
import { useUserPermissionsAPI } from '../../app/selectors';
import { ConfirmDialog, ErrorMessageBox, FallbackComponent, FormErrorText, Tab, Tabs } from '../../common';
import { CopyDataFrom } from '../common/copyDataFrom';
import { GENERAL_MONTH } from '../constants';
import { WizardContext } from '../context';
import { useRefreshQueueData } from '../queue/selectors';
import { QueueStatusType } from '../queue/types';
import { usePlanAPI } from '../selectors';
import { extractMonthFromKey, useClearDailyVolumeAPI, useDailyVolumeCopyAPI, useExceptionTitle, useWAVMonths, } from './selectors';
import { DailyVolumeTable } from './volumeTable';
var StyledTab = styled(Tab)(function (_a) {
    var theme = _a.theme;
    return ({
        textTransform: 'uppercase',
        '&.Mui-selected': {
            backgroundColor: theme.palette.text.primary,
            color: theme.palette.common.white,
        },
    });
});
var VolumeTabLabel = function (_a) {
    var month = _a.month;
    var t = useTranslation('wizard', { keyPrefix: 'wapVolume' }).t;
    var _b = useExceptionTitle(month), inline = _b[1];
    return _jsx(_Fragment, { children: inline !== null && inline !== void 0 ? inline : t('name') });
};
var ModifyOperatingHours = function () {
    var _a;
    var planData = usePlanAPI()[0];
    var t = useTranslation('wizard', { keyPrefix: 'wapVolume.modifyOperatingHours' }).t;
    var _b = useState(false), open = _b[0], setOpen = _b[1];
    var _c = useClearDailyVolumeAPI(), clearData = _c[1];
    var _d = useContext(WizardContext), handleSave = _d.handleSave, isDirty = _d.methods.formState.isDirty;
    var handleConfirm = function () {
        if (isDirty) {
            setOpen(false);
            return handleSave('submit', function () { return clearData(undefined, { modifyOperatingHours: true }); })();
        }
        clearData(undefined, { modifyOperatingHours: true });
    };
    return (_jsxs(_Fragment, { children: [_jsx(Button, __assign({ variant: "outlined", disabled: !((_a = planData.data) === null || _a === void 0 ? void 0 : _a.isBaseline), onClick: function () { return setOpen(true); } }, { children: t('label') })), _jsx(ConfirmDialog, { open: open, onClose: function () { return setOpen(false); }, confirmButtonText: "continue", message: _jsxs(_Fragment, { children: [_jsx(Typography, __assign({ gutterBottom: true }, { children: t('description') })), _jsx(Typography, __assign({ mt: 4, variant: "subtitle2", color: "" }, { children: t('confirm') }))] }), onConfirm: handleConfirm })] }));
};
export var DailyWorkVolumeContent = function (_a) {
    var rightContent = _a.rightContent;
    var t = useTranslation('wizard', { keyPrefix: 'wapVolume' }).t;
    var permission = useUserPermissionsAPI();
    var months = useWAVMonths();
    var _b = useState(GENERAL_MONTH), month = _b[0], setMonth = _b[1];
    var _c = useState(), firstError = _c[0], setFirstError = _c[1];
    var firstFieldErrorKey = useRef();
    var _d = useFormContext(), setFocus = _d.setFocus, errors = _d.formState.errors;
    useEffect(function () {
        setMonth(function (curr) { var _a; return (months.includes(curr) ? curr : (_a = months[0]) !== null && _a !== void 0 ? _a : GENERAL_MONTH); });
    }, [months]);
    useEffect(function () {
        var iterate = function (obj, stack) {
            if (stack === void 0) { stack = []; }
            return _.reduce(obj, function (key, value, property) {
                if (key.length || !value)
                    return key;
                if (typeof value == 'object') {
                    return iterate(value, __spreadArray(__spreadArray([], stack, true), ["".concat(property)], false));
                }
                return __spreadArray([value], stack, true);
            }, []);
        };
        var _a = iterate(errors.wapVolumes), error = _a[0], monthKey = _a[1], errorKeys = _a.slice(2);
        if (monthKey) {
            var visibleMonth = extractMonthFromKey(monthKey);
            setMonth(visibleMonth);
            firstFieldErrorKey.current = errorKeys.join('.');
            setFirstError([visibleMonth, error]);
        }
        else {
            setFirstError(undefined);
        }
    }, [errors]);
    useEffect(function () {
        if (firstFieldErrorKey.current) {
            setFocus("wapVolumes.month".concat(month, ".").concat(firstFieldErrorKey.current));
            firstFieldErrorKey.current = undefined;
        }
    }, [month, setFocus]);
    if (!months.length || !months.includes(month))
        return null;
    return (_jsxs(_Fragment, { children: [_jsx(Typography, __assign({ variant: "subtitle2" }, { children: t('title') })), _jsxs(Typography, { children: [t('description'), _jsx(Typography, __assign({ variant: "subtitle2", component: "span" }, { children: t('highlight') }))] }), _jsxs(Box, { children: [_jsxs(Stack, __assign({ direction: "row", alignItems: "flex-end" }, { children: [_jsx(Tabs, __assign({ value: month, onChange: function (e, v) { return setMonth(v); }, variant: "scrollable", scrollButtons: "auto" }, { children: months.map(function (m) { return (_jsx(StyledTab, { value: m, label: _jsx(VolumeTabLabel, { month: m }) }, m)); }) })), rightContent] })), _jsx(DailyVolumeTable, { month: month, disabled: (permission === null || permission === void 0 ? void 0 : permission.capacityPermission) !== StatusPermission.Edit }), (firstError === null || firstError === void 0 ? void 0 : firstError[0]) === month && _jsx(FormErrorText, { message: firstError[1] })] })] }));
};
export var DailyWorkVolumeContainer = function () {
    var months = useWAVMonths();
    var _a = useClearDailyVolumeAPI(), clearedData = _a[0], clearData = _a[1], cleanClearedData = _a[2];
    var _b = useDailyVolumeCopyAPI(), copiedData = _b[0], copyData = _b[1], clearCopiedData = _b[2];
    var refreshData = useRefreshQueueData();
    var _c = useContext(WizardContext), handleSave = _c.handleSave, requesting = _c.requesting, isDirty = _c.methods.formState.isDirty;
    useEffect(function () {
        if (clearedData.data || copiedData.data) {
            refreshData();
        }
        return function () {
            cleanClearedData();
            clearCopiedData();
        };
    }, [cleanClearedData, clearCopiedData, clearedData.data, copiedData.data, refreshData]);
    var handleCopy = function (source) {
        if (isDirty) {
            return handleSave('submit', function () { return copyData(undefined, { source: source }); })();
        }
        copyData(undefined, { source: source });
    };
    var handleClear = function () {
        if (isDirty) {
            return handleSave('submit', function () { return clearData(); })();
        }
        clearData();
    };
    if (!months.length)
        return null;
    return (_jsxs(Box, __assign({ position: "relative" }, { children: [_jsx(ErrorMessageBox, { errors: [clearedData.error, copiedData.error] }), _jsx(FallbackComponent, __assign({ requesting: !!requesting || !!clearedData.requesting || !!copiedData.requesting, overlay: true }, { children: _jsxs(Stack, __assign({ component: "form", mt: 5, spacing: 4, onSubmit: handleSave('submit') }, { children: [_jsx(DailyWorkVolumeContent, { rightContent: _jsx(CopyDataFrom, { statusType: QueueStatusType.WAV, onClear: handleClear, onCopy: handleCopy, mb: 3 }) }), _jsx(Stack, __assign({ direction: "row", spacing: 4, justifyContent: "flex-end" }, { children: _jsx(ModifyOperatingHours, {}) })), _jsx("button", { type: "submit", hidden: true, disabled: requesting })] })) }))] })));
};
