var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Link, Stack, TextField, Typography, } from '@mui/material';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PatternFormat } from 'react-number-format';
import * as yup from 'yup';
import { ErrorMessageBox, FallbackComponent, FormField } from '../common';
import { useResendCode, useVerifyPhone } from './selectors';
export var VerifyPhoneNumber = function (_a) {
    var open = _a.open, onClose = _a.onClose, onSuccess = _a.onSuccess, phone = _a.phone;
    var t = useTranslation('translation').t;
    var _b = useResendCode(phone), resendCode = _b[0], fetchResendCode = _b[1], clearResendCode = _b[2];
    var _c = useVerifyPhone(), verifyUserAttribute = _c[0], fetchVerifyUserAttribute = _c[1], clearVerifyUserAttribute = _c[2];
    var _d = useForm({
        defaultValues: {},
        resolver: yupResolver(yup.object().shape({
            code: yup.string().required().length(6),
        })),
    }), control = _d.control, handleSubmit = _d.handleSubmit, reset = _d.reset;
    useEffect(function () {
        if (open) {
            reset();
            return function () {
                clearResendCode();
                clearVerifyUserAttribute();
            };
        }
    }, [clearResendCode, clearVerifyUserAttribute, open, reset]);
    useEffect(function () {
        if (verifyUserAttribute.data) {
            onClose();
            onSuccess();
        }
    }, [onClose, onSuccess, verifyUserAttribute.data]);
    var handleFormSubmit = handleSubmit(function (values) {
        fetchVerifyUserAttribute(values);
    });
    return (_jsxs(Dialog, __assign({ maxWidth: "xs", open: open, onClose: onClose, component: "form", onSubmit: handleFormSubmit }, { children: [_jsx(DialogTitle, __assign({ variant: "h2" }, { children: t('mfa.form.phoneNumberVerification') })), _jsx(DialogContent, { children: _jsxs(FallbackComponent, __assign({ requesting: !!resendCode.requesting || !!verifyUserAttribute.requesting, overlay: true }, { children: [_jsx(ErrorMessageBox, { errors: [resendCode.error, verifyUserAttribute.error] }), _jsx(Typography, __assign({ variant: "body2" }, { children: t('mfa.form.smsMessage') })), _jsxs(Stack, __assign({ gap: 2, alignItems: "start" }, { children: [_jsx(FormField, __assign({ required: true, name: "code", control: control, width: "100%" }, { children: _jsx(PatternFormat, { customInput: TextField, format: "######" }) })), _jsx(Link, __assign({ component: "button", type: "button", onClick: function () { return fetchResendCode(); } }, { children: t('mfa.form.resend') }))] }))] })) }), _jsxs(DialogActions, { children: [_jsx(Button, __assign({ variant: "outlined", onClick: onClose }, { children: t('action.cancel') })), _jsx(Button, __assign({ variant: "contained", type: "submit" }, { children: t('action.ok') }))] })] })));
};
