var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { createSelector } from '@reduxjs/toolkit';
import _ from 'lodash';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Currency, DifferenceMode, PlanType } from '../../enum';
import { useAPI } from '../../state';
import { traceBarConfig, traceStackConfig } from '../common/chart/config';
import { QueueType } from '../multiskill/types';
import { DisplayMode } from '../report/resourcing';
import { useDisplayModeParams, useReportAPI } from '../report/selectors';
import { MONTH_OF_YEAR, selectPlanType } from '../wizard/selectors';
import { ConstraintType, } from './types';
import { formatNumberWithPrefix, generateQueueRoleLegend, handleLayout, handleTemplate, replaceNameInTemplate, } from './util';
var SUMMARY_API = '/api/report/summary?basePlanId={basePlanId}';
var MULTISKILL_API = '/api/multiskill/{planId}';
var selectScenarios = function (plan) { return plan === null || plan === void 0 ? void 0 : plan.scenarios; };
var selectCurrency = function (plan) { var _a; return (_a = plan === null || plan === void 0 ? void 0 : plan.currency) !== null && _a !== void 0 ? _a : Currency.Dollar; };
var selectScenarioMap = createSelector(selectScenarios, function (scenario) {
    return _.keyBy(scenario, function (item) { return (item.isBaseline ? 'Baseline' : item.name); });
});
var createScenarioSelector = function (name) {
    return createSelector(selectScenarioMap, function (map) { return map[name]; });
};
var selectSummaryReport = function (dataReport) { return dataReport; };
var selectSummaryReportCooked = createSelector(selectSummaryReport, function (dataReport) {
    return dataReport === null || dataReport === void 0 ? void 0 : dataReport.map(function (i) { return (__assign(__assign({}, i), { hasMultiskill: i.multiSkill.some(function (q) { return q.group; }) })); });
});
var selectSummaryGroupByType = createSelector(selectSummaryReportCooked, function (dataReport) {
    return _.chain(dataReport)
        .map(function (i) { return (__assign(__assign({}, i), { multiSkill: i.dataType === QueueType.SingleSkill ? [] : i.multiSkill })); })
        .groupBy('dataType')
        .value();
});
var selectMultiSkillScenarios = createSelector(selectSummaryReport, function (dataReport) {
    return _.filter(dataReport, { hasMultiskill: true, dataType: QueueType.SingleSkill, year: 0 });
});
var createSelectorSummaryReportByType = function (dataType) {
    return createSelector(selectSummaryReportCooked, selectSummaryGroupByType, function (summaryReports, summaryReportGrouped) {
        return dataType ? summaryReportGrouped[dataType] : summaryReports;
    });
};
var selectSummaryChunkSize = createSelector(selectSummaryReport, function (summaryReports) {
    return summaryReports ? _.filter(summaryReports, { year: 0 }).length : 1;
});
var selectQueuesByName = function (queueName, chunkSize, queueType) {
    return createSelector(selectSummaryReport, function (summaryReports) {
        return _.chain(summaryReports)
            .flatMap(function (_a) {
            var defQueue = _a.defQueue, nonDefQueue = _a.nonDefQueue, multiSkill = _a.multiSkill;
            return __spreadArray(__spreadArray(__spreadArray([], defQueue, true), nonDefQueue, true), multiSkill, true);
        })
            .filter({ name: queueName, queueType: queueType })
            .chunk(chunkSize)
            .value();
    });
};
var selectRoleByKey = function (keyItem, chunkSize) {
    return createSelector(selectSummaryReport, function (summaryReports) {
        return _.chain(summaryReports)
            .flatMap(function (report) { return report[keyItem]; })
            .groupBy('role')
            .map(function (values, role) { return ({ role: role, value: _.chunk(_.map(values, 'value'), chunkSize) }); })
            .value();
    });
};
var selectQueuesByCost = createSelector(selectSummaryReport, function (summaryReports) {
    return _.chain(summaryReports)
        .flatMap(function (_a) {
        var defQueue = _a.defQueue, nonDefQueue = _a.nonDefQueue;
        return __spreadArray(__spreadArray([], defQueue, true), nonDefQueue, true);
    })
        .sortBy('order')
        .groupBy('name')
        .value();
});
export var generateVariances = function (baseValue, currentValue, differenceMode) {
    if (differenceMode === void 0) { differenceMode = DifferenceMode.Absolute; }
    var diffValue = currentValue - baseValue;
    var percentageUnit = '';
    var decimal = 0;
    if (!diffValue)
        return undefined;
    if (differenceMode === DifferenceMode.Percentage) {
        if (!baseValue)
            return 'X';
        diffValue = _.round((diffValue * 100) / baseValue, 1);
        percentageUnit = '%';
        decimal = 1;
    }
    if (diffValue > 0) {
        return diffValue > 999 ? ">+1k".concat(percentageUnit) : "+".concat(diffValue.toFixed(decimal)).concat(percentageUnit);
    }
    return diffValue < -999 ? "<-1k".concat(percentageUnit) : "".concat(diffValue.toFixed(decimal)).concat(percentageUnit);
};
export var useSummaryReportAPI = function () {
    var basePlanId = useParams().basePlanId;
    return useAPI(SUMMARY_API, { basePlanId: basePlanId });
};
export var useSummaryByDisplayMode = function () {
    var data = useSummaryReportAPI()[0].data;
    var displayMode = useDisplayModeParams()[0];
    var dataType = displayMode === DisplayMode.All ? undefined : displayMode;
    var selectSummaryReportByType = useMemo(function () { return createSelectorSummaryReportByType(dataType); }, [dataType]);
    return selectSummaryReportByType(data);
};
export var useMultiskillScenariosAPI = function () {
    var data = useSummaryByDisplayMode();
    return selectMultiSkillScenarios(data);
};
export var useSummaryChunkSize = function () {
    var data = useSummaryByDisplayMode();
    return selectSummaryChunkSize(data);
};
export var useSelectScenario = function (name) {
    if (name === void 0) { name = 'Baseline'; }
    var data = useReportAPI()[0].data;
    var selectScenario = useMemo(function () { return createScenarioSelector(name); }, [name]);
    return selectScenario(data);
};
export var useSelectQueuesByName = function (queueName, constraintType) {
    var data = useSummaryByDisplayMode();
    var chunkSize = useSummaryChunkSize();
    var selectQueues = useMemo(function () {
        return selectQueuesByName(queueName, chunkSize, constraintType === ConstraintType.Multiskill ? QueueType.Multiskill : QueueType.SingleSkill);
    }, [queueName, chunkSize, constraintType]);
    return selectQueues(data);
};
export var useSelectRoles = function (keyItem) {
    var data = useSummaryByDisplayMode();
    var chunkSize = useSummaryChunkSize();
    var selectRoles = useMemo(function () { return selectRoleByKey(keyItem, chunkSize); }, [keyItem, chunkSize]);
    return selectRoles(data);
};
export var useSelectQueuesByCost = function () {
    var data = useSummaryByDisplayMode();
    return selectQueuesByCost(data);
};
export var useSelectCurrency = function () {
    var data = useReportAPI()[0].data;
    return selectCurrency(data);
};
var transformDataArrayForChart = function (items, options) {
    var _a;
    return _.times((_a = items[0]) === null || _a === void 0 ? void 0 : _a.data.length, function (index) {
        var y = items.map(function (t) { return t.data[index]; });
        var text = y.map(function (num) { return (options.isFormatNumber ? formatNumberWithPrefix(num) : String(num)); });
        return {
            y: y,
            x: _.range(items.length),
            text: text,
            name: items[0].name[index],
            hovertext: items.map(function (t) { return t.xLabel; }),
        };
    });
};
var transformDataForChart = function (items, options) {
    var y = _.map(items, 'data');
    var text = y.map(function (num) { return (options.isFormatNumber ? formatNumberWithPrefix(num) : String(num)); });
    return [
        {
            y: y,
            x: _.range(items.length),
            text: text,
            hovertext: items.map(function (t) { return t.xLabel; }),
        },
    ];
};
export var useChartPerRow = function () {
    var _a, _b;
    var data = useReportAPI()[0].data;
    var planType = selectPlanType(data);
    var periodLength = (_a = data === null || data === void 0 ? void 0 : data.periodLength) !== null && _a !== void 0 ? _a : 1;
    var currency = (_b = data === null || data === void 0 ? void 0 : data.currency) !== null && _b !== void 0 ? _b : '';
    var year = planType === PlanType.Weekly ? 1 : periodLength / MONTH_OF_YEAR;
    var chartPerRow = year < 3 ? 2 : 1;
    var costPerWorkActivityRow = year < 3 ? 1 : year;
    return { chartPerRow: chartPerRow, costPerWorkActivityRow: costPerWorkActivityRow, year: year, scenarios: data === null || data === void 0 ? void 0 : data.scenarios, currency: currency };
};
export var useChartData = function (extractDataFn, options, isTransformArray) {
    if (isTransformArray === void 0) { isTransformArray = false; }
    var data = useSummaryByDisplayMode();
    var _a = useChartPerRow(), year = _a.year, _b = _a.scenarios, scenarios = _b === void 0 ? [] : _b;
    var colorsByIndex = handleLayout(scenarios).colorsByIndex;
    var _c = handleTemplate(options), texttemplate = _c.texttemplate, hovertemplate = _c.hovertemplate;
    var traceConfig = _.cloneDeep(traceStackConfig);
    if (!isTransformArray) {
        var markerColor = _.flatMap(colorsByIndex, function (color) { return _.times(year, function () { return color; }); });
        _.set(traceConfig, 'marker.color', markerColor);
    }
    var chartData = _.chain(data)
        .groupBy(function (item) { return "".concat(item.name, ".").concat(item.year); })
        .toPairs()
        .flatMap(function (_a) {
        var values = _a[1];
        var extData = extractDataFn(values);
        var objData = Array.isArray(extData) || typeof extData === 'number' ? { data: extData } : extData;
        return __assign({ year: values[0].year, xLabel: year > 1 ? "".concat(values[0].name, " - Year ").concat(values[0].year + 1) : values[0].name, order: "".concat(values[0].order, ".").concat(values[0].year) }, objData);
    })
        .sortBy('order')
        .thru(function (items) {
        return isTransformArray ? transformDataArrayForChart(items, options) : transformDataForChart(items, options);
    })
        .map(function (item) { return (__assign(__assign(__assign({}, item), traceConfig), { texttemplate: texttemplate, hovertemplate: hovertemplate })); })
        .value();
    return replaceNameInTemplate(chartData);
};
export var useTotalRequiredAgent = function () {
    var _a = useChartPerRow(), _b = _a.scenarios, scenarios = _b === void 0 ? [] : _b, year = _a.year;
    var legends = handleLayout(scenarios).legends;
    var extractDataFn = function (values) {
        return _.chain(values)
            .flatMap(function (_a) {
            var defQueue = _a.defQueue, nonDefQueue = _a.nonDefQueue;
            return __spreadArray(__spreadArray([], defQueue, true), nonDefQueue, true);
        })
            .flatMap('requiredAgents')
            .sum()
            .value();
    };
    var options = {
        isSimple: true,
    };
    var data = useChartData(extractDataFn, options);
    return { data: data, key: 'totalRequiredAgent', middleLegends: year > 1 ? legends : [] };
};
export var useAgentByWorkType = function () {
    var _a = useChartPerRow(), _b = _a.scenarios, scenarios = _b === void 0 ? [] : _b, year = _a.year;
    var middleLegends = handleLayout(scenarios).legends;
    var getDataAndQueue = function (values) {
        var data = _.flatMap(values, function (_a) {
            var defQueue = _a.defQueue, nonDefQueue = _a.nonDefQueue;
            return _.flatMap(__spreadArray(__spreadArray([], defQueue, true), nonDefQueue, true), 'requiredAgents');
        });
        var name = _.flatMap(values, function (_a) {
            var defQueue = _a.defQueue, nonDefQueue = _a.nonDefQueue;
            return _.flatMap(__spreadArray(__spreadArray([], defQueue, true), nonDefQueue, true), 'name');
        });
        return { data: data, name: name };
    };
    var data = useChartData(getDataAndQueue, {}, true);
    var queue = _.flatMap(data, 'name');
    var _c = generateQueueRoleLegend(queue), legends = _c.legends, colorsway = _c.colorsway;
    return { data: data, key: 'agentsByWorkType', legends: legends, middleLegends: year > 1 ? middleLegends : [], colorsway: colorsway };
};
export var useSupportStaffRequirement = function () {
    var _a = useChartPerRow(), _b = _a.scenarios, scenarios = _b === void 0 ? [] : _b, year = _a.year;
    var legends = handleLayout(scenarios).legends;
    var extractDataFn = function (values) {
        return _.chain(values)
            .flatMap(function (_a) {
            var supportStaff = _a.supportStaff;
            return supportStaff;
        })
            .flatMap('value')
            .sum()
            .value();
    };
    var options = {
        isSimple: true,
    };
    var data = useChartData(extractDataFn, options);
    return { data: data, key: 'supportStaffRequirement', middleLegends: year > 1 ? legends : [] };
};
export var useBudgetSummary = function () {
    var _a = useChartPerRow(), _b = _a.scenarios, scenarios = _b === void 0 ? [] : _b, currency = _a.currency, year = _a.year;
    var legends = handleLayout(scenarios).legends;
    var extractDataFn = function (values) {
        return _.chain(values[0])
            .pick(['agentsSalaries', 'supportStaffSalaries', 'hiringAhead', 'overtimeCosts'])
            .flatMap()
            .sum()
            .value();
    };
    var options = {
        isSimple: true,
        isFormatNumber: true,
        currency: currency,
    };
    var data = useChartData(extractDataFn, options);
    return { data: data, key: 'budgetSummary', middleLegends: year > 1 ? legends : [], yTickPrefix: currency };
};
export var useSupportStaffByRole = function () {
    var _a = useChartPerRow(), _b = _a.scenarios, scenarios = _b === void 0 ? [] : _b, year = _a.year;
    var middleLegends = handleLayout(scenarios).legends;
    var getDataAndRole = function (values) {
        var extractValues = function (staff) { return _.flatMap(staff, 'value'); };
        var extractRoles = function (staff) { return _.flatMap(staff, 'role'); };
        var data = _.flatMap(values, function (_a) {
            var supportStaff = _a.supportStaff;
            return extractValues(supportStaff);
        });
        var name = _.flatMap(values, function (_a) {
            var supportStaff = _a.supportStaff;
            return extractRoles(supportStaff);
        });
        return { data: data, name: name };
    };
    var data = useChartData(getDataAndRole, {}, true);
    var roles = _.flatMap(data, 'name');
    var _c = generateQueueRoleLegend(roles), legends = _c.legends, colorsway = _c.colorsway;
    return { data: data, key: 'supportStaffByRole', legends: legends, middleLegends: year > 1 ? middleLegends : [], colorsway: colorsway };
};
export var useResourceBudgetDetail = function () {
    var _a = useChartPerRow(), _b = _a.scenarios, scenarios = _b === void 0 ? [] : _b, year = _a.year;
    var middleLegends = handleLayout(scenarios).legends;
    var currency = useChartPerRow().currency;
    var name = ['Agents salaries', 'Support staff salaries', 'Hiring ahead of attrition costs', 'OT costs (agents)'];
    var getDataAndBudget = function (values) {
        var data = _.chain(values[0])
            .pick(['agentsSalaries', 'supportStaffSalaries', 'hiringAhead', 'overtimeCosts'])
            .flatMap()
            .value();
        return { data: data, name: name };
    };
    var options = {
        isSimple: false,
        isFormatNumber: true,
        currency: currency,
    };
    var legends = generateQueueRoleLegend(name).legends;
    var data = useChartData(getDataAndBudget, options, true);
    return {
        data: data,
        key: 'resourceBudgetDetail',
        legends: legends,
        yTickPrefix: currency,
        middleLegends: year > 1 ? middleLegends : [],
    };
};
export var useResourcingCharts = function () {
    var totalRequiredAgent = useTotalRequiredAgent();
    var agentByWorkType = useAgentByWorkType();
    var supportStaffRequirement = useSupportStaffRequirement();
    var supportStaffByRole = useSupportStaffByRole();
    var optionalCharts = [supportStaffRequirement, supportStaffByRole].filter(function (_a) {
        var data = _a.data;
        return _.sumBy(data, function (item) { return _.sum(item.y); });
    });
    return __spreadArray([totalRequiredAgent, agentByWorkType], optionalCharts, true);
};
export var useFinancialCharts = function () {
    var budgetSummary = useBudgetSummary();
    var resourceBudgetDetail = useResourceBudgetDetail();
    return [budgetSummary, resourceBudgetDetail].filter(function (_a) {
        var data = _a.data;
        return _.sumBy(data, function (item) { return _.sum(item.y); });
    });
};
export var useCostPerWorkActivity = function () {
    var reportData = useSummaryByDisplayMode();
    var currency = useChartPerRow().currency;
    var _a = handleTemplate({ currency: currency, isCostPerWork: true }), hovertemplate = _a.hovertemplate, texttemplate = _a.texttemplate;
    return _.chain(reportData)
        .groupBy('year')
        .toPairs()
        .flatMap(function (_a) {
        var year = _a[0], values = _a[1];
        return _.flatMap(values, function (_a) {
            var defQueue = _a.defQueue, nonDefQueue = _a.nonDefQueue, name = _a.name;
            var queue = _.chain(__spreadArray(__spreadArray([], defQueue, true), nonDefQueue, true)).sortBy('order');
            return {
                data: queue.flatMap('costPerWork').value(),
                queue: queue.flatMap('name').value(),
                year: year,
                name: name,
            };
        });
    })
        .groupBy('year')
        .thru(function (items) {
        return _.chain(items)
            .map(function (subItem) {
            return subItem.map(function (_a) {
                var data = _a.data, queue = _a.queue, name = _a.name;
                return (__assign(__assign({}, traceBarConfig), { y: data, x: queue, text: data, hovertext: queue, name: name, hovertemplate: hovertemplate.replace('value', name), texttemplate: texttemplate }));
            });
        })
            .filter(function (data) { return _.sumBy(data, function (item) { return _.sum(item.y); }) !== 0; })
            .value();
    })
        .value();
};
export var useDeleteMultiskill = function (planId) {
    return useAPI(MULTISKILL_API, { planId: planId }, 'DELETE');
};
export var useExistMultiskillReport = function () {
    var _a;
    var data = useSummaryReportAPI()[0].data;
    var grouped = selectSummaryGroupByType(data);
    return !!((_a = grouped[QueueType.Multiskill]) === null || _a === void 0 ? void 0 : _a.length);
};
