var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { styled } from '@mui/material';
import _ from 'lodash';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ToastContainer, Zoom, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TaskStatus } from '../hooks';
import { useToastMessagesAPI, useUpdateStatusAPI } from './selector';
var LIMIT = 5;
var DELAY = 1500;
var AUTO_HIDE_DURATION = LIMIT * DELAY;
var INTERVAL_CHECK_ASYNC_PROCESS = 10000;
var StyledToastContainer = styled(ToastContainer)(function (_a) {
    var theme = _a.theme;
    return ({
        width: 'auto',
        '& .Toastify__toast-theme--colored.Toastify__toast--success': {
            backgroundColor: theme.palette.success.main,
        },
        '& .Toastify__toast-theme--colored.Toastify__toast--error': {
            backgroundColor: theme.palette.error.main,
        },
    });
});
var StyledLink = styled(Link)(function (_a) {
    var theme = _a.theme;
    return ({
        color: theme.palette.common.white,
        textDecorationColor: theme.palette.common.white,
        fontWeight: theme.typography.fontWeightBold,
        ':hover': {
            cursor: 'pointer',
            color: theme.palette.common.white,
        },
    });
});
export var ToastMessage = React.memo(function (props) {
    var t = useTranslation('translation', { keyPrefix: 'toastify' }).t;
    var _a = props.message, id = _a.id, status = _a.status, taskType = _a.taskType, details = _a.details, scenarioName = _a.scenarioName;
    var _b = useUpdateStatusAPI(id), fetchUpdateStatusData = _b[1];
    useEffect(function () {
        return function () {
            fetchUpdateStatusData({ isNotified: true });
        };
    }, [fetchUpdateStatusData, props.message]);
    return (_jsxs(_Fragment, { children: [t(status, {
                context: taskType,
                planName: details.planName,
                scenarioName: scenarioName,
                defaultValue: '',
            }), status === TaskStatus.Completed && (_jsx(StyledLink, __assign({ to: "/report/".concat(details.basePlanId, "/resourcing/summary") }, { children: t('viewResults') })))] }));
});
export var Toastify = function () {
    var _a = useToastMessagesAPI(), toastMessagesData = _a[0], fetchToastMessagesData = _a[1], clearToastMessagesData = _a[2];
    var toastStatusRef = useRef({});
    useEffect(function () {
        fetchToastMessagesData();
        return clearToastMessagesData;
    }, [fetchToastMessagesData, clearToastMessagesData]);
    useEffect(function () {
        if (_.some(toastMessagesData.data, { status: TaskStatus.Processing })) {
            var timeoutId_1 = setTimeout(fetchToastMessagesData, INTERVAL_CHECK_ASYNC_PROCESS);
            return function () {
                clearTimeout(timeoutId_1);
            };
        }
    }, [fetchToastMessagesData, toastMessagesData.data]);
    useEffect(function () {
        var _a;
        var toastStatus = {};
        (_a = toastMessagesData.data) === null || _a === void 0 ? void 0 : _a.filter(function (message) { return message.status !== TaskStatus.Processing; }).forEach(function (message, index) {
            toastStatus[message.id] = true;
            !toastStatusRef.current[message.id] &&
                setTimeout(function () {
                    toast(_jsx(ToastMessage, { message: message }), {
                        type: message.status === TaskStatus.Completed ? 'success' : 'error',
                        theme: 'colored',
                    });
                }, index * DELAY);
        });
        toastStatusRef.current = toastStatus;
    }, [toastMessagesData.data]);
    return (_jsx(StyledToastContainer, { hideProgressBar: true, autoClose: AUTO_HIDE_DURATION, transition: Zoom, limit: LIMIT, closeOnClick: true, position: "top-center" }));
};
