var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorMessageBox, FallbackComponent } from '../common';
import { useResourcingContext } from '../report/hooks';
import { useReportAPI } from '../report/selectors';
import { DailyProvider } from './context';
import { DailyForm } from './dailyForm';
import { DetailTable } from './detailTable';
import { useDailyAPI, useExportAPI, usePrintAPI, useWAPQueuesAPI } from './selectors';
import { SummaryTable } from './summaryTable';
export var DailyReportWrapper = function () {
    var _a;
    var t = useTranslation('report', { keyPrefix: 'daily' }).t;
    var setDescription = useResourcingContext().setDescription;
    var _b = useReportAPI(), reportData = _b[0], fetchReportData = _b[1];
    var queuesData = useWAPQueuesAPI()[0];
    var printData = usePrintAPI()[0];
    var exportData = useExportAPI()[0];
    var _c = useDailyAPI(), dailyData = _c[0], fetchDailyData = _c[1], clearDailyData = _c[2];
    useEffect(function () {
        setDescription(t('description'));
        return setDescription;
    }, [setDescription, t]);
    useEffect(function () {
        fetchReportData();
    }, [fetchReportData]);
    useEffect(function () {
        fetchDailyData();
        return clearDailyData;
    }, [clearDailyData, fetchDailyData]);
    return (_jsxs(_Fragment, { children: [_jsx(ErrorMessageBox, { errors: [queuesData.error, dailyData.error, printData.error, exportData.error] }), _jsx(DailyForm, {}), _jsxs(FallbackComponent, __assign({ requesting: !!reportData.requesting || !!queuesData.requesting || !!dailyData.requesting }, { children: [_jsx(DetailTable, {}), ((_a = dailyData.data) === null || _a === void 0 ? void 0 : _a.summary) && _jsx(SummaryTable, {})] }))] }));
};
export var DailyReport = function () {
    return (_jsx(DailyProvider, { children: _jsx(DailyReportWrapper, {}) }));
};
