var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createSelector } from '@reduxjs/toolkit';
import _ from 'lodash';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { PlanType } from '../../enum';
import { useAPI } from '../../state';
import { QueueType } from '../multiskill/types';
import { ActionType } from '../report/constants';
import { DisplayMode } from '../report/resourcing';
import { useDisplayModeParams, useExportAPI, useReportAPI, useReportPlanType } from '../report/selectors';
import { selectChunkSize, selectFullWeekly, selectFullWeeklyBr, selectMonthly, selectPlan, selectWeeks, } from '../wizard/selectors';
var PERIOD_API = '/api/report/period?basePlanId={basePlanId}';
var selectPeriodReport = function (dataReport) { return dataReport; };
var selectPeriodLength = createSelector(selectPlan, function (plan) { var _a; return (_a = plan === null || plan === void 0 ? void 0 : plan.periodLength) !== null && _a !== void 0 ? _a : 12; });
var selectTotalCol = createSelector(selectChunkSize, selectPeriodLength, function (chunkSize, periodLength) { return periodLength + (periodLength / chunkSize) * 2 + 1; });
var selectPeriodGroupByType = createSelector(selectPeriodReport, function (dataReport) {
    return _.chain(dataReport).groupBy('dataType').value();
});
var createSelectPeriodReportByMode = function (dataType) {
    return createSelector(selectPeriodReport, selectPeriodGroupByType, function (dataReport, peridReportGrouped) {
        return dataType ? peridReportGrouped[dataType] : dataReport;
    });
};
export var usePeriodReportChunkSize = function () {
    var data = useReportAPI()[0].data;
    return selectChunkSize(data);
};
export var useGetTotalCol = function () {
    var data = useReportAPI()[0].data;
    return selectTotalCol(data);
};
export var usePeriodReportAPI = function () {
    var basePlanId = useParams().basePlanId;
    return useAPI(PERIOD_API, { basePlanId: basePlanId });
};
export var usePeriodReportByMode = function () {
    var data = usePeriodReportAPI()[0].data;
    var displayMode = useDisplayModeParams()[0];
    var dataType = displayMode === DisplayMode.All ? undefined : displayMode;
    var selectPeriodReportByType = useMemo(function () { return createSelectPeriodReportByMode(dataType); }, [dataType]);
    return selectPeriodReportByType(data);
};
export var useExistMultiskillPeriodReport = function () {
    var _a;
    var data = usePeriodReportAPI()[0].data;
    var grouped = selectPeriodGroupByType(data);
    return !!((_a = grouped[QueueType.Multiskill]) === null || _a === void 0 ? void 0 : _a.length);
};
var createPeriodReportData = function (periodData, chartType) {
    return createSelector(selectWeeks, selectMonthly, selectFullWeeklyBr, selectFullWeekly, selectChunkSize, function (weeks, monthly, weeklyBr, fullWeekly, chunkSize) {
        return _.chain(periodData)
            .flatMap(function (item) {
            return _.toPairs(_.pick(item, chartType)).map(function (_a) {
                var _b;
                var key = _a[0], values = _a[1];
                return (_b = {
                        key: key,
                        name: item.displayName
                    },
                    _b[key] = _.chunk(values, chunkSize).map(function (vl, index) { return ({
                        year: index,
                        y: vl,
                        x: weeks !== null && weeks !== void 0 ? weeks : _.chunk(monthly, chunkSize)[index],
                        text: weeks ? fullWeekly : vl,
                        weekly: weeks ? weeklyBr : [],
                    }); }),
                    _b);
            });
        })
            .flatMap()
            .groupBy('key')
            .toPairs()
            .flatMap(function (_a) {
            var key = _a[0], values = _a[1];
            return _.chain(values)
                .groupBy('key')
                .map(function (groupedData) {
                return _.map(groupedData[0][key], function (agent) { return ({
                    key: key,
                    year: agent.year,
                    weekly: agent.weekly,
                    data: _.map(groupedData, function (scenario) { return (__assign({ name: scenario.name }, scenario[key][agent.year])); }),
                }); });
            })
                .flatten()
                .value();
        })
            .flatten()
            .filter(function (data) { return _.sumBy(data.data, function (item) { return _.sum(item.y); }) !== 0 || data.key === 'requiredAgents'; })
            .value();
    });
};
export var usePeriodGraphical = function (chartTypes) {
    var data = useReportAPI()[0].data;
    var reportData = usePeriodReportByMode();
    var selectPeriodData = useMemo(function () { return createPeriodReportData(reportData, chartTypes); }, [chartTypes, reportData]);
    return selectPeriodData(data);
};
export var usePrintPeriodTableAPI = function () {
    var planType = useReportPlanType();
    return useExportAPI(ActionType.Print, planType !== PlanType.Monthly);
};
