var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { createSelector } from '@reduxjs/toolkit';
import _ from 'lodash';
import { useMemo } from 'react';
import { stringToNumber } from '../common/numberInput/hooks';
import { WizardStep } from './constants';
import { useQueueAPI } from './queue/selectors';
import { usePlanAPI, useWizardParams } from './selectors';
import { OVERALL_TIME, getTimes, selectQueueDetail } from './workArrivalPattern/selectors';
var selectWAVMap = createSelector(selectQueueDetail, function (queue) {
    return _.keyBy(queue === null || queue === void 0 ? void 0 : queue.wapVolumes, function (v) { return "".concat(v.month, "-").concat(v.day, "-").concat(v.time); });
});
var selectDailyVolumeValues = createSelector(selectQueueDetail, selectWAVMap, function (queue, volume) {
    return _.chain(queue === null || queue === void 0 ? void 0 : queue.wapTimes)
        .map(function (_a) {
        var month = _a.month, day = _a.day, openTime = _a.openTime, closeTime = _a.closeTime;
        return getTimes(queue === null || queue === void 0 ? void 0 : queue.timeInterval, openTime, closeTime !== null && closeTime !== void 0 ? closeTime : undefined).map(function (time) {
            var _a, _b;
            return ({
                month: month,
                day: day,
                time: time,
                workVolume: stringToNumber((_b = (_a = volume["".concat(month, "-").concat(day, "-").concat(time)]) === null || _a === void 0 ? void 0 : _a.workVolume) !== null && _b !== void 0 ? _b : undefined),
            });
        });
    })
        .flatten()
        .groupBy(function (_a) {
        var month = _a.month;
        return "month".concat(month);
    })
        .mapValues(function (arr) {
        return _.chain(arr)
            .groupBy(function (i) { return (i.time === OVERALL_TIME ? OVERALL_TIME : "day".concat(i.day)); })
            .values()
            .value();
    })
        .value();
});
export var pickNumberValues = function (queue, pickFields) {
    var pickToNumber = [];
    for (var _i = 2; _i < arguments.length; _i++) {
        pickToNumber[_i - 2] = arguments[_i];
    }
    var result = {};
    pickToNumber.forEach(function (field) {
        var _a;
        result[field] = stringToNumber((_a = _.get(queue, field)) === null || _a === void 0 ? void 0 : _a.toString());
    });
    return __assign(__assign({}, _.pick.apply(_, __spreadArray([queue], pickFields, false))), result);
};
export var getDefaultVolumes = function (data) {
    var fields = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        fields[_i - 1] = arguments[_i];
    }
    return ({
        volumes: data === null || data === void 0 ? void 0 : data.volumes.map(function (volume) { return (__assign({}, pickNumberValues.apply(void 0, __spreadArray([volume, ['id']], fields, false)))); }),
    });
};
var selectVolumeValues = createSelector(selectQueueDetail, selectDailyVolumeValues, function (queue, wapVolumes) { return (__assign(__assign({}, pickNumberValues(queue, ['answered', 'threshold', 'factor', 'metricTarget', 'targetAsa'], 'maxOccupancy', 'targetOccupancy', 'chatConcurrency')), { wapVolumes: wapVolumes })); });
export var selectShrinkageValues = createSelector(selectQueueDetail, function (queue) { return ({
    shrinkages: _.chain(queue === null || queue === void 0 ? void 0 : queue.shrinkages)
        .groupBy('month')
        .mapValues(function (arr) { return arr.map(function (_a) {
        var id = _a.id, month = _a.month, name = _a.name, percent = _a.percent;
        return ({ id: id, month: month, name: name, percent: Number(percent) });
    }); })
        .values()
        .value(),
}); });
var selectWorkingDayValues = createSelector(selectQueueDetail, function (queue) {
    return pickNumberValues(queue, ['timeInterval', 'daysPerWeek'], 'fteDay', 'hoursPerDay', 'totalPartTime', 'dailyOperatingHours');
});
export var selectSupportStaffValues = createSelector(selectQueueDetail, function (queue) {
    var _a;
    return ({
        roles: (_a = queue === null || queue === void 0 ? void 0 : queue.roles) === null || _a === void 0 ? void 0 : _a.map(function (item) { return pickNumberValues(item, ['id', 'staffCalculate', 'role'], 'ratio', 'salary'); }),
    });
});
var createSelectorFinancialValues = function (currency) {
    return createSelector(selectQueueDetail, function (queue) { return (__assign(__assign(__assign({}, selectSupportStaffValues(queue)), pickNumberValues(queue, ['trainWeeks'], 'multiplier')), { currency: currency })); });
};
export var useDefaultValues = function () {
    var _a;
    var planData = usePlanAPI()[0];
    var queueData = useQueueAPI()[0];
    var step = useWizardParams().step;
    var selectFinancialValues = useMemo(function () { var _a; return createSelectorFinancialValues((_a = planData.data) === null || _a === void 0 ? void 0 : _a.currency); }, [(_a = planData.data) === null || _a === void 0 ? void 0 : _a.currency]);
    return useMemo(function () {
        var _a;
        var _b, _c;
        var defaultValue = (_a = {},
            _a[WizardStep.WorkVolume] = function () { return (__assign(__assign({}, selectVolumeValues(queueData.data)), getDefaultVolumes(queueData.data, 'volume'))); },
            _a[WizardStep.HandleTime] = function () { return getDefaultVolumes(queueData.data, 'handleTime', 'learnCurve'); },
            _a[WizardStep.Shrinkage] = function () { return selectShrinkageValues(queueData.data); },
            _a[WizardStep.Attrition] = function () { return getDefaultVolumes(queueData.data, 'attrition'); },
            _a[WizardStep.WorkingDays] = function () { return (__assign(__assign({}, selectWorkingDayValues(queueData.data)), getDefaultVolumes(queueData.data, 'workingDays'))); },
            _a[WizardStep.SupportStaff] = function () { return selectSupportStaffValues(queueData.data); },
            _a[WizardStep.Financial] = function () { return (__assign(__assign({}, selectFinancialValues(queueData.data)), getDefaultVolumes(queueData.data, 'rate'))); },
            _a[WizardStep.Review] = function () { return (__assign(__assign(__assign(__assign(__assign({}, selectVolumeValues(queueData.data)), selectShrinkageValues(queueData.data)), selectWorkingDayValues(queueData.data)), selectFinancialValues(queueData.data)), getDefaultVolumes(queueData.data, 'volume', 'handleTime', 'learnCurve', 'attrition', 'workingDays', 'rate'))); },
            _a);
        return __assign({ id: (_b = queueData.data) === null || _b === void 0 ? void 0 : _b.id }, (_c = defaultValue[step]) === null || _c === void 0 ? void 0 : _c.call(defaultValue));
    }, [queueData.data, selectFinancialValues, step]);
};
