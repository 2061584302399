var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { Outlet } from 'react-router-dom';
import { useUserSessionAPI } from '../components/app/selectors';
import { NotFound } from './notFound';
export var Authenticated = function (_a) {
    var roles = _a.roles, children = _a.children, _b = _a.fallback, fallback = _b === void 0 ? null : _b;
    var data = useUserSessionAPI()[0].data;
    var isValid = data && roles.includes(data.versionId);
    if (!isValid)
        return fallback;
    return _jsx(_Fragment, { children: children });
};
export var AuthRoute = function (props) {
    return (_jsx(Authenticated, __assign({ fallback: _jsx(NotFound, {}) }, props, { children: _jsx(Outlet, {}) })));
};
