var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Card, Stack, Tooltip, Typography, styled, useTheme } from '@mui/material';
import _ from 'lodash';
import { useRef } from 'react';
import Plot from 'react-plotly.js';
import { TICK_GAP, bold, configDefault } from './config';
var StyledChart = styled(Card)(function (_a) {
    var theme = _a.theme;
    return ({
        boxShadow: 'unset',
        background: theme.palette.common.white,
        height: '100%',
    });
});
var LegendContainer = styled(Box)({
    position: 'sticky',
    marginTop: '-1.875rem',
    padding: '0.5rem 5rem',
    zIndex: 7,
    display: 'flex',
    gap: '1rem',
    flexDirection: 'column',
});
var LegendGroup = styled(Box)({
    display: 'flex',
    width: 'fit-content',
    gap: '0.3125rem',
    alignItems: 'center',
});
var LegendColor = styled(Box)({
    width: '1rem',
    height: '1rem',
    flexShrink: 0,
});
var StyledPlot = styled(Plot)({
    width: '100%',
    height: '39rem',
    '.main-svg': {
        width: 'inherit',
    },
    '.rangeslider-mask-min, .rangeslider-mask-max': {
        fill: '#f1f1f1 !important',
        opacity: 0,
    },
    '.rangeslider-container .plot .scatterlayer': {
        visibility: 'hidden',
    },
    '.legendlines, .rangeslider-handle-min, .rangeslider-handle-max,.rangeslider-grabber-min, .rangeslider-grabber-max, .wdrag, .edrag': {
        display: 'none',
    },
    '.rangeslider-slidebox': {
        cursor: 'inherit',
        fill: '#c1c1c1',
        border: '1px solid red',
        strokeWidth: 1,
        height: 17,
        stroke: '#a8a8a8',
    },
});
export var MiddleLegend = function (_a) {
    var _b = _a.legends, legends = _b === void 0 ? [] : _b;
    return (_jsx(Box, __assign({ display: "grid", gridTemplateColumns: "repeat(".concat(legends.length, ", 1fr)") }, { children: legends.map(function (_a) {
            var text = _a.text, color = _a.color;
            return (_jsx(Tooltip, __assign({ title: text, placement: "top" }, { children: _jsx(Typography, __assign({ color: color, fontWeight: 700, fontSize: "1.1rem", whiteSpace: "normal", overflow: "hidden", textOverflow: "ellipsis", textAlign: "center" }, { children: text })) }), text));
        }) })));
};
export var Legend = function (_a) {
    var _b = _a.legends, legends = _b === void 0 ? [] : _b;
    return (_jsx(Stack, __assign({ gap: "0.625rem", flexDirection: "row", flexWrap: "wrap", width: "fit-content", justifyContent: "center", alignSelf: "center" }, { children: legends.map(function (_a) {
            var text = _a.text, color = _a.color;
            return (_jsxs(LegendGroup, { children: [_jsx(LegendColor, { sx: { bgcolor: color } }), _jsx(Typography, __assign({ fontSize: "1rem" }, { children: text }))] }, text));
        }) })));
};
export var Chart = function (_a) {
    var layout = _a.layout, data = _a.data, config = _a.config, title = _a.title, _b = _a.yAxisTitle, yAxisTitle = _b === void 0 ? '' : _b, _c = _a.yTickPrefix, yTickPrefix = _c === void 0 ? '' : _c, _d = _a.legends, legends = _d === void 0 ? [] : _d, _e = _a.middleLegends, middleLegends = _e === void 0 ? [] : _e, _f = _a.maxXAxis, maxXAxis = _f === void 0 ? 52 : _f;
    var refChart = useRef(null);
    var theme = useTheme();
    var layoutConfig = _.cloneDeep(layout);
    layoutConfig.font = { family: theme.typography.fontFamily };
    layoutConfig.title = bold(title);
    _.set(layoutConfig, 'yaxis.title', yAxisTitle);
    _.set(layoutConfig, 'yaxis.tickprefix', TICK_GAP + yTickPrefix);
    var handleReLayout = function (event) {
        if (event['xaxis.range'] && refChart.current) {
            var _a = event['xaxis.range'], x0 = _a[0], x1 = _a[1];
            var rangeX0 = -0.5;
            var rangeX1 = 12.5;
            var range = 12;
            var _x0 = Math.min(Math.max(x0, rangeX0), maxXAxis - rangeX1);
            var _x1 = _x0 + range;
            if (_x0 !== x0 || _x1 !== x1) {
                _.set(layoutConfig, 'xaxis.range', [_x0, _x1]);
                refChart.current.updatePlotly();
            }
        }
    };
    return (_jsxs(StyledChart, { children: [_jsx(StyledPlot, { ref: refChart, onRelayout: handleReLayout, data: data, layout: layoutConfig, config: __assign(__assign({}, configDefault), config) }), _jsxs(LegendContainer, { children: [!!middleLegends.length && _jsx(MiddleLegend, { legends: middleLegends }), !!legends.length && _jsx(Legend, { legends: legends })] })] }));
};
