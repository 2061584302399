import { configureStore } from '@reduxjs/toolkit';
import { api } from './api/reducer';
export var store = configureStore({
    reducer: {
        api: api,
    },
    middleware: function (getDefaultMiddleware) {
        return getDefaultMiddleware({
            serializableCheck: {
                // Ignore these action types
                ignoredActions: [],
                // Ignore these field paths in all actions
                ignoredActionPaths: ['payload.abortController', 'payload.data'],
                // Ignore these paths in the state
                ignoredPaths: ['api'],
            },
        });
    },
});
