var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faStarHalfStroke } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Radio, Stack, Typography, } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Autocomplete, InputLabel, RadioGroup } from '../common';
import { useMultiskillScenariosAPI } from './selectors';
var MultiskillOption;
(function (MultiskillOption) {
    MultiskillOption["New"] = "New";
    MultiskillOption["Copy"] = "Copy";
})(MultiskillOption || (MultiskillOption = {}));
export var MultiskillDialog = function (_a) {
    var open = _a.open, onClose = _a.onClose, scenario = _a.scenario;
    var t = useTranslation(['report', 'translation']).t;
    var navigate = useNavigate();
    var multiSkillScenarios = useMultiskillScenariosAPI();
    var _b = useState(MultiskillOption.New), multiSkillOption = _b[0], setMultiSkillOption = _b[1];
    var _c = useState(), copyScenarioId = _c[0], setCopyScenarioId = _c[1];
    useEffect(function () {
        var _a;
        setCopyScenarioId((_a = multiSkillScenarios === null || multiSkillScenarios === void 0 ? void 0 : multiSkillScenarios[0]) === null || _a === void 0 ? void 0 : _a.id);
    }, [multiSkillScenarios]);
    var handleContinue = function () {
        if (multiSkillOption === MultiskillOption.Copy) {
            return navigate("/multiskill/".concat(scenario === null || scenario === void 0 ? void 0 : scenario.basePlanId, "/").concat(scenario === null || scenario === void 0 ? void 0 : scenario.id, "?copyScenarioId=").concat(copyScenarioId));
        }
        navigate("/multiskill/".concat(scenario === null || scenario === void 0 ? void 0 : scenario.basePlanId, "/").concat(scenario === null || scenario === void 0 ? void 0 : scenario.id));
    };
    return (_jsx(Dialog, __assign({ open: open, onClose: onClose, maxWidth: "sm" }, { children: _jsxs(Stack, __assign({ spacing: 15 }, { children: [_jsxs(Stack, __assign({ spacing: 2 }, { children: [_jsx(DialogTitle, __assign({ variant: "h1" }, { children: _jsxs(Stack, __assign({ direction: "row", gap: 1 }, { children: [_jsx(FontAwesomeIcon, { icon: faStarHalfStroke }), t('multiskill')] })) })), _jsx(DialogContent, { children: _jsxs(Stack, __assign({ spacing: 5 }, { children: [_jsx(Typography, { children: t('annual.multiskill.createCopyScenarioContent') }), _jsxs(RadioGroup, __assign({ row: true, sx: { gap: 3 }, value: multiSkillOption, onChange: function (e, v) { return setMultiSkillOption(v); } }, { children: [_jsx(FormControlLabel, { value: MultiskillOption.New, control: _jsx(Radio, {}), label: t('annual.multiskill.new') }), _jsx(FormControlLabel, { value: MultiskillOption.Copy, control: _jsx(Radio, {}), label: t('annual.multiskill.copyFromAnother'), disabled: !(multiSkillScenarios === null || multiSkillScenarios === void 0 ? void 0 : multiSkillScenarios.length) })] })), multiSkillOption === MultiskillOption.Copy && (_jsxs(Stack, __assign({ width: "20rem" }, { children: [_jsx(InputLabel, __assign({ required: true }, { children: t('annual.multiskill.chooseScenario') })), _jsx(Autocomplete, { options: multiSkillScenarios, value: copyScenarioId, onChange: setCopyScenarioId })] })))] })) })] })), _jsxs(DialogActions, { children: [_jsx(Button, __assign({ variant: "outlined", onClick: onClose }, { children: t('translation:action.cancel') })), _jsx(Button, __assign({ variant: "contained", onClick: handleContinue }, { children: t('translation:action.continue') }))] })] })) })));
};
