import { CognitoUserAttribute, MFAType } from '../../enum';
import { useAPI } from '../../state';
export var MFA_CONFIG_API = '/api/user/mfa/setting';
export var MFA_ASSOCIATE_SOFTWARE_TOKEN_API = '/api/user/mfa/associateSoftwareToken';
export var MFA_CHANGE_METHOD_API = '/api/user/mfa/changeMFAMethod';
export var MFA_PHONE_VERIFICATION_CODE_API = '/api/user/mfa/phoneVerificationCode/{phone}';
export var MFA_VERIFY_PHONE_API = '/api/user/mfa/verifyPhone';
export var useMFAConfigAPI = function () {
    return useAPI(MFA_CONFIG_API);
};
export var useGetSecretCodeAPI = function () {
    return useAPI(MFA_ASSOCIATE_SOFTWARE_TOKEN_API);
};
export var useChangeMFAMethod = function () {
    return useAPI(MFA_CHANGE_METHOD_API, {}, 'PUT');
};
export var useVerifyPhone = function () {
    return useAPI(MFA_VERIFY_PHONE_API, {}, 'PUT');
};
export var useGetPhoneNumber = function () {
    var _a, _b, _c;
    var data = useMFAConfigAPI()[0].data;
    return (_c = (_b = (_a = data === null || data === void 0 ? void 0 : data.UserAttributes) === null || _a === void 0 ? void 0 : _a.find(function (_a) {
        var Name = _a.Name;
        return Name === CognitoUserAttribute.PhoneNumber;
    })) === null || _b === void 0 ? void 0 : _b.Value) !== null && _c !== void 0 ? _c : '';
};
export var useHasConfiguredAuthenticator = function () {
    var _a;
    var data = useMFAConfigAPI()[0].data;
    return (_a = data === null || data === void 0 ? void 0 : data.UserMFASettingList) === null || _a === void 0 ? void 0 : _a.includes(MFAType.TOTP);
};
export var useResendCode = function (phone) {
    return useAPI(MFA_PHONE_VERIFICATION_CODE_API, { phone: phone });
};
