import { useAPI } from '../../../state';
import { PLAN_COLORS } from '../constants';
import { usePlanAPI, useWizardParams } from '../selectors';
var CALCULATE_API = '/api/plan/calculate/{id}';
export var usePlanColor = function () {
    var _a, _b;
    var planData = usePlanAPI()[0];
    return PLAN_COLORS[(_b = (_a = planData.data) === null || _a === void 0 ? void 0 : _a.colorIndex) !== null && _b !== void 0 ? _b : 0];
};
export var useCalculateAPI = function () {
    var id = useWizardParams().id;
    return useAPI(CALCULATE_API, { id: id });
};
