var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { faChevronDown, faChevronUp } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Checkbox, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, Divider, List, ListItem, ListItemIcon, ListItemText, Typography, styled, } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ErrorMessageBox, FallbackComponent } from '../common';
import { useBillingConfigAPI, useUpdateBillingConfigAPI } from './selectors';
var StyledListWrapper = styled(Box)(function (_a) {
    var theme = _a.theme;
    return ({
        maxHeight: theme.typography.pxToRem(300),
        minHeight: theme.typography.pxToRem(300),
        overflow: 'auto',
        border: "1px solid ".concat(theme.palette.grey[600]),
    });
});
var StyledChildList = styled(List)(function (_a) {
    var theme = _a.theme;
    return ({
        backgroundColor: theme.palette.grey[100],
    });
});
var StyledListItem = styled(ListItem)(function () { return ({
    userSelect: 'none',
}); });
var allChildrenChecked = function (children, selectedItems) {
    return children.every(function (child) { return selectedItems.includes(child.id); });
};
var someChildrenChecked = function (children, selectedItems) {
    return children.some(function (child) { return selectedItems.includes(child.id); });
};
export var NestedListWithCheckboxes = function (_a) {
    var _b;
    var unit = _a.unit, selectedItems = _a.selectedUnits, onChange = _a.onChange;
    var unitSelectedItems = selectedItems[unit.id] || [];
    var _c = useState({}), open = _c[0], setOpen = _c[1];
    var handleCollapse = function (businessUnitId) {
        setOpen(function (prevOpen) {
            var _a;
            return (__assign(__assign({}, prevOpen), (_a = {}, _a[businessUnitId] = !prevOpen[businessUnitId], _a)));
        });
    };
    return (_jsxs(_Fragment, { children: [_jsxs(StyledListItem, { children: [_jsx(ListItemIcon, { children: _jsx(Checkbox, { edge: "start", checked: allChildrenChecked(unit.planningGroups || [], unitSelectedItems), indeterminate: someChildrenChecked(unit.planningGroups || [], unitSelectedItems) &&
                                !allChildrenChecked(unit.planningGroups || [], unitSelectedItems), onChange: function () { return onChange(unit.id, unit); } }) }), _jsx(ListItemText, { primary: unit.name }), _jsx(FontAwesomeIcon, { icon: open[unit.id] ? faChevronUp : faChevronDown, onClick: function () { return handleCollapse(unit.id); }, cursor: 'pointer' })] }), _jsxs(Collapse, __assign({ in: open[unit.id], timeout: "auto" }, { children: [_jsx(Divider, {}), _jsx(StyledChildList, __assign({ disablePadding: true }, { children: (_b = unit.planningGroups) === null || _b === void 0 ? void 0 : _b.map(function (group, pIndex, arr) { return (_jsxs(Box, { children: [_jsxs(StyledListItem, __assign({ sx: { pl: 8 } }, { children: [_jsx(ListItemIcon, { children: _jsx(Checkbox, { edge: "start", checked: unitSelectedItems.includes(group.id), onChange: function () { return onChange(unit.id, group); } }) }), _jsx(ListItemText, { primary: group.name })] })), pIndex < arr.length - 1 && _jsx(Divider, {})] }, group.id)); }) }))] }))] }));
};
export var BillingConfigDialog = function (_a) {
    var _b, _c;
    var user = _a.user, open = _a.open, onClose = _a.onClose;
    var orgId = (_b = user === null || user === void 0 ? void 0 : user.orgs[0]) === null || _b === void 0 ? void 0 : _b.id;
    var t = useTranslation(['manageUser', 'translation']).t;
    var _d = useBillingConfigAPI(orgId), billingConfigData = _d[0], fetchBillingConfig = _d[1], clearBillingConfig = _d[2];
    var _e = useUpdateBillingConfigAPI(orgId), updateBillingConfigData = _e[0], updateBillingConfig = _e[1], clearUpdateBillingConfig = _e[2];
    var defaultValues = useMemo(function () { var _a; return ({ selectedUnits: ((_a = billingConfigData.data) === null || _a === void 0 ? void 0 : _a.selectedUnits) || {} }); }, [billingConfigData.data]);
    var _f = useForm({
        defaultValues: defaultValues,
    }), handleSubmit = _f.handleSubmit, reset = _f.reset, watch = _f.watch, setValue = _f.setValue;
    var selectedUnits = watch('selectedUnits');
    useEffect(function () {
        if (open) {
            orgId && fetchBillingConfig();
            return function () {
                clearBillingConfig();
                clearUpdateBillingConfig();
            };
        }
    }, [clearBillingConfig, clearUpdateBillingConfig, fetchBillingConfig, open, orgId]);
    useEffect(function () {
        if (open) {
            console.log('defaultValues', defaultValues);
            reset(defaultValues);
        }
    }, [defaultValues, open, reset]);
    useEffect(function () {
        if (updateBillingConfigData.data) {
            onClose();
        }
    }, [updateBillingConfigData.data, onClose]);
    var handleChange = function (unitId, item) {
        var _a;
        var updatedUnits = __assign({}, selectedUnits);
        if (!updatedUnits[unitId]) {
            updatedUnits[unitId] = [];
        }
        if ('planningGroups' in item) {
            var groupIds = ((_a = item.planningGroups) === null || _a === void 0 ? void 0 : _a.map(function (group) { return group.id; })) || [];
            var isAllChildrenSelected = allChildrenChecked(item.planningGroups || [], updatedUnits[unitId]);
            if (isAllChildrenSelected) {
                delete updatedUnits[unitId];
            }
            else {
                updatedUnits[unitId] = groupIds;
            }
            return setValue('selectedUnits', updatedUnits);
        }
        if (updatedUnits[unitId].includes(item.id)) {
            updatedUnits[unitId] = updatedUnits[unitId].filter(function (id) { return id !== item.id; });
        }
        else {
            updatedUnits[unitId].push(item.id);
        }
        setValue('selectedUnits', updatedUnits);
    };
    var handleFormSubmit = function (values) {
        updateBillingConfig(values);
    };
    return (_jsx(Dialog, __assign({ open: open, onClose: onClose }, { children: _jsxs(Box, __assign({ component: "form", onSubmit: handleSubmit(handleFormSubmit) }, { children: [_jsx(DialogTitle, __assign({ variant: "h2" }, { children: t('billingConfig.title') })), _jsx(DialogContent, { children: _jsxs(FallbackComponent, __assign({ requesting: billingConfigData.requesting, overlay: true }, { children: [_jsx(Typography, __assign({ variant: "body1", marginBottom: 4 }, { children: t('billingConfig.subtitle') })), _jsx(ErrorMessageBox, { errors: [billingConfigData.error, updateBillingConfigData.error] }), _jsx(StyledListWrapper, { children: _jsx(List, __assign({ disablePadding: true }, { children: (_c = billingConfigData.data) === null || _c === void 0 ? void 0 : _c.businessUnits.map(function (businessUnit) { return (_jsxs(Box, { children: [_jsx(NestedListWithCheckboxes, { unit: businessUnit, selectedUnits: selectedUnits, onChange: handleChange }), _jsx(Divider, {})] }, businessUnit.id)); }) })) })] })) }), _jsxs(DialogActions, { children: [_jsx(Button, __assign({ variant: "outlined", onClick: onClose }, { children: t('translation:action.cancel') })), _jsx(LoadingButton, __assign({ variant: "contained", type: "submit" }, { children: t('translation:action.apply') }))] })] })) })));
};
