var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField, Typography, styled, } from '@mui/material';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { ErrorMessageBox, FallbackComponent } from '../common';
import { FormField } from '../common/formField';
import { useRenameScenario, useReportAPI } from '../report/selectors';
import { useSummaryReportAPI } from './selectors';
import { DialogMode } from './types';
var StyledStack = styled(Stack)(function () { return ({
    width: '100%',
}); });
var validateForm = yup.object().shape({
    name: yup.string().required().trim().max(15).specialCharacter().quotesNotAllowed().label('Name'),
    notes: yup.string().defined().trim().max(1000),
    financialNotes: yup.string().defined().trim().max(1000),
});
export var RenameScenarioDialog = function (_a) {
    var type = _a.type, scenario = _a.scenario, open = _a.open, onClose = _a.onClose;
    var t = useTranslation(['report', 'translation']).t;
    var _b = useRenameScenario(scenario === null || scenario === void 0 ? void 0 : scenario.id), renameData = _b[0], renameScenario = _b[1], clearRenameData = _b[2];
    var _c = useReportAPI(), fetchData = _c[1];
    var _d = useSummaryReportAPI(), fetchSummaryData = _d[1];
    var defaultValues = useMemo(function () {
        var _a, _b, _c;
        return ({
            name: (scenario === null || scenario === void 0 ? void 0 : scenario.isBaseline) ? 'Baseline' : (_a = scenario === null || scenario === void 0 ? void 0 : scenario.name) !== null && _a !== void 0 ? _a : '',
            notes: (_b = scenario === null || scenario === void 0 ? void 0 : scenario.notes) !== null && _b !== void 0 ? _b : '',
            financialNotes: (_c = scenario === null || scenario === void 0 ? void 0 : scenario.financialNotes) !== null && _c !== void 0 ? _c : '',
        });
    }, [scenario === null || scenario === void 0 ? void 0 : scenario.isBaseline, scenario === null || scenario === void 0 ? void 0 : scenario.name, scenario === null || scenario === void 0 ? void 0 : scenario.notes, scenario === null || scenario === void 0 ? void 0 : scenario.financialNotes]);
    var _e = useForm({
        defaultValues: defaultValues,
        resolver: yupResolver(validateForm),
    }), control = _e.control, handleSubmit = _e.handleSubmit, reset = _e.reset;
    useEffect(function () {
        if (open) {
            reset(defaultValues);
        }
    }, [defaultValues, open, reset]);
    useEffect(function () {
        if (open && renameData.data) {
            onClose();
            fetchData();
            fetchSummaryData();
        }
        return clearRenameData;
    }, [renameData.data, onClose, fetchData, fetchSummaryData, open, clearRenameData]);
    var handleFormSubmit = function (values) {
        renameScenario(values);
    };
    return (_jsx(Dialog, __assign({ open: open, onClose: onClose }, { children: _jsxs(Box, __assign({ component: "form", onSubmit: handleSubmit(handleFormSubmit) }, { children: [_jsx(DialogTitle, { children: _jsx(Typography, __assign({ variant: "body1" }, { children: type === DialogMode.RenameAfterSwitch
                            ? t('annual.chooseNewNameAfterSwitch')
                            : t('annual.renameScenarioTitle') })) }), _jsx(DialogContent, { children: _jsxs(FallbackComponent, __assign({ requesting: renameData.requesting, overlay: true }, { children: [_jsx(ErrorMessageBox, { errors: [renameData.error] }), _jsxs(Stack, __assign({ direction: "column", gap: 5 }, { children: [_jsx(FormField, __assign({ required: true, name: "name", label: t('annual.scenarioName'), control: control }, { children: _jsx(TextField, { disabled: (scenario === null || scenario === void 0 ? void 0 : scenario.isBaseline) || type === DialogMode.RenameFinancial, inputProps: {
                                                maxLength: 15,
                                            } }) })), _jsx(FormField, __assign({ name: type === DialogMode.RenameFinancial ? 'financialNotes' : 'notes', label: t('annual.scenarioNotes'), control: control }, { children: _jsx(TextField, { multiline: true, rows: 4, inputProps: {
                                                maxLength: 1000,
                                            } }) }))] }))] })) }), _jsx(DialogActions, { children: _jsxs(StyledStack, __assign({ direction: "row", justifyContent: "space-between" }, { children: [_jsx(Button, __assign({ variant: "contained", type: "submit", disabled: renameData.requesting }, { children: t('translation:action.save') })), _jsx(Button, __assign({ variant: "outlined", onClick: onClose }, { children: t('translation:action.cancel') }))] })) })] })) })));
};
