export var DialogMode;
(function (DialogMode) {
    DialogMode[DialogMode["User"] = 0] = "User";
    DialogMode[DialogMode["Company"] = 1] = "Company";
    DialogMode[DialogMode["ResetPassword"] = 2] = "ResetPassword";
    DialogMode[DialogMode["ChangeStatus"] = 3] = "ChangeStatus";
    DialogMode[DialogMode["ExportLog"] = 4] = "ExportLog";
    DialogMode[DialogMode["BillingLog"] = 5] = "BillingLog";
    DialogMode[DialogMode["BillingConfig"] = 6] = "BillingConfig";
    DialogMode[DialogMode["DeleteUser"] = 7] = "DeleteUser";
})(DialogMode || (DialogMode = {}));
export var LicenseType;
(function (LicenseType) {
    LicenseType[LicenseType["No"] = 0] = "No";
    LicenseType[LicenseType["Yes"] = 1] = "Yes";
})(LicenseType || (LicenseType = {}));
