var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faCircleCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
var StyleDialogContent = styled(DialogContent)({
    textAlign: 'center',
});
export var UploadSuccessStatusDialog = function (_a) {
    var uploadDataId = _a.uploadDataId;
    var t = useTranslation(['myPlans', 'translation']).t;
    var navigate = useNavigate();
    var handleClick = function () {
        navigate("/report/".concat(uploadDataId));
    };
    return (_jsxs(Dialog, __assign({ open: !!uploadDataId }, { children: [_jsx(DialogTitle, __assign({ variant: "h2", textAlign: "center" }, { children: t('upload.success') })), _jsxs(StyleDialogContent, { children: [_jsx(Typography, __assign({ color: "success.main", paddingBottom: 3 }, { children: _jsx(FontAwesomeIcon, { icon: faCircleCheck, size: "3x" }) })), _jsx(Typography, { children: t('upload.uploadFileSuccess') })] }), _jsx(DialogActions, __assign({ sx: { display: 'flex', justifyContent: 'center' } }, { children: _jsx(Button, __assign({ "data-testid": "done-btn", variant: "contained", type: "submit", onClick: handleClick }, { children: t('upload.viewPlan') })) }))] })));
};
