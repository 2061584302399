export var MultiSkillStep;
(function (MultiSkillStep) {
    MultiSkillStep[MultiSkillStep["Add"] = 0] = "Add";
    MultiSkillStep[MultiSkillStep["Input"] = 1] = "Input";
})(MultiSkillStep || (MultiSkillStep = {}));
export var QueueType;
(function (QueueType) {
    QueueType["SingleSkill"] = "SINGLE_SKILL";
    QueueType["Multiskill"] = "MULTI_SKILL";
})(QueueType || (QueueType = {}));
export var MultiskillType;
(function (MultiskillType) {
    MultiskillType[MultiskillType["All"] = 1] = "All";
    MultiskillType[MultiskillType["Optimized"] = 2] = "Optimized";
})(MultiskillType || (MultiskillType = {}));
