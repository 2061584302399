export var ROUTER_URL = {
    Home: '/',
    Wizard: '/wizard/:basePlanId/:id?',
    Users: '/users',
    Report: '/report/:basePlanId',
    MultiSkill: '/multiskill/:basePlanId/:id',
};
export var REPORT_ROUTER_URL = {
    Resourcing: 'resourcing',
    Financial: 'financial',
    Annual: 'summary',
    Period: 'period',
    Daily: 'daily',
    Training: 'training',
};
export var FULL_REPORT_ROUTER_URL = {
    Annual: "".concat(ROUTER_URL.Report, "/").concat(REPORT_ROUTER_URL.Resourcing, "/").concat(REPORT_ROUTER_URL.Annual),
    Period: "".concat(ROUTER_URL.Report, "/").concat(REPORT_ROUTER_URL.Resourcing, "/").concat(REPORT_ROUTER_URL.Period),
    Daily: "".concat(ROUTER_URL.Report, "/").concat(REPORT_ROUTER_URL.Resourcing, "/").concat(REPORT_ROUTER_URL.Daily),
    Training: "".concat(ROUTER_URL.Report, "/").concat(REPORT_ROUTER_URL.Training),
};
export var REPORT_TAB_PATTERN = "".concat(ROUTER_URL.Report, "/:tab");
export var REPORT_TYPE_PATTERN = "".concat(REPORT_TAB_PATTERN, "/:reportType");
export var PDF_ROUTER_URL = {
    PERIOD_RESOURCING_CHART: '/pdf/periodChart/resourcing/:basePlanId',
    PERIOD_FINANCIAL_CHART: '/pdf/periodChart/financial/:basePlanId',
    ANNUAL_RESOURCING_CHART: '/pdf/annualChart/resourcing/:basePlanId',
    ANNUAL_FINANCIAL_CHART: '/pdf/annualChart/financial/:basePlanId',
};
