import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfirmDialog } from '../common';
import { useResetPasswordAPI, useUsersAPI } from './selectors';
export var ResetPasswordDialog = function (_a) {
    var open = _a.open, user = _a.user, onClose = _a.onClose;
    var t = useTranslation(['manageUser', 'translation']).t;
    var _b = useResetPasswordAPI(user === null || user === void 0 ? void 0 : user.id), resetPasswordData = _b[0], resetPassword = _b[1], clearResetPasswordData = _b[2];
    var _c = useUsersAPI(), fetchUsers = _c[1];
    useEffect(function () {
        if (open && resetPasswordData.data) {
            onClose();
            fetchUsers();
            return clearResetPasswordData;
        }
    }, [resetPasswordData.data, onClose, fetchUsers, open, clearResetPasswordData]);
    var handleResetPassword = function () {
        resetPassword();
    };
    return (_jsx(ConfirmDialog, { open: open, onClose: onClose, title: t('resetPasswordTitle', { username: user === null || user === void 0 ? void 0 : user.name }), message: t('translation:confirmation.yesReset'), onConfirm: handleResetPassword, loading: !!resetPasswordData.requesting, errors: [resetPasswordData.error] }));
};
