var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Typography, styled } from '@mui/material';
import { useEffect } from 'react';
import { PlanType } from '../../enum';
import { FinancialGraphical, ResourcingGraphical } from '../annual/graphical';
import { useSummaryReportAPI } from '../annual/selectors';
import { useUserSessionAPI } from '../app/selectors';
import { Table } from '../common';
import { useReportAPI, useReportPlanType } from '../report/selectors';
import { PageBody, PageHead, PrintContainer } from './style';
var StyledAnnualChart = styled(PrintContainer)(function () { return ({
    '.js-plotly-plot': {
        height: '60vh',
    },
}); });
var AnnualChart = function (props) {
    var _a;
    var planType = useReportPlanType();
    var _b = useReportAPI(), reportData = _b[0], fetchReportData = _b[1];
    var _c = useUserSessionAPI(), fetchUserSessionData = _c[1];
    var _d = useSummaryReportAPI(), fetchSummaryReport = _d[1], clearSummaryReportData = _d[2];
    var printTitle = planType === PlanType.Weekly ? 'Summary' : 'Annual';
    useEffect(function () {
        fetchUserSessionData();
        fetchReportData();
        fetchSummaryReport();
        return clearSummaryReportData;
    }, [clearSummaryReportData, fetchReportData, fetchSummaryReport, fetchUserSessionData]);
    return (_jsx(StyledAnnualChart, { children: _jsxs(Table, { children: [_jsxs(PageHead, { children: [_jsx(Typography, __assign({ variant: "h1" }, { children: (_a = reportData.data) === null || _a === void 0 ? void 0 : _a.name })), _jsxs(Typography, __assign({ variant: "h2" }, { children: [props.title, " Requirements - ", printTitle] }))] }), _jsx(PageBody, { children: props.children })] }) }));
};
export var AnnualResourcingChart = function () {
    return (_jsx(AnnualChart, __assign({ title: "Resourcing" }, { children: _jsx(ResourcingGraphical, { disableBarButtons: true }) })));
};
export var AnnualFinancialChart = function () {
    return (_jsx(AnnualChart, __assign({ title: "Financial" }, { children: _jsx(FinancialGraphical, { disableBarButtons: true }) })));
};
