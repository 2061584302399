import _ from 'lodash';
import { useCallback } from 'react';
import { useAPI } from '../../../state';
import { useWizardParams } from '../selectors';
import { useWizardSimpleContext } from '../useWizardSimpleContext';
export var SHRINKAGE_API = '/api/shrinkage/{queueId}';
export var SHRINKAGE_CREATE_API = '/api/shrinkage';
export var SHRINKAGE_COPY_API = '/api/shrinkage/{dest}';
export var useShrinkageTable = function () {
    var _a;
    var queue = ((_a = useWizardSimpleContext()) !== null && _a !== void 0 ? _a : {}).queue;
    return _.groupBy(queue === null || queue === void 0 ? void 0 : queue.shrinkages, 'name');
};
export var useAddShrinkageAPI = function () {
    return useAPI(SHRINKAGE_CREATE_API, undefined, 'POST');
};
export var useClearShrinkageAPI = function () {
    var queueId = useWizardParams().queueId;
    return useAPI(SHRINKAGE_API, { queueId: queueId }, 'DELETE');
};
export var useCopyShrinkageAPI = function () {
    var dest = useWizardParams().queueId;
    var _a = useAPI(SHRINKAGE_COPY_API, { dest: dest }, 'POST'), data = _a[0], fetchData = _a[1], clearData = _a[2];
    var fetchDataWrapper = useCallback(function (source) {
        if (dest) {
            fetchData(undefined, { source: source });
        }
    }, [fetchData, dest]);
    return [data, fetchDataWrapper, clearData];
};
