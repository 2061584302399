import { jsx as _jsx } from "react/jsx-runtime";
import { HttpStatusCode } from 'axios';
import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { CHALLENGE_STATUS } from '../../state/api';
import { FallbackComponent } from '../common';
import { ExpiredUser } from '../expired';
import { TermOfUse } from '../tou';
import { ErrorPage } from './errorPage';
import { useUserSessionAPI } from './selectors';
export var App = function () {
    var _a = useUserSessionAPI(), userSessionData = _a[0], fetchUserSessionData = _a[1];
    var _b = useState(''), challengeStatus = _b[0], setChallengeStatus = _b[1];
    useEffect(function () {
        fetchUserSessionData();
    }, [fetchUserSessionData]);
    useEffect(function () {
        window.addEventListener('storage', function (event) {
            if (event.key === CHALLENGE_STATUS && event.newValue) {
                setChallengeStatus(event.newValue);
                sessionStorage.removeItem(CHALLENGE_STATUS);
            }
        });
    }, []);
    useEffect(function () {
        if (userSessionData.requesting) {
            setChallengeStatus('');
        }
    }, [userSessionData.requesting]);
    if (challengeStatus === HttpStatusCode.Gone.toString())
        return _jsx(ExpiredUser, {});
    if (challengeStatus === HttpStatusCode.PreconditionFailed.toString())
        return _jsx(TermOfUse, {});
    if (userSessionData.error)
        return _jsx(ErrorPage, { error: userSessionData.error });
    if (!userSessionData.data)
        return _jsx(FallbackComponent, { requesting: true, overlay: true });
    return _jsx(Outlet, {});
};
