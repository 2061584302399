import { useCallback } from 'react';
import { useAPI } from '../../../state';
import { useWizardParams } from '../selectors';
export var COPY_VOLUME_API = '/api/volume/{dest}/{type}';
export var CLEAR_VOLUME_API = '/api/volume/{queueId}/{type}';
export var useCopyVolumeAPI = function (type) {
    var dest = useWizardParams().queueId;
    var _a = useAPI(COPY_VOLUME_API, { dest: dest, type: type }, 'POST'), data = _a[0], fetchData = _a[1], clearData = _a[2];
    var fetchDataWrapper = useCallback(function (source) {
        if (dest) {
            fetchData(undefined, { source: source });
        }
    }, [fetchData, dest]);
    return [data, fetchDataWrapper, clearData];
};
export var useClearVolumeAPI = function (type) {
    var queueId = useWizardParams().queueId;
    var _a = useAPI(CLEAR_VOLUME_API, { queueId: queueId, type: type }, 'DELETE'), data = _a[0], fetchData = _a[1], clearData = _a[2];
    var fetchDataWrapper = useCallback(function () {
        if (queueId) {
            fetchData();
        }
    }, [fetchData, queueId]);
    return [data, fetchDataWrapper, clearData];
};
