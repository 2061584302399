var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingButton } from '@mui/lab';
import { Stack, Typography, styled } from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { WORK_TYPE_ICON } from '../constants';
import { WizardContext } from '../context';
import { useQueueAPI } from '../queue/selectors';
import { useCalculateAPI } from './selectors';
var QueueTitle = styled(Stack)(function (_a) {
    var theme = _a.theme;
    return ({
        gap: theme.spacing(2.5),
        alignItems: 'center',
    });
});
export var ActionBar = function (_a) {
    var _b, _c, _d;
    var _e = _a.showQueue, showQueue = _e === void 0 ? false : _e;
    var t = useTranslation(['translation']).t;
    var queueData = useQueueAPI()[0];
    var calculatedData = useCalculateAPI()[0];
    var _f = useContext(WizardContext), actionRef = _f.actionRef, requesting = _f.requesting;
    return (_jsxs(Stack, __assign({ direction: "row", gap: 2 }, { children: [showQueue && (_jsxs(QueueTitle, __assign({ direction: "row" }, { children: [_jsx(Typography, __assign({ variant: "h3" }, { children: _jsx(FontAwesomeIcon, { icon: WORK_TYPE_ICON[((_c = (_b = queueData.data) === null || _b === void 0 ? void 0 : _b.worktypeId) !== null && _c !== void 0 ? _c : 0) - 1] }) })), _jsx(Typography, __assign({ variant: "subtitle1" }, { children: (_d = queueData.data) === null || _d === void 0 ? void 0 : _d.name }))] }))), _jsx(LoadingButton, __assign({ sx: { ml: 'auto' }, variant: "contained", type: "submit", loading: (actionRef.current === 'submit' && requesting) || !!calculatedData.requesting }, { children: _jsx("span", { children: t('action.calculate') }) }))] })));
};
