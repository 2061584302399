var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { yupResolver } from '@hookform/resolvers/yup';
import _ from 'lodash';
import { createContext, useCallback, useEffect, useMemo, useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { FormlessProvider } from '../hooks/useFormlessContext';
import { useCompletedStep, useQueueAPI, useQueuesAPI, useRefreshQueueData, useSaveQueueAPI } from './queue/selectors';
import { usePlanAPI, useWizardParams } from './selectors';
import { useDefaultValues } from './useDefaultValue';
import { useValidateForm } from './validateForm';
export var WizardContext = createContext({});
export var WizardProvider = function (props) {
    var _a;
    var navigate = useNavigate();
    var defaultValues = useDefaultValues();
    var validateForm = useValidateForm();
    var actionRef = useRef();
    var callbackRef = useRef();
    var methods = useForm({
        defaultValues: defaultValues,
        resolver: yupResolver(validateForm),
    });
    var reset = methods.reset, getValues = methods.getValues, handleSubmit = methods.handleSubmit, isDirty = methods.formState.isDirty;
    var _b = useWizardParams(), step = _b.step, queueId = _b.queueId;
    var queueData = useQueueAPI()[0];
    var queuesData = useQueuesAPI()[0];
    var _c = usePlanAPI(), planData = _c[0], fetchPlan = _c[1];
    var completedStep = useCompletedStep()[0];
    var _d = useSaveQueueAPI(), savedData = _d[0], fetchSave = _d[1], clearSavedData = _d[2];
    var requesting = !!savedData.requesting || !!queueData.requesting || !!queuesData.requesting;
    var refreshData = useRefreshQueueData();
    useEffect(function () {
        reset(defaultValues);
    }, [defaultValues, reset]);
    useEffect(function () {
        var _a;
        if (savedData.data) {
            refreshData();
            var currency = getValues().currency;
            if (currency !== ((_a = planData.data) === null || _a === void 0 ? void 0 : _a.currency)) {
                fetchPlan();
            }
        }
        return clearSavedData;
    }, [clearSavedData, fetchPlan, getValues, (_a = planData.data) === null || _a === void 0 ? void 0 : _a.currency, refreshData, savedData.data]);
    useEffect(function () {
        actionRef.current = undefined;
        callbackRef.current = undefined;
    }, [savedData.error]);
    useEffect(function () {
        if (queuesData.data && callbackRef.current) {
            callbackRef.current(completedStep);
            callbackRef.current = undefined;
            actionRef.current = undefined;
        }
    }, [completedStep, queuesData.data]);
    var handleSave = useCallback(function (action, callback) {
        if (action === void 0) { action = 'submit'; }
        return function (e) { return __awaiter(void 0, void 0, void 0, function () {
            var isValid, shouldSkipSubmit;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, validateForm.isValid(getValues())];
                    case 1:
                        isValid = _a.sent();
                        shouldSkipSubmit = !step || (!isDirty && action !== 'next') || (!isDirty && isValid);
                        if (shouldSkipSubmit) {
                            callback === null || callback === void 0 ? void 0 : callback();
                            if (action !== 'submit') {
                                return [2 /*return*/];
                            }
                        }
                        handleSubmit === null || handleSubmit === void 0 ? void 0 : handleSubmit(function (_a) {
                            var shrinkages = _a.shrinkages, _b = _a.wapVolumes, wapVolumes = _b === void 0 ? {} : _b, values = __rest(_a, ["shrinkages", "wapVolumes"]);
                            if (!isDirty || requesting)
                                return;
                            callbackRef.current = callback;
                            actionRef.current = action;
                            fetchSave(__assign(__assign({}, values), { shrinkages: _.flatten(shrinkages), wapVolumes: _.flattenDeep(Object.values(wapVolumes)) }));
                        })(e);
                        return [2 /*return*/];
                }
            });
        }); };
    }, [fetchSave, getValues, handleSubmit, isDirty, requesting, step, validateForm]);
    var goToStep = useCallback(function (nextStep) {
        navigate("".concat(window.location.pathname, "?queueId=").concat(queueId, "&step=").concat(nextStep));
        window.scrollTo(0, 0);
    }, [navigate, queueId]);
    var goToQueue = useCallback(function (id) {
        navigate("".concat(window.location.pathname, "?queueId=").concat(id, "&step=").concat(step));
    }, [navigate, step]);
    var value = useMemo(function () { return ({ handleSave: handleSave, actionRef: actionRef, requesting: requesting, goToStep: goToStep, goToQueue: goToQueue, methods: methods }); }, [goToQueue, goToStep, handleSave, methods, requesting]);
    return (_jsx(WizardContext.Provider, __assign({ value: value }, { children: _jsx(FormProvider, __assign({}, methods, { children: _jsx(FormlessProvider, __assign({ control: methods.control, setValue: methods.setValue }, { children: props.children })) })) })));
};
