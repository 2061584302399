var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
import { createSlice } from '@reduxjs/toolkit';
var initialState = {};
var apiSlice = createSlice({
    name: 'api',
    initialState: initialState,
    reducers: {
        fetchStart: function (state, action) {
            var _a = action.payload, key = _a.key, abortController = _a.abortController;
            state[key] = __assign(__assign({}, state[key]), { requesting: true, abortController: abortController });
            delete state[key].error;
        },
        fetchSuccess: function (state, action) {
            var _a = action.payload, key = _a.key, data = _a.data, statusCode = _a.statusCode;
            state[key] = {
                requesting: false,
                data: data,
                statusCode: statusCode,
            };
        },
        fetchFailed: function (state, action) {
            var _a = action.payload, key = _a.key, error = _a.error, statusCode = _a.statusCode;
            state[key] = {
                requesting: false,
                error: error,
                statusCode: statusCode,
            };
        },
        fetchReset: function (state, action) {
            var key = action.payload.key;
            delete state[key];
        },
    },
});
export var fetchStart = (_a = apiSlice.actions, _a.fetchStart), fetchSuccess = _a.fetchSuccess, fetchFailed = _a.fetchFailed, fetchReset = _a.fetchReset;
export var api = apiSlice.reducer;
