var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useAPI } from '../../state';
var TIME_FORMAT = 'HHmmss_DDMMYYYY';
var ASYNC_STATUS_API = '/api/report/process/{id}';
var INTERVAL_CHECK_STATUS = 2000;
export var TaskStatus;
(function (TaskStatus) {
    TaskStatus["Processing"] = "processing";
    TaskStatus["Completed"] = "completed";
    TaskStatus["Failed"] = "failed";
})(TaskStatus || (TaskStatus = {}));
export var TaskType;
(function (TaskType) {
    TaskType["MultiskillSimulation"] = "MultiskillSimulation";
})(TaskType || (TaskType = {}));
export var getDownloadFileName = function (fileName) {
    var timestamp = moment().format(TIME_FORMAT);
    var lastDot = fileName.lastIndexOf('.');
    return "".concat(fileName.substring(0, lastDot), "_").concat(timestamp).concat(fileName.substring(lastDot));
};
export var useDownload = function (url, fileName, _a) {
    var _b = _a === void 0 ? {} : _a, method = _b.method, substitutions = _b.substitutions, _c = _b.shouldDownload, shouldDownload = _c === void 0 ? true : _c, _d = _b.responseType, responseType = _d === void 0 ? 'blob' : _d;
    var _e = useAPI(url, substitutions, method, {
        responseType: responseType,
    }), downloadData = _e[0], fetchDownloadData = _e[1], clearDownloadData = _e[2];
    useEffect(function () {
        if (downloadData.data && shouldDownload) {
            var link = document.createElement('a');
            link.href = downloadData.data instanceof Blob ? URL.createObjectURL(downloadData.data) : downloadData.data;
            link.download = getDownloadFileName(fileName);
            link.click();
        }
        return clearDownloadData;
    }, [clearDownloadData, downloadData.data, fileName, shouldDownload]);
    return [downloadData, fetchDownloadData, clearDownloadData];
};
export var useAsyncStatusAPI = function (id) {
    var _a = useAPI(ASYNC_STATUS_API, { id: id }), statusData = _a[0], fetchStatusData = _a[1], clearStatusData = _a[2];
    var _b = useState(false), requesting = _b[0], setRequesting = _b[1];
    useEffect(function () {
        setRequesting(!!statusData.requesting);
    }, [statusData.requesting]);
    useEffect(function () {
        if (id && (!statusData.data || statusData.data.status === TaskStatus.Processing)) {
            var timeoutId_1 = setTimeout(function () { return fetchStatusData(); }, INTERVAL_CHECK_STATUS);
            setRequesting(true);
            return function () {
                clearTimeout(timeoutId_1);
            };
        }
    }, [fetchStatusData, id, statusData.data]);
    useEffect(function () { return clearStatusData; }, [clearStatusData]);
    return [__assign(__assign({}, statusData), { requesting: requesting }), fetchStatusData, clearStatusData];
};
export var useAsyncDownload = function (url) {
    var _a, _b, _c;
    var _d = useAPI(url, undefined, 'POST'), downloadData = _d[0], fetchDownloadData = _d[1], clearDownloadData = _d[2];
    var statusData = useAsyncStatusAPI((_a = downloadData.data) === null || _a === void 0 ? void 0 : _a.id)[0];
    var requesting = !!downloadData.requesting || !!statusData.requesting;
    var error = (_b = downloadData.error) !== null && _b !== void 0 ? _b : statusData.error;
    useEffect(function () {
        var _a;
        if ((_a = statusData.data) === null || _a === void 0 ? void 0 : _a.details.downloadUrl) {
            var link = document.createElement('a');
            link.href = statusData.data.details.downloadUrl;
            link.click();
        }
    }, [clearDownloadData, (_c = statusData.data) === null || _c === void 0 ? void 0 : _c.details.downloadUrl]);
    useEffect(function () { return clearDownloadData; }, [clearDownloadData]);
    return [__assign(__assign({}, statusData), { requesting: requesting, error: error }), fetchDownloadData, clearDownloadData];
};
