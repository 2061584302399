var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Typography } from '@mui/material';
import { useEffect } from 'react';
import { useUserSessionAPI } from '../app/selectors';
import { Table } from '../common';
import { FINANCIAL_CHARTS, RESOURCING_CHARTS } from '../period/constants';
import { PeriodGraphical } from '../period/graphical';
import { usePeriodReportAPI } from '../period/selectors';
import { useReportAPI } from '../report/selectors';
import { PageBody, PageHead, PrintContainer } from './style';
var PeriodChart = function (props) {
    var _a;
    var _b = useReportAPI(), reportData = _b[0], fetchReportData = _b[1];
    var _c = useUserSessionAPI(), fetchUserSessionData = _c[1];
    var _d = usePeriodReportAPI(), fetchPeriodReport = _d[1], clearPeriodReportData = _d[2];
    useEffect(function () {
        fetchUserSessionData();
        fetchReportData();
        fetchPeriodReport();
        return clearPeriodReportData;
    }, [clearPeriodReportData, fetchPeriodReport, fetchReportData, fetchUserSessionData]);
    return (_jsx(PrintContainer, { children: _jsxs(Table, { children: [_jsxs(PageHead, { children: [_jsx(Typography, __assign({ variant: "h1" }, { children: (_a = reportData.data) === null || _a === void 0 ? void 0 : _a.name })), _jsxs(Typography, __assign({ variant: "h2" }, { children: [props.title, " Requirements - Monthly"] }))] }), _jsx(PageBody, { children: _jsx(PeriodGraphical, { disableBarButtons: true, types: props.types }) })] }) }));
};
export var PeriodResourcingChart = function () {
    return _jsx(PeriodChart, { title: "Resourcing", types: RESOURCING_CHARTS });
};
export var PeriodFinancialChart = function () {
    return _jsx(PeriodChart, { title: "Financial", types: FINANCIAL_CHARTS });
};
