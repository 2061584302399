var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid, Stack, Typography } from '@mui/material';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { FallbackComponent } from '../../common';
import { stringToNumber } from '../../common/numberInput/hooks';
import { FormlessProvider } from '../../hooks/useFormlessContext';
import { useReportAPI } from '../../report/selectors';
import { useToastMessagesAPI } from '../../toastify/selector';
import { pickNumberValues, selectShrinkageValues, selectSupportStaffValues } from '../../wizard/useDefaultValue';
import { WizardSimpleProvider } from '../../wizard/useWizardSimpleContext';
import { generateValidateField, shrinkageShape, staffShape } from '../../wizard/validateForm';
import { Actions } from '../actions';
import { useMultiSkillParams } from '../selectors';
import { MultiSkillStep } from '../types';
import { MultiSkillGroupDetail } from './multiSkillGroupDetail';
import { MultiSkillGroups } from './multiSkillGroups';
import { useGroupDetailAPI, useMultiSkillAPI, useSimulationAPI } from './selectors';
var validateForm = yup.object().shape(__assign(__assign(__assign({ maxOccupancy: yup.number().nullable() }, shrinkageShape), staffShape), { volumes: generateValidateField('rate', 'attrition'), multiplier: yup.number().required(), trainWeeks: yup.number().required() }));
export var MultiSkillGroup = function (_a) {
    var _b;
    var multiSkillGroups = _a.multiSkillGroups, setMultiSkillGroups = _a.setMultiSkillGroups, goToStep = _a.goToStep, permission = _a.permission;
    var _c = useState(0), activeGroup = _c[0], setActiveGroup = _c[1];
    var defaultValues = useMemo(function () { return multiSkillGroups[activeGroup]; }, [activeGroup, multiSkillGroups]);
    var planData = useReportAPI()[0];
    var _d = useGroupDetailAPI(multiSkillGroups[activeGroup]), queueData = _d[0], fetchQueueData = _d[1], clearQueueData = _d[2];
    var _e = useMultiSkillAPI(), multiskillData = _e[0], fetchMultiskillData = _e[1], clearMultiskillData = _e[2];
    var _f = useSimulationAPI(), simulationData = _f[0], fetchSimulationData = _f[1], clearSimulationData = _f[2];
    var _g = useToastMessagesAPI(), fetchToastMessagesData = _g[1];
    var basePlanId = useMultiSkillParams().basePlanId;
    var navigate = useNavigate();
    var _h = useForm({
        defaultValues: defaultValues,
        resolver: yupResolver(validateForm),
    }), reset = _h.reset, handleSubmit = _h.handleSubmit, control = _h.control, setValue = _h.setValue, getValues = _h.getValues;
    var queue = useWatch({ name: '_raw', control: control });
    var isActionLoading = !!multiskillData.requesting || !!simulationData.requesting;
    useEffect(function () {
        if (!multiSkillGroups.length) {
            goToStep(MultiSkillStep.Add);
        }
    }, [goToStep, multiSkillGroups.length]);
    useEffect(function () {
        fetchQueueData();
        return clearQueueData;
    }, [clearQueueData, fetchQueueData]);
    useEffect(function () {
        if (multiskillData.data) {
            fetchSimulationData();
        }
        return clearMultiskillData;
    }, [clearMultiskillData, fetchSimulationData, multiskillData.data]);
    useEffect(function () {
        if (simulationData.data) {
            navigate("/report/".concat(basePlanId));
            fetchToastMessagesData();
        }
        return clearSimulationData;
    }, [basePlanId, clearSimulationData, fetchToastMessagesData, navigate, simulationData.data]);
    useEffect(function () {
        reset(defaultValues);
    }, [defaultValues, reset]);
    useEffect(function () {
        if (queueData.data) {
            var shrinkages = selectShrinkageValues(queueData.data);
            var roles = selectSupportStaffValues(queueData.data);
            var volumes = _.map(queueData.data.volumes, function (v) { return pickNumberValues(v, [], 'rate', 'attrition'); });
            reset(__assign(__assign(__assign(__assign({ _raw: queueData.data }, multiSkillGroups[activeGroup]), shrinkages), roles), { volumes: volumes, multiplier: stringToNumber(queueData.data.multiplier), trainWeeks: stringToNumber(queueData.data.trainWeeks.toString()) }));
        }
    }, [activeGroup, multiSkillGroups, queueData.data, reset]);
    var handleFormSubmit = handleSubmit(function (data) {
        var newData = __spreadArray([], multiSkillGroups, true);
        newData[activeGroup] = data;
        fetchMultiskillData({
            items: newData.map(function (item) { return (__assign(__assign({}, item), { shrinkages: _.flatten(item.shrinkages), multiskillType: Number(item.multiskillType), isUseWAP: Number(item.isUseWAP), _children: undefined, _raw: undefined })); }),
        });
    });
    var setGroupValues = function (values) {
        setMultiSkillGroups(multiSkillGroups.map(function (item, index) { return (index === activeGroup ? __assign(__assign({}, values), { _children: values.children }) : item); }));
    };
    var handleChangeActiveGroup = function (value) {
        return handleSubmit(function (values) {
            setActiveGroup(value);
            setGroupValues(values);
        })();
    };
    var handleModifyGroups = function () { return __awaiter(void 0, void 0, void 0, function () {
        var values, isValid;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    values = getValues();
                    return [4 /*yield*/, validateForm.isValid(values)];
                case 1:
                    isValid = _a.sent();
                    if (isValid) {
                        setGroupValues(values);
                    }
                    goToStep(MultiSkillStep.Add);
                    return [2 /*return*/];
            }
        });
    }); };
    var goToReport = function () {
        navigate("/report/".concat(basePlanId));
    };
    return (_jsxs(Box, __assign({ my: 6, gap: 4, component: "form", onSubmit: permission ? handleFormSubmit : goToReport }, { children: [_jsxs(Grid, __assign({ container: true, direction: "row", gap: 8, wrap: "nowrap" }, { children: [_jsx(Grid, __assign({ item: true, xs: 3, lg: 3 }, { children: _jsx(MultiSkillGroups, { data: multiSkillGroups, value: activeGroup, onChange: handleChangeActiveGroup, onModify: handleModifyGroups }) })), _jsxs(Grid, __assign({ item: true, xs: 9 }, { children: [_jsxs(Stack, __assign({ direction: "row", gap: 2, mb: 5, alignItems: "center" }, { children: [_jsx(Typography, __assign({ variant: "h2" }, { children: (_b = multiSkillGroups[activeGroup]) === null || _b === void 0 ? void 0 : _b.businessArea })), _jsx(Actions, { mt: 0, loading: isActionLoading })] })), _jsx(FormlessProvider, __assign({ control: control, setValue: setValue }, { children: _jsx(WizardSimpleProvider, __assign({ plan: planData.data, queue: queue }, { children: _jsx(FallbackComponent, __assign({ requesting: !!queueData.requesting }, { children: _jsx(MultiSkillGroupDetail, { activeData: defaultValues, permission: permission }) })) })) }))] }))] })), _jsx(Actions, { loading: isActionLoading })] })));
};
