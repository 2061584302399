var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { yupResolver } from '@hookform/resolvers/yup';
import { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { usePlatformContext } from '../../app/selectors';
import { AddNameForm } from '../common/addNameForm';
import { WizardContext } from '../context';
import { useQueueAPI } from '../queue/selectors';
import { useWizardParams } from '../selectors';
import { useAddShrinkageAPI } from './selectors';
var initialValues = {
    name: '',
};
var validateForm = yup.object().shape({
    name: yup.string().required().max(50).trim().specialCharacter().label('Name'),
});
export var AddShrinkageFactor = function () {
    var t = useTranslation(['wizard', 'translation']).t;
    var queueId = useWizardParams().queueId;
    var _a = useAddShrinkageAPI(), addShrinkageData = _a[0], addShrinkage = _a[1], clearAddShrinkage = _a[2];
    var _b = useQueueAPI(), fetchQueue = _b[1];
    var context = usePlatformContext();
    var _c = useForm({
        defaultValues: initialValues,
        resolver: yupResolver(validateForm),
    }), control = _c.control, handleSubmit = _c.handleSubmit, reset = _c.reset;
    var _d = useContext(WizardContext), handleSave = _d.handleSave, isDirty = _d.methods.formState.isDirty;
    useEffect(function () {
        if (addShrinkageData.data) {
            reset(initialValues);
            fetchQueue();
        }
        return clearAddShrinkage;
    }, [addShrinkageData.data, clearAddShrinkage, fetchQueue, reset]);
    var handleAddShrinkageName = handleSubmit(function (values) {
        if (isDirty) {
            return handleSave('submit', function () { return addShrinkage(__assign(__assign({}, values), { queueId: queueId })); })();
        }
        addShrinkage(__assign(__assign({}, values), { queueId: queueId }));
    });
    return (_jsx(AddNameForm, { toggleText: t('shrinkage.addAnotherShrinkageFactor', { context: context }), label: t('shrinkage.shrinkageFactorName', { context: context }), maxLength: 50, control: control, name: "name", onSubmit: handleAddShrinkageName, loading: addShrinkageData.requesting, errors: [addShrinkageData.error] }));
};
