import {
  AnnualFinancialChart,
  AnnualFinancialReport,
  AnnualResourcingChart,
  AnnualResourcingReport,
  App,
  AppLayout,
  AuthRoute,
  DailyReport,
  FinancialLayout,
  Home,
  ManageUsers,
  MultiSkill,
  NotFound,
  PDF_ROUTER_URL,
  PeriodFinancialChart,
  PeriodFinancialReport,
  PeriodResourcingChart,
  PeriodResourcingReport,
  REPORT_ROUTER_URL,
  ROUTER_URL,
  ReportLayout,
  ResourcingLayout,
  Role,
  TrainingRecruitment,
  Wizard,
} from 'common-ui';
import { Navigate, createBrowserRouter } from 'react-router-dom';

export const router = createBrowserRouter([
  {
    path: ROUTER_URL.Home,
    errorElement: <NotFound />,
    Component: App,
    children: [
      {
        element: <AppLayout />,
        children: [
          {
            index: true,
            element: <Home />,
          },
          {
            path: ROUTER_URL.Wizard,
            element: <Wizard />,
          },
          {
            element: <AuthRoute roles={[Role.Superadmin]} />,
            children: [
              {
                path: ROUTER_URL.Users,
                element: <ManageUsers />,
              },
            ],
          },
          {
            path: ROUTER_URL.Report,
            element: <ReportLayout />,
            children: [
              {
                index: true,
                element: <Navigate to={REPORT_ROUTER_URL.Resourcing} replace />,
              },
              {
                path: REPORT_ROUTER_URL.Resourcing,
                element: <ResourcingLayout />,
                children: [
                  {
                    index: true,
                    element: <Navigate to={REPORT_ROUTER_URL.Annual} replace />,
                  },
                  {
                    path: REPORT_ROUTER_URL.Annual,
                    element: <AnnualResourcingReport />,
                  },
                  {
                    path: REPORT_ROUTER_URL.Period,
                    element: <PeriodResourcingReport />,
                  },
                  {
                    path: REPORT_ROUTER_URL.Daily,
                    element: <DailyReport />,
                  },
                ],
              },
              {
                path: REPORT_ROUTER_URL.Financial,
                element: <FinancialLayout />,
                children: [
                  {
                    index: true,
                    element: <Navigate to={REPORT_ROUTER_URL.Annual} replace />,
                  },
                  {
                    path: REPORT_ROUTER_URL.Annual,
                    element: <AnnualFinancialReport />,
                  },
                  {
                    path: REPORT_ROUTER_URL.Period,
                    element: <PeriodFinancialReport />,
                  },
                ],
              },
              {
                path: REPORT_ROUTER_URL.Training,
                element: <TrainingRecruitment />,
              },
            ],
          },
          {
            path: ROUTER_URL.MultiSkill,
            element: <MultiSkill />,
          },
        ],
      },
    ],
  },
  {
    path: PDF_ROUTER_URL.PERIOD_RESOURCING_CHART,
    element: <PeriodResourcingChart />,
  },
  {
    path: PDF_ROUTER_URL.PERIOD_FINANCIAL_CHART,
    element: <PeriodFinancialChart />,
  },
  {
    path: PDF_ROUTER_URL.ANNUAL_RESOURCING_CHART,
    element: <AnnualResourcingChart />,
  },
  {
    path: PDF_ROUTER_URL.ANNUAL_FINANCIAL_CHART,
    element: <AnnualFinancialChart />,
  },
]);
