var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Stack, Typography } from '@mui/material';
import { useContext, useEffect } from 'react';
import { ErrorMessageBox, FallbackComponent } from '../../common';
import { WizardContext } from '../context';
import { useQueueAPI, useQueuesAPI, useRefreshQueueData } from '../queue/selectors';
import { useClearVolumeAPI, useCopyVolumeAPI } from '../workVolume/selectors';
import { CopyDataFrom } from './copyDataFrom';
export var BasePanel = function (_a) {
    var _b;
    var _c = _a.errors, errors = _c === void 0 ? [] : _c, _d = _a.permission, permission = _d === void 0 ? true : _d, props = __rest(_a, ["errors", "permission"]);
    var queuesData = useQueuesAPI()[0];
    var queueData = useQueueAPI()[0];
    var refreshData = useRefreshQueueData();
    var _e = useCopyVolumeAPI(props.statusType), copyVolumeData = _e[0], clearCopyVolume = _e[2];
    var _f = useClearVolumeAPI(props.statusType), clearVolumeData = _f[0], cleanClearVolume = _f[2];
    var _g = useContext(WizardContext), handleSave = _g.handleSave, requesting = _g.requesting;
    useEffect(function () {
        if (clearVolumeData.data || copyVolumeData.data) {
            refreshData();
        }
        return function () {
            clearCopyVolume();
            cleanClearVolume();
        };
    }, [cleanClearVolume, clearCopyVolume, clearVolumeData.data, copyVolumeData.data, refreshData]);
    return (_jsxs(Stack, __assign({ spacing: 2, position: "relative" }, { children: [_jsx(ErrorMessageBox, { errors: __spreadArray(__spreadArray([], errors, true), [queuesData.error, queueData.error, copyVolumeData.error, clearVolumeData.error], false) }), _jsxs(FallbackComponent, __assign({ requesting: !!requesting || !!props.requesting || !!copyVolumeData.requesting || !!clearVolumeData.requesting, overlay: true }, { children: [_jsxs(Stack, __assign({ direction: "row", spacing: 4, justifyContent: "space-between" }, { children: [_jsxs(Box, { children: [_jsx(Typography, __assign({ variant: "h3", gutterBottom: true }, { children: props.title })), _jsx(Typography, __assign({ gutterBottom: true }, { children: props.description })), props.leftContent] }), props.statusType && (_jsx(CopyDataFrom, { statusType: props.statusType, onCopy: props.onCopy, onClear: props.onClear, needSaveBeforeCopy: props.needSaveBeforeCopy, disabled: !permission }))] })), props.longDescription && _jsx(Typography, __assign({ gutterBottom: true }, { children: props.longDescription })), _jsxs(Stack, __assign({ direction: "row", alignItems: "flex-end", justifyContent: "space-between" }, { children: [_jsx(Typography, __assign({ variant: "subtitle2" }, { children: (_b = queueData.data) === null || _b === void 0 ? void 0 : _b.name })), props.rightContent] })), _jsxs(Stack, __assign({ component: "form", onSubmit: handleSave('submit'), spacing: 4, "data-testid": "panelForm" }, { children: [props.children, _jsx("button", { type: "submit", hidden: true, disabled: requesting })] }))] }))] })));
};
