var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Pagination as MuiPagination, Stack, paginationClasses, styled, tablePaginationClasses } from '@mui/material';
import { GridPagination, gridFilteredTopLevelRowCountSelector, gridPageSizeSelector, useGridApiContext, useGridRootProps, useGridSelector, } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
var StyleSortArrowIcon = styled(FontAwesomeIcon)(function (_a) {
    var theme = _a.theme;
    return ({
        marginLeft: "-".concat(theme.spacing(1)),
        marginBottom: "-".concat(theme.spacing(1)),
    });
});
var StyleGridPagination = styled(GridPagination)((_a = {
        margin: '0 auto'
    },
    _a["&.".concat(tablePaginationClasses.root, " .").concat(tablePaginationClasses.toolbar)] = {
        padding: 0,
    },
    _a["&.".concat(tablePaginationClasses.root, " .").concat(tablePaginationClasses.displayedRows)] = {
        display: 'none',
    },
    _a["&.".concat(tablePaginationClasses.root, " .").concat(paginationClasses.root)] = {
        marginLeft: 0,
    },
    _a["&.".concat(tablePaginationClasses.root, " .").concat(tablePaginationClasses.spacer)] = {
        display: 'none',
    },
    _a));
export var SortIcon = function (props) {
    var _a;
    return (_jsxs("span", __assign({ className: "icon-stack" }, { children: [_jsx(FontAwesomeIcon, { icon: "bars-sort" }), _jsx(StyleSortArrowIcon, { icon: (_a = props.icon) !== null && _a !== void 0 ? _a : 'circle-arrow-up', size: "2xs", visibility: props.icon ? 'visible' : 'hidden' })] })));
};
export var SortedAscendingIcon = function () { return _jsx(SortIcon, { icon: "circle-arrow-up" }); };
export var SortedDescendingIcon = function () { return _jsx(SortIcon, { icon: "circle-arrow-down" }); };
var Pagination = function (_a) {
    var page = _a.page, onPageChange = _a.onPageChange;
    var apiRef = useGridApiContext();
    var pageSize = useGridSelector(apiRef, gridPageSizeSelector);
    var visibleTopLevelRowCount = useGridSelector(apiRef, gridFilteredTopLevelRowCountSelector);
    var rootProps = useGridRootProps();
    var getPageCount = function () {
        var _a;
        var rowCount = (_a = rootProps.rowCount) !== null && _a !== void 0 ? _a : visibleTopLevelRowCount;
        if (pageSize > 0 && rowCount > 0) {
            return Math.ceil(rowCount / pageSize);
        }
        return 0;
    };
    if (!getPageCount())
        return null;
    return (_jsx(MuiPagination, { color: "primary", count: getPageCount(), page: page + 1, onChange: function (event, newPage) {
            onPageChange(event, newPage - 1);
        } }));
};
export var CustomPagination = function (props) {
    return _jsx(StyleGridPagination, __assign({ ActionsComponent: Pagination }, props));
};
export var NoRowsOverlay = function () {
    var t = useTranslation().t;
    return (_jsx(Stack, __assign({ className: "noDataIndication", height: "100%", alignItems: "center", justifyContent: "center" }, { children: t('noDataIndication') })));
};
