var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useCallback, useContext, useMemo, useState } from 'react';
export var HeadcountType;
(function (HeadcountType) {
    HeadcountType[HeadcountType["Reduction"] = 0] = "Reduction";
    HeadcountType[HeadcountType["Increase"] = 1] = "Increase";
})(HeadcountType || (HeadcountType = {}));
var DailyContext = createContext(undefined);
export function useDailyContext(key) {
    var _a = useContext(DailyContext), state = _a[0], updateState = _a[1], setState = _a[2];
    var getter = function () { return (key ? state === null || state === void 0 ? void 0 : state[key] : state); };
    var setter = useMemo(function () { return (key ? function (value) { return updateState(key, value); } : setState); }, [key, setState, updateState]);
    return [useMemo(getter, [key, state]), setter];
}
export var DailyProvider = function (props) {
    var _a = useState(), state = _a[0], setState = _a[1];
    var setter = useCallback(function (key, value) {
        setState(function (curr) {
            var _a;
            return curr && __assign(__assign({}, curr), (_a = {}, _a[key] = typeof value === 'function' ? value(curr === null || curr === void 0 ? void 0 : curr[key]) : value, _a));
        });
    }, []);
    var contextValue = useMemo(function () { return [state, setter, setState]; }, [setter, state]);
    return _jsx(DailyContext.Provider, __assign({}, props, { value: contextValue }));
};
