import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfirmDialog } from '../common';
import { useDeleteUserAPI, useUsersAPI } from './selectors';
export var DeleteUserDialog = function (_a) {
    var open = _a.open, user = _a.user, onClose = _a.onClose;
    var t = useTranslation(['manageUser', 'translation']).t;
    var _b = useUsersAPI(), fetchUsers = _b[1];
    var _c = useDeleteUserAPI(user === null || user === void 0 ? void 0 : user.id), deleteUserData = _c[0], deleteUser = _c[1], clearDeleteUserData = _c[2];
    useEffect(function () {
        if (open && deleteUserData.data) {
            onClose();
            fetchUsers();
            return clearDeleteUserData;
        }
    }, [deleteUserData.data, onClose, fetchUsers, clearDeleteUserData, open]);
    var handleDeleteUser = function () {
        deleteUser();
    };
    return (_jsx(ConfirmDialog, { open: open, onClose: onClose, title: t('deleteUserTitle', { username: user === null || user === void 0 ? void 0 : user.name }), message: t('translation:confirmation.yesDelete'), onConfirm: handleDeleteUser, loading: !!deleteUserData.requesting, errors: [deleteUserData.error] }));
};
