var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, TableHead, styled, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PlanType, StatusPermission } from '../../enum';
import { useUserPermissionsAPI } from '../app/selectors';
import { FormField, NativeNumberInput, Table, TableBody, TableCell, TableContainer, TableRow } from '../common';
import { useReportTimeline } from '../daily/selectors';
import { StyledFirstCell, StyledTableCell } from '../period/periodTable';
import { useReportAPI } from '../report/selectors';
import { PLAN_COLORS } from '../wizard/constants';
import { MONTH_OF_YEAR, TimelineType } from '../wizard/selectors';
import { BOLD_KEYS, NON_DEFERRABLE_BOTTOM_KEY, PERCENTAGE_KEYS, TRAINING_KEY, TRAINING_KEY_BOTTOM, TRAINING_KEY_EDITABLE, } from './constants';
import { TrainingColumn } from './enum';
export var TRAINING_STICKIES = ['10.75rem'];
export var QueueNameTableCell = styled(StyledFirstCell)(function (_a) {
    var theme = _a.theme;
    return ({
        borderLeft: 'unset !important',
        minWidth: theme.typography.pxToRem(300),
        top: 'initial',
        zIndex: 5,
    });
});
export var TrainingStyledTableCell = styled(StyledTableCell)(function () { return ({
    position: 'relative',
}); });
export var TrainingTableHead = function (_a) {
    var _b;
    var queueName = _a.queueName, bgColor = _a.bgColor, disableWeekDate = _a.disableWeekDate, _c = _a.addColumns, addColumns = _c === void 0 ? [] : _c;
    var reportData = useReportAPI()[0];
    var isWeekly = ((_b = reportData.data) === null || _b === void 0 ? void 0 : _b.planType) === PlanType.Weekly;
    var timelines = useReportTimeline.apply(void 0, __spreadArray([true, true, !disableWeekDate], addColumns, false));
    var totalColumn = MONTH_OF_YEAR + addColumns.length;
    return (_jsx(TableHead, { children: timelines.map(function (_a, rowIdx) {
            var titles = _a[0], type = _a[1];
            return (_jsxs(TableRow, { children: [!rowIdx && (_jsx(QueueNameTableCell, { stickySizes: TRAINING_STICKIES, value: !rowIdx ? queueName : null, rowSpan: 2, color: bgColor })), titles === null || titles === void 0 ? void 0 : titles.map(function (title, cellIdx, arr) { return (_jsx(TrainingStyledTableCell, { colSpan: type === TimelineType.Years ? totalColumn : 1, highlightBorder: !isWeekly &&
                            cellIdx !== arr.length - 1 &&
                            (type === TimelineType.Years || !((cellIdx + 1) % totalColumn)), value: title, color: bgColor }, cellIdx)); })] }, type));
        }) }));
};
var TrainingTableInputCell = function (_a) {
    var inputKey = _a.inputKey, trainingItem = _a.trainingItem, control = _a.control, setValue = _a.setValue, disabled = _a.disabled, isPercentage = _a.isPercentage;
    var getMinMaxValueInput = function (key) {
        var _a;
        var _b;
        var values = (_a = {},
            _a[TrainingColumn.TrainingLength] = { min: 0, max: 24 },
            _a[TrainingColumn.ForecastedGraduationRate] = { min: 1, max: 100 },
            _a[TrainingColumn.AdjustedAttritionRate] = { min: 0, max: 99.99 },
            _a);
        return (_b = values[key]) !== null && _b !== void 0 ? _b : { min: 0, max: 10000 };
    };
    var _b = getMinMaxValueInput(inputKey), min = _b.min, max = _b.max;
    return (_jsx(FormField, __assign({ disableHelperText: true, required: true, name: "trainings.".concat(trainingItem.month, ".").concat(inputKey), control: control }, { children: _jsx(NativeNumberInput, { disabled: disabled, min: min, max: max, setValue: setValue, suffix: isPercentage ? '%' : '', decimal: inputKey === TrainingColumn.AdjustedAttritionRate ? 2 : undefined, sx: { paddingRight: inputKey === TrainingColumn.AdjustedAttritionRate ? '1.25rem' : '' } }) })));
};
var TrainingTableDisplayCell = function (_a) {
    var inputKey = _a.inputKey, trainingItem = _a.trainingItem, isPercentage = _a.isPercentage, bold = _a.bold;
    var key = inputKey;
    var isNumberVal = typeof trainingItem[key] === 'number';
    var value = trainingItem[key];
    var getCellValue = function () {
        if (isPercentage && isNumberVal) {
            var percentage = Number(value) * 100;
            return "".concat(inputKey === TrainingColumn.ForecastAttritionRate ? percentage.toFixed(2) : percentage.toFixed(1), "%");
        }
        if (inputKey === TrainingColumn.ForecatedAnswerSpeed && isNumberVal) {
            return Math.round(Number(value));
        }
        return value;
    };
    return _jsx(Box, __assign({ sx: { fontWeight: bold ? 700 : 'normal' } }, { children: getCellValue() }));
};
var TrainingTableBody = function (_a) {
    var _b;
    var trainings = _a.trainings, bgColor = _a.bgColor, control = _a.control, setValue = _a.setValue, disabled = _a.disabled, isDeferrableQueue = _a.isDeferrableQueue;
    var theme = useTheme();
    var t = useTranslation('report', { keyPrefix: 'training' }).t;
    var reportData = useReportAPI()[0];
    var isWeekly = ((_b = reportData.data) === null || _b === void 0 ? void 0 : _b.planType) === PlanType.Weekly;
    var keys = TRAINING_KEY.filter(function (key) { return (isDeferrableQueue ? !NON_DEFERRABLE_BOTTOM_KEY.includes(key) : !!key); });
    return (_jsx(TableBody, { children: keys.map(function (key, index) {
            var isBottomRow = TRAINING_KEY_BOTTOM.includes(key);
            var isEditRow = TRAINING_KEY_EDITABLE.includes(key);
            var isPercentage = PERCENTAGE_KEYS.includes(key);
            var isBold = BOLD_KEYS.includes(key);
            return (_jsxs(TableRow, { children: [_jsx(TableCell, { stickySizes: TRAINING_STICKIES, color: isBottomRow ? bgColor[1] : theme.palette.background.default, component: "th", align: "left", value: t(key, { defaultValue: key }) }), trainings.map(function (cell, cellIdx) {
                        return (_jsx(TableCell, __assign({ color: isBottomRow || isEditRow ? bgColor[1] : theme.palette.common.white, highlightBorder: !isWeekly && !((cellIdx + 1) % 12) && trainings.length - 1 !== cellIdx }, { children: isEditRow ? (_jsx(TrainingTableInputCell, { disabled: disabled, inputKey: key, trainingItem: cell, setValue: setValue, control: control, isPercentage: isPercentage })) : (_jsx(TrainingTableDisplayCell, { inputKey: key, trainingItem: cell, isPercentage: isPercentage, bold: isBold })) }), "".concat(cell.id).concat(index).concat(cellIdx)));
                    })] }, key));
        }) }));
};
export var TrainingTable = function (_a) {
    var currentScenario = _a.currentScenario, trainings = _a.trainings, control = _a.control, setValue = _a.setValue, isDeferrableQueue = _a.isDeferrableQueue;
    var _b = currentScenario.colorIndex, colorIndex = _b === void 0 ? 0 : _b;
    var permission = useUserPermissionsAPI();
    return (_jsx(TableContainer, { children: _jsxs(Table, { children: [_jsx(TrainingTableHead, { queueName: currentScenario.isBaseline ? 'Baseline' : currentScenario.name, bgColor: PLAN_COLORS[colorIndex][0] }), _jsx(TrainingTableBody, { trainings: trainings, bgColor: PLAN_COLORS[colorIndex], control: control, setValue: setValue, disabled: (permission === null || permission === void 0 ? void 0 : permission.recruitmentPermission) !== StatusPermission.Edit, isDeferrableQueue: isDeferrableQueue })] }) }));
};
