var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import _ from 'lodash';
import 'moment-business-days';
import { Fragment, memo, useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PlanType, StatusPermission } from '../../../enum';
import { useUserPermissionsAPI } from '../../app/selectors';
import { FormField, NativeNumberInput, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, } from '../../common';
import { useFormlessContext } from '../../hooks';
import { WizardStep } from '../constants';
import { useBusinessDays, useDetailTimeline, usePeriodChunkSize, usePlanType, useWizardParams } from '../selectors';
import { useWizardSimpleContext } from '../useWizardSimpleContext';
var DEFAULT_ADD_COLUMNS = ['Total', 'Average'];
export var BaseTableHeader = function (_a) {
    var _b = _a.addColumns, addColumns = _b === void 0 ? DEFAULT_ADD_COLUMNS : _b, stickySizes = _a.stickySizes, simpleTitle = _a.simpleTitle, _c = _a.showCheckbox, showCheckbox = _c === void 0 ? false : _c, selectAll = _a.selectAll, handleSelectAllChange = _a.handleSelectAllChange;
    var t = useTranslation('wizard', { keyPrefix: 'bulkChange' }).t;
    var planType = usePlanType();
    var timelines = useDetailTimeline.apply(void 0, addColumns);
    var groupSize = 12 + addColumns.length;
    var showSelectAll = function (rowIdx) { return (planType === PlanType.Weekly && !!rowIdx) || planType === PlanType.Monthly; };
    timelines = useMemo(function () {
        if (!simpleTitle)
            return timelines;
        if (planType === PlanType.Weekly)
            return timelines.slice(0, 1);
        return timelines === null || timelines === void 0 ? void 0 : timelines.slice(0, 1).map(function (_a) {
            var arr = _a[0], type = _a[1];
            return [arr.map(function (text, idx) { return "Month ".concat(idx + 1); }), type];
        });
    }, [planType, simpleTitle, timelines]);
    return (_jsx(_Fragment, { children: timelines === null || timelines === void 0 ? void 0 : timelines.map(function (_a, rowIdx) {
            var titles = _a[0], type = _a[1];
            var Component = rowIdx ? TableBody : TableHead;
            return (_jsx(Component, { children: _jsxs(TableRow, { children: [stickySizes && (_jsx(TableCell, __assign({ greyColor: !!rowIdx, stickySizes: stickySizes, align: "left" }, { children: showCheckbox && showSelectAll(rowIdx) && (_jsx(FormControlLabel, { control: _jsx(Checkbox, { size: "small", checked: selectAll, onChange: handleSelectAllChange }), label: _jsx(Typography, __assign({ variant: "subtitle2" }, { children: t('selectAll') })) })) }))), titles === null || titles === void 0 ? void 0 : titles.map(function (title, cellIdx, arr) { return (_jsx(TableCell, __assign({ greyColor: !!rowIdx, sx: { whiteSpace: 'nowrap', minWidth: '5rem' }, highlightBorder: timelines.length === 1 && !((cellIdx + 1) % groupSize) && cellIdx !== arr.length - 1 }, { children: title }), cellIdx)); })] }) }, type));
        }) }));
};
var VolumeTableBody = function (_a) {
    var _b, _c;
    var name = _a.name, _d = _a.addColumns, addColumns = _d === void 0 ? DEFAULT_ADD_COLUMNS : _d, _e = _a.averagePrecision, averagePrecision = _e === void 0 ? 2 : _e, decimal = _a.decimal, _f = _a.min, min = _f === void 0 ? 1 : _f, _g = _a.max, max = _g === void 0 ? 9999999 : _g, calculateWorkingDate = _a.calculateWorkingDate, placeholder = _a.placeholder, disabled = _a.disabled, defaultValue = _a.defaultValue, suffix = _a.suffix;
    var plan = ((_b = useWizardSimpleContext()) !== null && _b !== void 0 ? _b : {}).plan;
    var businessDays = useBusinessDays();
    var _h = plan !== null && plan !== void 0 ? plan : {}, _j = _h.periodLength, periodLength = _j === void 0 ? 0 : _j, planType = _h.planType;
    var chunkSize = usePeriodChunkSize();
    var control = ((_c = useFormlessContext()) !== null && _c !== void 0 ? _c : {}).control;
    var tableValues = useWatch({ name: 'volumes', control: control });
    var summary = useMemo(function () {
        return _.chain(periodLength)
            .times()
            .map(function (i) { var _a, _b; return Number((_b = (_a = tableValues === null || tableValues === void 0 ? void 0 : tableValues[i]) === null || _a === void 0 ? void 0 : _a[name]) !== null && _b !== void 0 ? _b : 0); })
            .chunk(chunkSize)
            .map(function (arr) { return ({
            total: _.sum(arr),
            avg: _.chain(arr).mean().round(averagePrecision).value(),
        }); })
            .value();
    }, [periodLength, tableValues, name, chunkSize, averagePrecision]);
    var isIncludeTotal = addColumns.includes('Total');
    var isIncludeAverage = addColumns.includes('Average');
    return (_jsx(TableBody, { children: _jsx(TableRow, { children: summary.map(function (item, year) { return (_jsxs(Fragment, { children: [_.times(chunkSize).map(function (month) {
                        var _a;
                        return (_jsx(TableCell, __assign({ padding: "none", highlightBorder: planType === PlanType.Monthly &&
                                !((month + 1) % 12) &&
                                !isIncludeAverage &&
                                !isIncludeTotal &&
                                year + 1 < summary.length, sx: { minWidth: '6.4rem' } }, { children: _jsx(FormField, __assign({ disableHelperText: true, required: true, name: "volumes.".concat(month + year * chunkSize, ".").concat(name), defaultValue: defaultValue }, { children: _jsx(NativeNumberInput, { min: min, max: calculateWorkingDate ? businessDays === null || businessDays === void 0 ? void 0 : businessDays[month][1] : max, decimal: decimal, placeholder: calculateWorkingDate ? (_a = businessDays === null || businessDays === void 0 ? void 0 : businessDays[month][0]) === null || _a === void 0 ? void 0 : _a.toString() : placeholder, disabled: disabled, suffix: suffix }) })) }), month));
                    }), isIncludeTotal && (_jsx(TableCell, { padding: "none", greyColor: true, value: item.total, decimal: decimal, suffix: suffix })), isIncludeAverage && (_jsx(TableCell, { padding: "none", greyColor: true, value: item.avg, decimal: averagePrecision, highlightBorder: year + 1 < summary.length, suffix: suffix }))] }, year)); }) }) }));
};
export var BaseVolumeTable = memo(function (props) {
    var step = useWizardParams().step;
    var permission = useUserPermissionsAPI();
    var isDisabled = permission.capacityPermission !== StatusPermission.Edit || props.disabled;
    var addColumns = step === WizardStep.Review ? props.addColumns : [];
    return (_jsx(TableContainer, { children: _jsxs(Table, { children: [_jsx(BaseTableHeader, __assign({}, props, { addColumns: addColumns })), _jsx(VolumeTableBody, __assign({}, props, { disabled: isDisabled, addColumns: addColumns }))] }) }));
});
