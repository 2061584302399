var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { LoadingButton } from '@mui/lab';
import { Button, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useMultiSkillParams } from './selectors';
export var Actions = function (_a) {
    var _b = _a.disabled, disabled = _b === void 0 ? false : _b, loading = _a.loading, props = __rest(_a, ["disabled", "loading"]);
    var t = useTranslation(['multiSkill', 'translation']).t;
    var _c = useMultiSkillParams(), step = _c.step, basePlanId = _c.basePlanId;
    return (_jsxs(Stack, __assign({ ml: "auto", direction: "row", spacing: 4, justifyContent: "flex-end", mt: 4 }, props, { children: [_jsx(Button, __assign({ component: Link, to: "/report/".concat(basePlanId), variant: "outlined" }, { children: t('translation:action.cancel') })), _jsx(LoadingButton, __assign({ loading: loading, variant: "contained", type: "submit", disabled: disabled }, { children: !step ? t('translation:action.continue') : t('multiSkill:action') }))] })));
};
