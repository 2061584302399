export var TRAINER_ROLE = 'Trainers';
export var QA_ROLE = 'Quality Assurance';
export var TRAINER_INPUT = [
    { name: 'trainingLength', disabled: false, min: 1, max: 52 },
    { name: 'classSize', disabled: false, min: 1, max: 100 },
    { name: 'avgNewHire', disabled: true },
    { name: 'shrinkage', disabled: false, placeholder: '%', decimal: 1, min: 0, max: 99.9 },
    { name: 'numberTrainer', disabled: true },
    { name: 'numberReqAgent', disabled: true },
    { name: 'trainerRatio', disabled: true },
];
export var INTERACTION_INPUT = [
    { name: 'percentInteraction', disabled: false, placeholder: '%', decimal: 1, max: 100 },
    { name: 'numberInteraction', disabled: true },
    { name: 'numberMonitorReq', disabled: true },
    { name: 'interactionAHT', disabled: true },
    { name: 'evalCompletionTime', disabled: false, min: 0 },
    { name: 'totalEvalTime', disabled: true },
    { name: 'productionHour', disabled: true },
    { name: 'shrinkage', disabled: false, placeholder: '%', decimal: 1, min: 0, max: 99.9 },
    { name: 'staffEstimate', disabled: true },
    { name: 'numberReqAgent', disabled: true },
    { name: 'qaRatio', disabled: true },
];
export var MONITOR_INPUT = [
    { name: 'monitorPerAgent', disabled: false, min: 0 },
    { name: 'numberReqAgent', disabled: true },
    { name: 'numberMonitorReq', disabled: true },
    { name: 'interactionAHT', disabled: true },
    { name: 'evalCompletionTime', disabled: false, min: 0 },
    { name: 'totalEvalTime', disabled: true },
    { name: 'productionHour', disabled: true },
    { name: 'shrinkage', disabled: false, placeholder: '%', decimal: 1, min: 0, max: 99.9 },
    { name: 'staffEstimate', disabled: true },
    { name: 'qaRatio', disabled: true },
];
