var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Stack, Typography, styled } from '@mui/material';
import { Draggable } from 'react-beautiful-dnd';
import { WORK_TYPE_ICON } from '../../wizard/constants';
import { useQueueById } from '../selectors';
var SingleSkillContent = styled(Stack)(function (_a) {
    var theme = _a.theme;
    return ({
        backgroundColor: theme.palette.common.white,
        border: "1px solid ".concat(theme.palette.primary.main),
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(3),
        overflowY: 'auto',
        margin: theme.spacing(4, 4, 0),
    });
});
export var SingleSkillItem = function (_a) {
    var queueId = _a.queueId, index = _a.index, disabled = _a.disabled;
    var queue = useQueueById(queueId);
    return (_jsx(Draggable, __assign({ draggableId: "".concat(queueId), index: index, isDragDisabled: disabled }, { children: function (provided) { return (_jsx(SingleSkillContent, __assign({ flexDirection: "row", alignItems: "center", ref: provided.innerRef }, provided.draggableProps, provided.dragHandleProps, { children: queue.worktype && (_jsxs(_Fragment, { children: [_jsx(Typography, __assign({ variant: "h3", mr: 4, color: "primary.main" }, { children: _jsx(FontAwesomeIcon, { icon: WORK_TYPE_ICON[queue.worktype.id - 1] }) })), _jsxs(Box, __assign({ textAlign: "left" }, { children: [_jsx(Typography, __assign({ fontWeight: "bold" }, { children: queue.name })), _jsx(Typography, __assign({ variant: "body2" }, { children: queue.worktype.name }))] }))] })) }))); } })));
};
