import { useEffect } from 'react';
import { useReportAPI } from '../report/selectors';
import { useCopyMultiSkillAPI, useMultiSkillAPI } from './selectors';
export var MultiSkillLoader = function () {
    var _a = useCopyMultiSkillAPI(), fetchCopyMultiSkillData = _a[1], clearCopyMultiSkillData = _a[2];
    var _b = useReportAPI(), fetchReportPlanData = _b[1], clearReportPlanData = _b[2];
    var _c = useMultiSkillAPI(), fetchMultiSkillData = _c[1], clearMultiSkillData = _c[2];
    useEffect(function () {
        fetchReportPlanData();
        fetchMultiSkillData();
        fetchCopyMultiSkillData();
        return function () {
            clearMultiSkillData();
            clearReportPlanData();
            clearCopyMultiSkillData();
        };
    }, [
        clearCopyMultiSkillData,
        clearMultiSkillData,
        clearReportPlanData,
        fetchCopyMultiSkillData,
        fetchMultiSkillData,
        fetchReportPlanData,
    ]);
    return null;
};
