export var TrainingColumn;
(function (TrainingColumn) {
    TrainingColumn["ForecatedAnswerSpeed"] = "forecastedAnswerSpeed";
    TrainingColumn["ForecastedServiceLevel"] = "forecastedServiceLevel";
    TrainingColumn["ForecastedOccupancy"] = "forecastedOccupancy";
    TrainingColumn["NewHireClassStartDate"] = "newHireClassStartDate";
    TrainingColumn["TrainingLength"] = "trainingLength";
    TrainingColumn["ForecastedGraduationRate"] = "forecastedGraduationRate";
    TrainingColumn["AdjustedAttritionRate"] = "adjustedAttritionRate";
    TrainingColumn["ForecastAttritionRate"] = "forecastAttritionRate";
    TrainingColumn["ClassSize"] = "classSize";
    TrainingColumn["RecruitingLeadTime"] = "recruitingLeadTime";
    TrainingColumn["RosteredStaffPlanned"] = "rosteredStaffPlanned";
    TrainingColumn["ForecastedAgentAttrition"] = "forecastedAgentAttrition";
    TrainingColumn["TotalLeadTimeToGraduation"] = "totalLeadTimeToGraduation";
    TrainingColumn["TotalNewHireRequired"] = "totalNewHireRequired";
    TrainingColumn["RecruitingStartDate"] = "recruitingStartDate";
    TrainingColumn["TrainerRequire"] = "trainerRequire";
    TrainingColumn["StaffOverUnder"] = "staffOverUnder";
})(TrainingColumn || (TrainingColumn = {}));
