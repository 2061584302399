var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Paper, Stack, Typography, styled } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { StatusPermission } from '../../enum';
import { useUserPermissionsAPI } from '../app/selectors';
import { ErrorMessageBox, ExitBar, FallbackComponent } from '../common';
import { PageBody, SubTitle } from '../layout/styled';
import { useReportAPI } from '../report/selectors';
import { PLAN_COLORS } from '../wizard/constants';
import { AddSingleSkill } from './addSingleSkills';
import { AttentionDialog } from './addSingleSkills/attentionDialog';
import { MultiSkillLoader } from './loader';
import { MultiSkillGroup } from './multiskillGroup';
import { selectIsValidGroup, useGetCopiedGroups, useMultiSkillAPI, useMultiSkillGroups, useMultiSkillParams, } from './selectors';
import { MultiSkillStep } from './types';
var BasePanel = styled(Paper)(function (_a) {
    var theme = _a.theme;
    return ({
        position: 'relative',
        padding: theme.spacing(8),
        borderRadius: theme.shape.borderRadius,
        height: '100%',
        overflow: 'auto',
    });
});
var ComponentByStep = (_a = {},
    _a[MultiSkillStep.Add] = AddSingleSkill,
    _a[MultiSkillStep.Input] = MultiSkillGroup,
    _a);
export var MultiSkill = function () {
    var _a, _b, _c;
    var t = useTranslation('multiSkill').t;
    var step = useMultiSkillParams().step;
    var reportPlanData = useReportAPI()[0];
    var multiSkillData = useMultiSkillAPI()[0];
    var _d = useSearchParams(), searchParams = _d[0], setSearchParams = _d[1];
    var _e = useState([]), multiSkillGroups = _e[0], setMultiSkillGroups = _e[1];
    var scenario = (_a = reportPlanData.data) === null || _a === void 0 ? void 0 : _a.scenarios[0];
    var multiSkillGroupData = useMultiSkillGroups();
    var copyMultiSkillGroups = useGetCopiedGroups();
    var _f = useState(false), isInvalidGroup = _f[0], setIsInvalidGroup = _f[1];
    var Component = ComponentByStep[step];
    var permission = useUserPermissionsAPI();
    useEffect(function () {
        if (!multiSkillGroupData.length && copyMultiSkillGroups.length) {
            var updatedGroups = copyMultiSkillGroups
                .map(function (group) {
                var validGroup = selectIsValidGroup(group.children)(multiSkillData.data);
                return validGroup ? group : null;
            })
                .filter(Boolean);
            setMultiSkillGroups(updatedGroups);
            setIsInvalidGroup(copyMultiSkillGroups.length !== updatedGroups.length);
        }
        else {
            setMultiSkillGroups(multiSkillGroupData);
        }
    }, [multiSkillData.data, copyMultiSkillGroups, multiSkillGroupData]);
    var goToStep = useCallback(function (s) {
        searchParams.set('step', "".concat(s));
        setSearchParams(searchParams);
    }, [searchParams, setSearchParams]);
    return (_jsxs(_Fragment, { children: [_jsx(MultiSkillLoader, {}), _jsxs(FallbackComponent, __assign({ requesting: !!multiSkillData.requesting || !!reportPlanData.requesting }, { children: [_jsx(ErrorMessageBox, { errors: [reportPlanData.error, multiSkillData.error], sx: { margin: 3, marginBottom: 0 } }), _jsx(ExitBar, { title: (_b = reportPlanData.data) === null || _b === void 0 ? void 0 : _b.name }), !reportPlanData.error && (_jsxs(PageBody, { children: [_jsx(Typography, __assign({ variant: "h1" }, { children: t('title') })), _jsx(SubTitle, { children: t('description') }), _jsxs(BasePanel, { children: [_jsx(Stack, __assign({ bgcolor: PLAN_COLORS[(_c = scenario === null || scenario === void 0 ? void 0 : scenario.colorIndex) !== null && _c !== void 0 ? _c : 0][0], color: "common.white", padding: 4, borderRadius: 1, direction: "row", alignItems: "center", mb: 5 }, { children: _jsx(Typography, __assign({ variant: "subtitle1" }, { children: (scenario === null || scenario === void 0 ? void 0 : scenario.isBaseline) ? 'Baseline' : scenario === null || scenario === void 0 ? void 0 : scenario.name })) })), _jsx(Component, { multiSkillGroups: multiSkillGroups, setMultiSkillGroups: setMultiSkillGroups, goToStep: goToStep, permission: permission.capacityPermission === StatusPermission.Edit }), _jsx(AttentionDialog, { isInvalidGroup: isInvalidGroup })] })] }))] }))] }));
};
