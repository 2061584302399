import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfirmDialog } from '../common';
import { useCancelSimulation, useDeleteScenario, useReportAPI } from '../report/selectors';
import { useSummaryReportAPI } from './selectors';
export var DeleteScenarioDialog = function (_a) {
    var open = _a.open, scenario = _a.scenario, onClose = _a.onClose;
    var t = useTranslation(['report', 'translation']).t;
    var _b = useDeleteScenario(scenario === null || scenario === void 0 ? void 0 : scenario.id), deleteScenarioData = _b[0], deleteScenario = _b[1], clearDeleteScenario = _b[2];
    var _c = useCancelSimulation(scenario === null || scenario === void 0 ? void 0 : scenario.id, scenario === null || scenario === void 0 ? void 0 : scenario.basePlanId), cancelSimulation = _c[1];
    var _d = useReportAPI(), fetchData = _d[1];
    var _e = useSummaryReportAPI(), fetchSummaryData = _e[1];
    useEffect(function () {
        if (open && deleteScenarioData.data) {
            onClose();
            fetchData();
            fetchSummaryData();
        }
        return clearDeleteScenario;
    }, [deleteScenarioData.data, fetchSummaryData, onClose, fetchData, open, clearDeleteScenario]);
    var handleDeleteScenario = function () {
        deleteScenario();
        cancelSimulation({ isCancelAll: false });
    };
    return (_jsx(ConfirmDialog, { open: open, onClose: onClose, title: t('translation:confirmation.title'), message: t('translation:confirmation.yesDeleteMultiskill'), onConfirm: handleDeleteScenario, confirmButtonText: 'continue', loading: deleteScenarioData.requesting, errors: [deleteScenarioData.error] }));
};
