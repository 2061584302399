import { Tab as MuiTab, Tabs as MuiTabs, styled, tabsClasses } from '@mui/material';
export var Tabs = styled(MuiTabs)(function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = {},
        _b[".".concat(tabsClasses.flexContainer)] = {
            gap: theme.typography.pxToRem(15),
        },
        _b[".".concat(tabsClasses.indicator)] = {
            display: 'none',
        },
        _b);
});
export var Tab = styled(MuiTab)(function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = {
            fontSize: theme.typography.body1.fontSize,
            backgroundColor: theme.palette.divider,
            color: theme.palette.text.primary,
            borderTopLeftRadius: theme.typography.pxToRem(5),
            borderTopRightRadius: theme.typography.pxToRem(5),
            padding: theme.spacing(1, 4),
            minHeight: theme.typography.pxToRem(50),
            '&.Mui-selected': {
                pointerEvents: 'none',
                backgroundColor: theme.palette.background.default,
            }
        },
        _b["&:hover"] = {
            opacity: 0.75,
        },
        _b["&.Mui-disabled svg"] = {
            color: 'inherit',
        },
        _b);
});
