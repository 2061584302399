import { createContext, useContext } from 'react';
var DefaultAuthStrategy = /** @class */ (function () {
    function DefaultAuthStrategy() {
    }
    DefaultAuthStrategy.prototype.getRequestOptions = function () {
        return {
            withCredentials: true,
        };
    };
    DefaultAuthStrategy.prototype.onUnauthorized = function () {
        this.logout();
    };
    DefaultAuthStrategy.prototype.logout = function () {
        var _a;
        window.isLogout = true;
        window.open((_a = process.env.REACT_APP_LOGOUT_URL) !== null && _a !== void 0 ? _a : '/api/auth/logout', '_self');
    };
    return DefaultAuthStrategy;
}());
export { DefaultAuthStrategy };
export var AuthContext = createContext(new DefaultAuthStrategy());
export var useAuthStrategy = function () {
    return useContext(AuthContext);
};
