var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { BasePanel } from '../common/basePanel';
import { BaseVolumeTable } from '../common/baseTable';
import { QueuePanel } from '../common/queuePanel';
import { StepContainer, StepPanel } from '../common/stepContainer';
import { LearningCurveAccordion } from '../learningCurve';
import { QueueStatusType } from '../queue/types';
import { usePlanType } from '../selectors';
export var HandleTimeContent = function () {
    return _jsx(BaseVolumeTable, { name: "handleTime", addColumns: ['Average'], max: 99999 });
};
export var HandleTimeStep = function () {
    var t = useTranslation('wizard', { keyPrefix: 'handleTime' }).t;
    var planType = usePlanType();
    return (_jsx(StepContainer, __assign({ leftContent: _jsx(QueuePanel, {}) }, { children: _jsxs(StepPanel, { children: [_jsx(BasePanel, __assign({ title: t('title'), description: t('message', { planType: planType }), statusType: QueueStatusType.HandleTime, needSaveBeforeCopy: true }, { children: _jsx(HandleTimeContent, {}) })), _jsx(LearningCurveAccordion, {})] }) })));
};
