var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createSelector } from '@reduxjs/toolkit';
import _ from 'lodash';
import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { PlanType } from '../../enum';
import { useAPI } from '../../state';
import { QueueType } from '../multiskill/types';
import { ActionType } from '../report/constants';
import { useReportDownload } from '../report/hooks';
import { useReportAPI, useReportPlanType } from '../report/selectors';
import { PLAN_COLORS } from '../wizard/constants';
import { createTimelineSelector, selectTimelineOptions } from '../wizard/selectors';
import { useDailyContext } from './context';
var WAP_QUEUES_API = '/api/queue/wap/{basePlanId}';
var DAILY_REPORT_DETAIL_API = '/api/report/daily';
var PRINT_API = '/api/report/daily/print';
var EXPORT_API = '/api/report/daily/export';
var EXPORT_FILE_NAME = 'Forecast_Daily';
var selectDailyData = function (data) { return data; };
var selectDailySummary = createSelector(selectDailyData, function (data) { return data === null || data === void 0 ? void 0 : data.summary; });
export var selectTimes = createSelector(selectDailyData, function (data) { var _a, _b; return (_a = data === null || data === void 0 ? void 0 : data.times) !== null && _a !== void 0 ? _a : (data && ((_b = Object.values(data)[0]) === null || _b === void 0 ? void 0 : _b.times)); });
var selectQueueNames = createSelector(selectDailyData, function (data) {
    return !data || 'times' in data ? [] : Object.values(data).map(function (_a) {
        var queueName = _a.queueName, queueType = _a.queueType;
        return ({ queueName: queueName, queueType: queueType });
    });
});
var transformData = function (data) {
    if (!data || 'times' in data)
        return data === null || data === void 0 ? void 0 : data.detail;
    return Object.keys(data).reduce(function (acc, queueId) {
        var _a = data[queueId], queueName = _a.queueName, queueType = _a.queueType, detail = _a.detail;
        Object.keys(detail).forEach(function (day) {
            acc[day] = acc[day] || {};
            Object.keys(detail[day]).forEach(function (time) {
                acc[day][time] = acc[day][time] || [];
                acc[day][time].push({
                    queueName: queueName,
                    queueType: queueType,
                    detail: detail[day][time],
                });
            });
        });
        return acc;
    }, {});
};
export var selectDailyDataTransformed = createSelector(selectDailyData, function (data) { return transformData(data); });
var selectQueues = function (queues) { return queues; };
var selectQueueMap = createSelector(selectQueues, function (queues) { return _.keyBy(queues, 'id'); });
var selectQueuesGroup = createSelector(selectQueues, function (queues) {
    return _.chain(queues)
        .groupBy('planId')
        .mapValues(function (children) { return ({ queues: children, byName: _.keyBy(children, 'name') }); })
        .value();
});
var createUniqueQueuesSelector = function (uniqueId) {
    return createSelector(selectQueuesGroup, selectQueues, function (group, queues) {
        var _a;
        if (!(queues === null || queues === void 0 ? void 0 : queues.length))
            return [];
        var planId = uniqueId ? uniqueId === null || uniqueId === void 0 ? void 0 : uniqueId.split('-')[0] : queues[0].planId;
        var queueList = (_a = group[planId]) === null || _a === void 0 ? void 0 : _a.queues;
        return (uniqueId === null || uniqueId === void 0 ? void 0 : uniqueId.includes('-')) ? queueList : queueList.filter(function (q) { return q.queueType === QueueType.SingleSkill; });
    });
};
var selectMultiskillPlanIds = createSelector(selectQueuesGroup, function (group) {
    return Object.keys(group)
        .filter(function (planId) { return group[planId].queues.some(function (queue) { return queue.queueType === QueueType.Multiskill; }); })
        .map(Number);
});
var createQueueSelector = function (id) {
    if (id === void 0) { id = 0; }
    return createSelector(selectQueueMap, function (map) { return map[id]; });
};
var createNextQueueSelector = function (id, planId) {
    if (id === void 0) { id = 0; }
    if (planId === void 0) { planId = 0; }
    return createSelector(selectQueuesGroup, selectQueueMap, function (group, map) { var _a, _b, _c; return (_c = (_a = group[planId]) === null || _a === void 0 ? void 0 : _a.byName[(_b = map[id]) === null || _b === void 0 ? void 0 : _b.name]) === null || _c === void 0 ? void 0 : _c.id; });
};
var selectScenarios = function (plan) { return plan === null || plan === void 0 ? void 0 : plan.scenarios; };
var selectScenarioMap = createSelector(selectScenarios, function (scenario) { return _.keyBy(scenario, 'id'); });
var createScenarioSelector = function (id) {
    if (id === void 0) { id = 0; }
    return createSelector(selectScenarioMap, function (map) { return map[id]; });
};
var createScenarioWithMultiskillSelector = function (ids) {
    return createSelector(selectScenarios, function (scenarios) {
        return scenarios === null || scenarios === void 0 ? void 0 : scenarios.flatMap(function (plan) {
            var baseScenario = __assign(__assign({}, plan), { uniqueId: "".concat(plan.id), name: plan.isBaseline ? 'Baseline' : plan.name });
            return ids.includes(plan.id)
                ? [
                    baseScenario,
                    __assign(__assign({}, baseScenario), { name: plan.isBaseline ? 'Baseline Multiskill' : "".concat(plan.name, " Multiskill"), uniqueId: "".concat(plan.id, "-multiskill") }),
                ]
                : [baseScenario];
        });
    });
};
export var useWAPQueuesAPI = function () {
    var basePlanId = useParams().basePlanId;
    return useAPI(WAP_QUEUES_API, { basePlanId: basePlanId });
};
export var useUniqueQueues = function (uniqueId) {
    var data = useWAPQueuesAPI()[0].data;
    var selectUniqueQueues = useMemo(function () { return createUniqueQueuesSelector(uniqueId); }, [uniqueId]);
    return selectUniqueQueues(data);
};
export var useQueueById = function (id) {
    var data = useWAPQueuesAPI()[0].data;
    var selectQueue = useMemo(function () { return createQueueSelector(id); }, [id]);
    return selectQueue(data);
};
export var useNextQueueId = function (queueId, uniqueId) {
    var data = useWAPQueuesAPI()[0].data;
    var selectNextQueueId = useMemo(function () { return createNextQueueSelector(queueId, Number(uniqueId === null || uniqueId === void 0 ? void 0 : uniqueId.split('-')[0])); }, [uniqueId, queueId]);
    return selectNextQueueId(data);
};
export var usePlanColor = function () {
    var _a;
    var data = useReportAPI()[0].data;
    var uniqueId = useDailyContext('uniqueId')[0];
    var selectScenario = useMemo(function () { return createScenarioSelector(Number(uniqueId === null || uniqueId === void 0 ? void 0 : uniqueId.split('-')[0])); }, [uniqueId]);
    var plan = selectScenario(data);
    return PLAN_COLORS[(_a = plan === null || plan === void 0 ? void 0 : plan.colorIndex) !== null && _a !== void 0 ? _a : 0];
};
export var useDailyReportPlan = function () {
    var data = useReportAPI()[0].data;
    var WAPQueuesData = useWAPQueuesAPI()[0].data;
    var ids = selectMultiskillPlanIds(WAPQueuesData);
    var selectScenarioWithMultiskill = useMemo(function () { return createScenarioWithMultiskillSelector(ids); }, [ids]);
    return selectScenarioWithMultiskill(data);
};
export var useReportTimeline = function (includeYear, includeWeek, includeWeekDate) {
    if (includeYear === void 0) { includeYear = false; }
    if (includeWeek === void 0) { includeWeek = false; }
    if (includeWeekDate === void 0) { includeWeekDate = false; }
    var addColumns = [];
    for (var _i = 3; _i < arguments.length; _i++) {
        addColumns[_i - 3] = arguments[_i];
    }
    var data = useReportAPI()[0].data;
    var selectTimelines = useMemo(function () { return createTimelineSelector(includeYear, includeWeek, includeWeekDate, addColumns); }, [includeYear, includeWeek, includeWeekDate, addColumns]);
    return selectTimelines(data);
};
export var useReportTimelineOptions = function () {
    var data = useReportAPI()[0].data;
    return selectTimelineOptions(data);
};
export var useDailyAPI = function () {
    var state = useDailyContext()[0];
    var _a = useAPI(DAILY_REPORT_DETAIL_API), data = _a[0], fetchData = _a[1], clearData = _a[2];
    var fetchDataWrapper = useCallback(function () {
        if (state) {
            var dataType = state.uniqueId.includes('-') ? QueueType.Multiskill : QueueType.SingleSkill;
            return fetchData(undefined, __assign(__assign({}, _.pick(state, 'queueId', 'headcount', 'month')), { dataType: dataType }));
        }
    }, [fetchData, state]);
    return [data, fetchDataWrapper, clearData];
};
export var useDailySummaryData = function () {
    var data = useDailyAPI()[0].data;
    return selectDailySummary(data);
};
export var useGetQueueNames = function () {
    var data = useDailyAPI()[0].data;
    return selectQueueNames(data);
};
export var useExportAPI = function () {
    var state = useDailyContext()[0];
    var params = useMemo(function () {
        var _a = state !== null && state !== void 0 ? state : {}, headcount = _a.headcount, uniqueId = _a.uniqueId;
        return {
            headcount: headcount,
            planId: uniqueId === null || uniqueId === void 0 ? void 0 : uniqueId.split('-')[0],
            dataType: (uniqueId === null || uniqueId === void 0 ? void 0 : uniqueId.includes('-')) ? QueueType.Multiskill : QueueType.SingleSkill,
        };
    }, [state]);
    return useReportDownload(ActionType.Export, EXPORT_API, "".concat(EXPORT_FILE_NAME, ".xlsx"), {
        disabled: !state,
        params: params,
    });
};
export var usePrintAPI = function () {
    var _a;
    var state = useDailyContext()[0];
    var planType = useReportPlanType();
    var params = useMemo(function () { return _.pick(state, 'queueId', 'headcount'); }, [state]);
    return useReportDownload(ActionType.Print, PRINT_API, "".concat(EXPORT_FILE_NAME, ".pdf"), {
        disabled: !state || planType !== PlanType.Monthly || ((_a = state === null || state === void 0 ? void 0 : state.uniqueId) === null || _a === void 0 ? void 0 : _a.includes('-')),
        params: params,
    });
};
