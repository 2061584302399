var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FallbackComponent } from '../fallback';
import { ErrorMessageBox } from '../messageBox';
export var ConfirmDialog = function (props) {
    var _a, _b, _c, _d;
    var t = useTranslation().t;
    return (_jsxs(Dialog, __assign({ open: props.open, onClose: props.onClose }, { children: [props.title && _jsx(DialogTitle, __assign({ variant: "h2" }, { children: props.title })), _jsx(DialogContent, { children: _jsxs(FallbackComponent, __assign({ requesting: props.loading, overlay: true }, { children: [_jsx(ErrorMessageBox, { errors: (_a = props.errors) !== null && _a !== void 0 ? _a : [] }), _jsx(DialogContentText, __assign({ component: "div" }, { children: props.message }))] })) }), _jsxs(DialogActions, { children: [_jsx(Button, __assign({ variant: "outlined", onClick: props.onClose }, { children: t("action.".concat((_b = props.cancelButtonText) !== null && _b !== void 0 ? _b : 'cancel')) })), props.onSkipSaveConfirm && (_jsx(Button, __assign({ variant: "outlined", onClick: props.onSkipSaveConfirm }, { children: t("action.".concat((_c = props.skipSaveButtonText) !== null && _c !== void 0 ? _c : 'skipSave')) }))), props.onConfirm && (_jsx(Button, __assign({ variant: "contained", onClick: props.onConfirm }, { children: t("action.".concat((_d = props.confirmButtonText) !== null && _d !== void 0 ? _d : 'yes')) })))] })] })));
};
