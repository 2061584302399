var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfirmDialog } from '../common';
import { useReportAPI, useSwitchScenario } from '../report/selectors';
import { useSummaryReportAPI } from './selectors';
import { DialogMode } from './types';
export var SwitchScenarioDialog = function (_a) {
    var open = _a.open, scenario = _a.scenario, onClose = _a.onClose, handleOpenDialog = _a.handleOpenDialog;
    var t = useTranslation(['report', 'translation']).t;
    var _b = useSwitchScenario(scenario === null || scenario === void 0 ? void 0 : scenario.id), switchScenarioData = _b[0], switchScenario = _b[1], clearSwitchScenario = _b[2];
    var _c = useReportAPI(), reportData = _c[0], fetchData = _c[1];
    var _d = useSummaryReportAPI(), fetchSummaryData = _d[1];
    useEffect(function () {
        var _a;
        if (open && switchScenarioData.data && reportData.data) {
            var oldBaseline = reportData.data.scenarios[0];
            var switchedBaseline = __assign(__assign({}, oldBaseline), { isBaseline: false, name: (_a = scenario === null || scenario === void 0 ? void 0 : scenario.name) !== null && _a !== void 0 ? _a : '' });
            onClose();
            fetchData();
            fetchSummaryData();
            handleOpenDialog(DialogMode.RenameAfterSwitch, switchedBaseline);
        }
        return clearSwitchScenario;
    }, [
        switchScenarioData.data,
        onClose,
        fetchData,
        fetchSummaryData,
        open,
        clearSwitchScenario,
        handleOpenDialog,
        scenario,
        reportData.data,
    ]);
    var handleSwitchScenario = function () {
        switchScenario();
    };
    return (_jsx(ConfirmDialog, { open: open, onClose: onClose, title: t('report:annual.switchScenarioConfirm'), onConfirm: handleSwitchScenario, loading: switchScenarioData.requesting, errors: [switchScenarioData.error] }));
};
