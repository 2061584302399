import { PlanType } from '../../enum';
export var PLAN_TYPE = [
    { value: PlanType.Monthly, name: 'Monthly' },
    { value: PlanType.Weekly, name: 'Weekly' },
];
export var ImportDataType;
(function (ImportDataType) {
    ImportDataType[ImportDataType["None"] = 0] = "None";
    ImportDataType[ImportDataType["Excel"] = 1] = "Excel";
    ImportDataType[ImportDataType["Forecast"] = 2] = "Forecast";
})(ImportDataType || (ImportDataType = {}));
export var IMPORT_DATA_TYPE = [
    { id: ImportDataType.None, name: 'None' },
    { id: ImportDataType.Excel, name: 'Excel template' },
    { id: ImportDataType.Forecast, name: 'Genesys Forecast' },
];
export var PERIOD_LENGTH = [
    { value: 12, name: '12 months' },
    { value: 24, name: '24 months' },
    { value: 36, name: '36 months' },
];
export var MAX_FILE_SIZE = 10485760; // 10MB
