import _ from 'lodash';
import { Currency } from '../../enum';
import { PLAN_COLORS } from '../wizard/constants';
export var generateColorByIndex = function (scenarios) {
    if (scenarios === void 0) { scenarios = []; }
    return _.map(scenarios, function (_a) {
        var colorIndex = _a.colorIndex;
        return PLAN_COLORS[colorIndex][0];
    });
};
export var hoverTemplate = function (name, type, layoutType, currencyPlan) {
    if (name === void 0) { name = ''; }
    if (type === void 0) { type = 'requiredAgents'; }
    if (layoutType === void 0) { layoutType = 'bar'; }
    if (currencyPlan === void 0) { currencyPlan = Currency.Dollar; }
    var decimal = type === 'avgCostPerWork' ? 2 : 0;
    var key = layoutType === 'scatter' ? 'text' : 'x';
    var currency = type === 'avgCostPerWork' || type === 'totalBudgets' ? currencyPlan : '';
    return "<b style=\"color:#fff\">".concat(name, "</b><br><span style=\"color:#fff\">%{").concat(key, "}: ").concat(currency, "%{y:,.").concat(decimal, "f}</span><extra></extra>");
};
export var textTemplate = function (type, currencyPlan) {
    if (type === void 0) { type = 'requiredAgents'; }
    if (currencyPlan === void 0) { currencyPlan = Currency.Dollar; }
    var format = type === 'avgCostPerWork' ? '%{value:,.2f}' : '%{value:0,.3~s}';
    var currency = type === 'avgCostPerWork' || type === 'totalBudgets' ? currencyPlan : '';
    return "<span>".concat(currency).concat(format, "</span>");
};
