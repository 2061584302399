var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { DatePicker as DatePickerRaw, } from '@mui/x-date-pickers';
import moment from 'moment';
import { useMemo } from 'react';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
var DEFAULT_MAX_DATE = moment.utc().endOf('year').add(100, 'year');
var DEFAULT_MIN_DATE = moment.utc().startOf('year').subtract(100, 'year');
export var DatePicker = function (_a) {
    var _b, _c;
    var _d = _a.name, name = _d === void 0 ? '' : _d, control = _a.control, error = _a.error, helperText = _a.helperText, props = __rest(_a, ["name", "control", "error", "helperText"]);
    var t = useTranslation('translation', { keyPrefix: 'form' }).t;
    var _e = useController({
        name: "".concat(name, "Error"),
        control: control,
    }), field = _e.field, fieldState = _e.fieldState;
    var maxDate = ((_b = props.maxDate) === null || _b === void 0 ? void 0 : _b.isValid()) ? props.maxDate : DEFAULT_MAX_DATE;
    var minDate = ((_c = props.minDate) === null || _c === void 0 ? void 0 : _c.isValid()) ? props.minDate : DEFAULT_MIN_DATE;
    var extraError = useMemo(function () {
        if (!fieldState.invalid)
            return null;
        switch (field.value) {
            case 'disableFuture':
                return t('maxDate', { date: 'today' });
            case 'maxDate':
                return t('maxDate', { date: maxDate.format('L') });
            case 'minDate':
                return t('minDate', { date: minDate.format('L') });
            default:
                return t('invalid');
        }
    }, [field.value, fieldState.invalid, maxDate, minDate, t]);
    var handleError = function (newError) {
        field.onChange(newError !== null && newError !== void 0 ? newError : undefined);
    };
    return (_jsx(DatePickerRaw, __assign({}, props, { onError: handleError, slotProps: {
            textField: {
                error: !!error || !!field.value,
                helperText: helperText !== null && helperText !== void 0 ? helperText : extraError,
            },
        }, maxDate: maxDate, minDate: minDate })));
};
