var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormControlLabel, Grid, InputAdornment, Radio, RadioGroup, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormField, NumberInput } from '../../common';
import { AttritionContent } from '../../wizard/attrition';
import { OvertimeCostContent } from '../../wizard/financial/overtimeCost';
import { Completed } from '../../wizard/queue/types';
import { ShrinkageContent } from '../../wizard/shrinkage';
import { RoleTable } from '../../wizard/supportStaff/roleTable';
import { useQueueById } from '../selectors';
import { MultiskillType } from '../types';
import { MultiskillWarningDialog } from './multiskillWarningDialog';
var MaxOccupancyThreshold = function (_a) {
    var isDisabledWAP = _a.isDisabledWAP, disabled = _a.disabled;
    var t = useTranslation(['wizard', 'multiSkill']).t;
    var _b = useState(false), openWarning = _b[0], setOpenWarning = _b[1];
    var handleTurnOnWAP = function () {
        setOpenWarning(true);
    };
    return (_jsxs(Grid, __assign({ container: true, direction: "row", gap: 4, wrap: "nowrap" }, { children: [_jsx(Grid, __assign({ item: true, xs: 4 }, { children: _jsx(FormField, __assign({ name: 'maxOccupancy', label: t('workVolume.maxOccupancy') }, { children: _jsx(NumberInput, { min: -1, max: 99.9999, decimal: 4, InputProps: {
                            endAdornment: _jsx(InputAdornment, __assign({ position: "end" }, { children: "%" })),
                        }, disabled: disabled }) })) })), _jsx(Grid, __assign({ item: true, xs: 4 }, { children: _jsx(FormField, __assign({ label: t('multiSkill:edit.chooseMultiSkillingMix'), name: 'multiskillType' }, { children: _jsxs(RadioGroup, __assign({ row: true }, { children: [_jsx(FormControlLabel, { value: MultiskillType.All, control: _jsx(Radio, {}), label: t('multiSkill:edit.oneHundredPercent'), disabled: disabled }), _jsx(FormControlLabel, { value: MultiskillType.Optimized, control: _jsx(Radio, {}), label: t('multiSkill:edit.fullyOptimized'), disabled: disabled })] })) })) })), _jsx(Grid, __assign({ item: true, xs: 4 }, { children: _jsx(FormField, __assign({ label: t('multiSkill:edit.includeWorkArrivalPattern'), name: 'isUseWAP', disabled: isDisabledWAP }, { children: _jsxs(RadioGroup, __assign({ row: true, "data-testid": "radioWapGroup" }, { children: [_jsx(FormControlLabel, { value: Completed.No, control: _jsx(Radio, {}), label: t('multiSkill:edit.no'), disabled: isDisabledWAP }), _jsx(FormControlLabel, { value: Completed.Yes, control: _jsx(Radio, {}), label: t('multiSkill:edit.yes'), disabled: isDisabledWAP, onChange: function () { return handleTurnOnWAP(); } })] })) })) })), _jsx(MultiskillWarningDialog, { openWarning: openWarning, setOpenWarning: setOpenWarning })] })));
};
export var MultiSkillGroupDetail = React.memo(function (_a) {
    var activeData = _a.activeData, permission = _a.permission;
    var t = useTranslation(['wizard', 'multiSkill']).t;
    var queue = useQueueById(activeData === null || activeData === void 0 ? void 0 : activeData.children[0]);
    return (_jsxs(Stack, __assign({ spacing: 4 }, { children: [_jsx(MaxOccupancyThreshold, { isDisabledWAP: !(queue === null || queue === void 0 ? void 0 : queue.enableUseWAP) || !permission, disabled: !permission }), _jsx(Typography, { children: t('multiSkill:edit.groupDescription') }), _jsx(Typography, __assign({ mb: 4, variant: "subtitle2" }, { children: t('shrinkage.name') })), _jsx(ShrinkageContent, {}), _jsx(Typography, __assign({ variant: "subtitle2" }, { children: t('attrition.fullName') })), _jsx(AttritionContent, {}), _jsx(Typography, __assign({ variant: "subtitle2" }, { children: t('supportStaff.supportStaffAndSalaries') })), _jsx(RoleTable, {}), _jsx(Typography, __assign({ variant: "subtitle2" }, { children: t('financial.overtimeCosts') })), _jsx(OvertimeCostContent, {})] })));
});
