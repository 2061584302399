import isEmail from 'validator/lib/isEmail';
import isMobilePhone from 'validator/lib/isMobilePhone';
import * as yup from 'yup';
var SPECIAL_CHARACTER_REGEX = /^[^\\/*?:[\]]*$/;
var QUOTES_NOT_ALLOWED_REGEX = /^'|'$|^"|"$/g;
yup.addMethod(yup.string, 'specialCharacter', function specialCharacter(message) {
    if (message === void 0) { message = 'specialCharacter'; }
    return this.matches(SPECIAL_CHARACTER_REGEX, { message: message });
});
yup.addMethod(yup.string, 'quotesNotAllowed', function quotesNotAllowed(message) {
    if (message === void 0) { message = 'quotesNotAllowed'; }
    return this.test('quotesNotAllowed', message, function (value) { return !(value === null || value === void 0 ? void 0 : value.match(QUOTES_NOT_ALLOWED_REGEX)); });
});
yup.setLocale({
    mixed: {
        required: 'required',
        notType: 'invalid',
    },
    string: {
        email: 'invalid',
    },
});
yup.addMethod(yup.string, 'email', function (message) {
    if (message === void 0) { message = 'invalid'; }
    return this.test({
        message: message,
        test: function (value) { return !value || isEmail(value); },
    });
});
yup.addMethod(yup.string, 'phone', function (message) {
    if (message === void 0) { message = 'invalid'; }
    return this.test({
        message: message,
        test: function (value) { return !value || isMobilePhone(value, 'any', { strictMode: true }); },
    });
});
var formatError = yup.ValidationError.formatError;
yup.ValidationError.formatError = function (message, params) {
    if (!params.label) {
        params.label = 'Value';
    }
    return formatError.call(this, message, params);
};
export default yup;
