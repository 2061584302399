var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Stack, Typography, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FormField, NumberInput } from '../../../common';
var StyledLabel = styled(Typography)(function (_a) {
    var theme = _a.theme;
    return ({
        width: theme.typography.pxToRem(280),
        textAlign: 'right',
    });
});
var StyledInput = styled(NumberInput, {
    shouldForwardProp: function (prop) { return prop !== 'isBold'; },
})(function (_a) {
    var isBold = _a.isBold;
    return ({
        '& input': {
            textAlign: 'center',
            fontWeight: isBold ? 'bold' : 'normal',
        },
    });
});
export var CalculatorForm = function (_a) {
    var control = _a.control, data = _a.data, onSubmit = _a.onSubmit;
    var t = useTranslation('wizard', { keyPrefix: 'calculator' }).t;
    return (_jsxs(Stack, __assign({ direction: "column", alignItems: "center", gap: 1.5, component: "form", onSubmit: onSubmit }, { children: [data.map(function (_a, index, _b) {
                var name = _a.name, disabled = _a.disabled, decimal = _a.decimal, max = _a.max, min = _a.min, placeholder = _a.placeholder;
                var length = _b.length;
                return (_jsxs(Stack, __assign({ alignItems: "center", direction: "row", gap: 2 }, { children: [_jsx(StyledLabel, __assign({ fontWeight: index === length - 1 ? 'bold' : 'normal' }, { children: t("".concat(name), { defaultValue: name }) })), _jsx(FormField, __assign({ name: name, control: control, fontWeight: "bold" }, { children: _jsx(StyledInput, { placeholder: placeholder, min: min, max: max, decimal: decimal, disabled: disabled, isBold: index === length - 1 }) }))] }), name));
            }), _jsx(Button, __assign({ variant: "contained", type: "submit" }, { children: t("calculate") }))] })));
};
