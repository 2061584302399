import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
    backend: {
        loadPath: "/assets/locales/{{lng}}/{{ns}}.json?v=".concat(process.env.REACT_APP_VERSION),
    },
    fallbackLng: 'en',
    supportedLngs: ['en'],
    interpolation: {
        escapeValue: false,
    },
});
