var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AccordionDetails, AccordionSummary, Accordion as MuiAccordion, Stack, Typography, accordionSummaryClasses, styled, } from '@mui/material';
var StyledAccordionSummary = styled(AccordionSummary)(function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = {
            flexFlow: 'row-reverse',
            backgroundColor: theme.palette.divider,
            gap: theme.spacing(4),
            padding: theme.spacing(0, 4)
        },
        _b[".".concat(accordionSummaryClasses.expandIconWrapper, ".Mui-expanded")] = {
            transform: 'rotate(90deg)',
        },
        _b);
});
export var Accordion = function (props) {
    return (_jsxs(MuiAccordion, __assign({ disableGutters: true, elevation: 0, sx: { mt: 6 } }, props, { children: [_jsx(StyledAccordionSummary, __assign({ expandIcon: _jsx(FontAwesomeIcon, { icon: "chevron-right" }) }, { children: _jsx(Typography, __assign({ variant: "subtitle1" }, { children: props.title })) })), _jsx(AccordionDetails, __assign({ sx: { p: 0, pt: 4 } }, { children: _jsx(Stack, __assign({ gap: 4 }, { children: props.children })) }))] })));
};
