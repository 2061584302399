var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Box, styled } from '@mui/material';
export var PrintContainer = styled(Box)(function (_a) {
    var theme = _a.theme;
    return ({
        width: '100vw',
        minHeight: '100vh',
        backgroundColor: theme.palette.common.white,
    });
});
export var PageHead = function (props) {
    return (_jsx("thead", { children: _jsx("tr", { children: _jsx("td", __assign({ align: "center" }, { children: props.children })) }) }));
};
export var PageBody = function (props) {
    return (_jsx("tbody", { children: _jsx("tr", { children: _jsx("td", { children: props.children }) }) }));
};
