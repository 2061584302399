var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Grid, styled } from '@mui/material';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { usePlatformContext } from '../app/selectors';
import { Chart, ErrorMessageBox } from '../common';
import { BAR_BUTTONS, layoutDefault } from '../common/chart/config';
import { PdfType } from '../report/constants';
import { usePrintChartAPI } from '../report/selectors';
import { useChartPerRow, useCostPerWorkActivity, useFinancialCharts, useResourcingCharts, useSummaryReportAPI, } from './selectors';
import { handleLayout } from './util';
var StyledGrid = styled(Grid)(function () { return ({
    pageBreakAfter: 'always',
    ':last-child': {
        pageBreakAfter: 'avoid',
    },
}); });
var AnnualGraphical = function (props) {
    var t = useTranslation('report', { keyPrefix: 'annual.chart' }).t;
    var summaryData = useSummaryReportAPI()[0];
    var printData = usePrintChartAPI(PdfType.AnnualChart)[0];
    var _a = useChartPerRow(), year = _a.year, _b = _a.scenarios, scenarios = _b === void 0 ? [] : _b, chartPerRow = _a.chartPerRow;
    var layout = handleLayout(scenarios, year).layout;
    var context = usePlatformContext();
    return (_jsxs(_Fragment, { children: [_jsx(ErrorMessageBox, { errors: [summaryData.error, printData.error] }), _jsxs(Grid, __assign({ container: true, spacing: 4 }, { children: [summaryData.data &&
                        props.charts.map(function (_a) {
                            var data = _a.data, key = _a.key, legends = _a.legends, middleLegends = _a.middleLegends, yTickPrefix = _a.yTickPrefix, _b = _a.colorsway, colorsway = _b === void 0 ? [] : _b;
                            if (colorsway.length) {
                                layout.colorway = colorsway;
                            }
                            return (_jsx(StyledGrid, __assign({ item: true, xs: 12 / (props.disableBarButtons ? 1 : chartPerRow) }, { children: _jsx(Chart, { data: data, title: t(key, { context: context }), legends: legends, middleLegends: middleLegends, layout: layout, yTickPrefix: yTickPrefix, config: { modeBarButtons: props.disableBarButtons ? [[]] : BAR_BUTTONS } }) }), key));
                        }), props.children] }))] }));
};
export var ResourcingGraphical = function (props) {
    var charts = useResourcingCharts();
    return _jsx(AnnualGraphical, __assign({}, props, { charts: charts }));
};
export var FinancialGraphical = function (props) {
    var t = useTranslation('report', { keyPrefix: 'annual.chart' }).t;
    var _a = useChartPerRow(), year = _a.year, _b = _a.scenarios, scenarios = _b === void 0 ? [] : _b, currency = _a.currency;
    var _c = handleLayout(scenarios, year), costPerWorkLegend = _c.legends, colorsByIndex = _c.colorsByIndex;
    var charts = useFinancialCharts();
    var cost = useCostPerWorkActivity();
    var context = usePlatformContext();
    var handleCostPerWorkChart = function (index) {
        var yearSuffix = cost.length > 1 ? " - Year ".concat(index + 1) : '';
        var title = "".concat(t('costPerWorkActivity', { context: context })).concat(yearSuffix);
        var layoutConfig = _.cloneDeep(layoutDefault);
        layoutConfig.showlegend = false;
        _.set(layoutConfig, 'yaxis.linewidth', 6);
        _.set(layoutConfig, 'yaxis.linecolor', '#D7D7D7');
        _.set(layoutConfig, 'xaxis.linewidth', 6);
        _.set(layoutConfig, 'xaxis.linecolor', '#D7D7D7');
        layoutConfig.colorway = colorsByIndex;
        return { layout: layoutConfig, title: title };
    };
    return (_jsx(AnnualGraphical, __assign({}, props, { charts: charts }, { children: cost.map(function (costChart, index) {
            var _a = handleCostPerWorkChart(index), title = _a.title, costPerWorkLayout = _a.layout;
            return (_jsx(Grid, __assign({ item: true, xs: 12 }, { children: _jsx(Chart, { data: costChart, title: title, legends: costPerWorkLegend, layout: costPerWorkLayout, yTickPrefix: currency, config: { modeBarButtons: props.disableBarButtons ? [[]] : BAR_BUTTONS } }) }), title));
        }) })));
};
