import { useCallback, useState } from 'react';
export var useDialog = function () {
    var _a = useState(), dialogMode = _a[0], setDialogMode = _a[1];
    var _b = useState(), data = _b[0], setData = _b[1];
    var handleOpenDialog = useCallback(function (mode, _data) {
        setDialogMode(mode);
        setData === null || setData === void 0 ? void 0 : setData(_data);
    }, []);
    var handleCloseDialog = useCallback(function () {
        setDialogMode(undefined);
    }, []);
    return { dialogMode: dialogMode, data: data, handleOpenDialog: handleOpenDialog, handleCloseDialog: handleCloseDialog };
};
