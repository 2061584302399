var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { usePlanType } from '../selectors';
import { BasePanel } from './basePanel';
import { QueuePanel } from './queuePanel';
import { StepContainer, StepPanel } from './stepContainer';
export var TableComponent = function (_a) {
    var name = _a.name, statusType = _a.statusType, children = _a.children;
    var t = useTranslation('wizard', { keyPrefix: name }).t;
    var planType = usePlanType();
    return (_jsx(StepContainer, __assign({ leftContent: _jsx(QueuePanel, {}) }, { children: _jsx(StepPanel, { children: _jsx(BasePanel, __assign({ title: t('title'), description: t('message', { planType: planType, defaultValue: '' }), statusType: statusType }, { children: children })) }) })));
};
