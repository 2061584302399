var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Backdrop, Box, CircularProgress, styled } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
var BackdropComponent = styled(Backdrop)(function (_a) {
    var theme = _a.theme;
    return ({
        backgroundColor: 'rgba(0, 0, 0, 0)',
        zIndex: theme.zIndex.drawer + 1,
        color: theme.palette.primary.main,
    });
});
var FallbackOverlay = styled(Box)(function () { return ({
    display: 'flex',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 99,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
}); });
export var FallbackComponent = function (props) {
    var _a = useState(props.requesting), requesting = _a[0], setRequesting = _a[1];
    var showTime = useRef(0);
    useEffect(function () {
        if (props.requesting) {
            setRequesting(props.requesting);
            showTime.current = new Date().getMilliseconds();
        }
        else {
            var timeoutId_1 = setTimeout(function () {
                setRequesting(props.requesting);
                showTime.current = 0;
            }, Math.max(500 - new Date().getMilliseconds() + showTime.current, 0));
            return function () {
                clearTimeout(timeoutId_1);
            };
        }
    }, [props.requesting]);
    var fallback = (_jsx(Box, __assign({ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }, { children: props.backdrop ? (_jsx(BackdropComponent, __assign({ open: true }, { children: _jsx(CircularProgress, {}) }))) : (_jsx(CircularProgress, {})) })));
    if (requesting && !props.overlay)
        return _jsx(_Fragment, { children: fallback });
    return (_jsxs(_Fragment, { children: [props.children, requesting && props.overlay && _jsx(FallbackOverlay, __assign({ "data-testid": "fallback-overlay" }, { children: fallback }))] }));
};
