var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton, Menu, buttonBaseClasses, styled } from '@mui/material';
import { useState } from 'react';
var StyledMenuButton = styled(IconButton)(function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = {
            color: theme.palette.common.black,
            margin: '0 auto'
        },
        _b["&.".concat(buttonBaseClasses.root, ":hover")] = {
            backgroundColor: 'unset',
        },
        _b);
});
export var MenuButton = function (props) {
    var _a = useState(), anchorEl = _a[0], setAnchorEl = _a[1];
    var handleClick = function (event) {
        setAnchorEl(event.currentTarget);
    };
    var handleClose = function () {
        setAnchorEl(undefined);
    };
    return (_jsxs(_Fragment, { children: [_jsx(StyledMenuButton, __assign({ onClick: handleClick, disabled: props.disabled }, { children: _jsx(FontAwesomeIcon, { icon: "ellipsis-vertical" }) })), _jsx(Menu, __assign({ anchorEl: anchorEl, open: !!anchorEl, onClose: handleClose, onClick: handleClose, anchorOrigin: {
                    vertical: 'center',
                    horizontal: 'center',
                } }, { children: props.children }))] }));
};
