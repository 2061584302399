var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import _ from 'lodash';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePlatformContext } from '../../app/selectors';
import { Autocomplete, ConfirmDialog, FormField, InputLabel } from '../../common';
import { TIME_INTERVAL } from '../constants';
import { WizardContext } from '../context';
import { useCancelAndDeleteMultiskill, usePlanStatus, useQueuesHasMultiskill } from '../queue/selectors';
import { QueueStatusType } from '../queue/types';
import { usePlanAPI } from '../selectors';
import { CLOSED, useChangeIntervalAPI } from './selectors';
export var TimeIntervalSelect = function (_a) {
    var _b;
    var control = _a.control, watch = _a.watch, getValues = _a.getValues, setValue = _a.setValue;
    var t = useTranslation('wizard', { keyPrefix: 'wapTime.interval' }).t;
    var hasWAP = usePlanStatus(QueueStatusType.WAT);
    var planData = usePlanAPI()[0];
    var _c = watch().items, items = _c === void 0 ? {} : _c;
    var _d = useState(), pendingInterval = _d[0], setPendingInterval = _d[1];
    var _e = useChangeIntervalAPI(), changeInterval = _e[1];
    var context = usePlatformContext();
    var fetchCancelAndDeleteMultiskill = useCancelAndDeleteMultiskill();
    var _f = useContext(WizardContext), handleSave = _f.handleSave, isDirty = _f.methods.formState.isDirty;
    var hasMultiskill = useQueuesHasMultiskill();
    var handleChangeInterval = function (interval) {
        if (hasWAP) {
            setPendingInterval(interval);
            return false;
        }
        _.forEach(items, function (wats, month) {
            return wats.forEach(function (wat, day) {
                var value = getValues("items.".concat(month, ".").concat(day, ".closeTime"));
                if (value !== CLOSED) {
                    setValue("items.".concat(month, ".").concat(day, ".closeTime"), null);
                }
            });
        });
    };
    var handleContinue = function () {
        var _a;
        if (hasMultiskill) {
            fetchCancelAndDeleteMultiskill();
        }
        if (isDirty) {
            setPendingInterval(undefined);
            return handleSave('submit', function () { var _a; return changeInterval({ basePlanId: (_a = planData.data) === null || _a === void 0 ? void 0 : _a.basePlanId, timeInterval: pendingInterval }); })();
        }
        changeInterval({ basePlanId: (_a = planData.data) === null || _a === void 0 ? void 0 : _a.basePlanId, timeInterval: pendingInterval });
        setPendingInterval(undefined);
    };
    return (_jsxs(_Fragment, { children: [_jsx(InputLabel, { children: t('label') }), _jsx(FormField, __assign({ name: "timeInterval", control: control }, { children: _jsx(Autocomplete, { sx: { width: '6.625rem' }, options: TIME_INTERVAL, "data-testid": "intervalSelect", disabled: !((_b = planData.data) === null || _b === void 0 ? void 0 : _b.isBaseline), onChange: handleChangeInterval }) })), _jsx(ConfirmDialog, { open: !!pendingInterval, onClose: function () { return setPendingInterval(undefined); }, message: t("".concat(hasMultiskill ? 'confirmMultiskill' : 'confirm'), { context: context }), confirmButtonText: "continue", onConfirm: handleContinue })] }));
};
