export var DialogMode;
(function (DialogMode) {
    DialogMode[DialogMode["DeleteScenario"] = 0] = "DeleteScenario";
    DialogMode[DialogMode["RenameScenario"] = 1] = "RenameScenario";
    DialogMode[DialogMode["SwitchScenario"] = 2] = "SwitchScenario";
    DialogMode[DialogMode["RenameAfterSwitch"] = 3] = "RenameAfterSwitch";
    DialogMode[DialogMode["RenameFinancial"] = 4] = "RenameFinancial";
    DialogMode[DialogMode["Multiskill"] = 5] = "Multiskill";
    DialogMode[DialogMode["MultiskillDelete"] = 6] = "MultiskillDelete";
    DialogMode[DialogMode["DeleteAllMultiskill"] = 7] = "DeleteAllMultiskill";
})(DialogMode || (DialogMode = {}));
export var ConstraintType;
(function (ConstraintType) {
    ConstraintType["DefQueue"] = "defQueue";
    ConstraintType["NonDefQueue"] = "nonDefQueue";
    ConstraintType["Multiskill"] = "multiSkill";
    ConstraintType["Both"] = "both";
})(ConstraintType || (ConstraintType = {}));
