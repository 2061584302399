import { useEffect, useState } from 'react';
var numberToString = function (value) { var _a; return (_a = value === null || value === void 0 ? void 0 : value.toString()) !== null && _a !== void 0 ? _a : ''; };
export var stringToNumber = function (value) {
    return value ? parseFloat(value.replace(/[^0-9.-]/g, '')) : null;
};
var cleanFormattingNumber = function (value, decimal) {
    var result = value.replace(/[^0-9.]/g, '').replace(/^\.*/g, '');
    var output = result.split('.');
    if (!decimal)
        return output[0];
    return "".concat(output.shift()).concat(output.length ? '.' : '').concat(output.join('').substring(0, decimal));
};
export var getNumberValue = function (value, _a) {
    var _b = _a.decimal, decimal = _b === void 0 ? 0 : _b, _c = _a.min, min = _c === void 0 ? 0 : _c, _d = _a.max, max = _d === void 0 ? Number.MAX_SAFE_INTEGER : _d;
    var cleanedValue = cleanFormattingNumber(value, decimal);
    var floatValue = stringToNumber(cleanedValue);
    var isAllowed = floatValue === null || (floatValue >= min && floatValue <= max);
    return { floatValue: floatValue, isAllowed: isAllowed };
};
export var useNumberInput = function (_a) {
    var _b = _a.value, value = _b === void 0 ? null : _b, _c = _a.min, min = _c === void 0 ? 0 : _c, _d = _a.max, max = _d === void 0 ? Number.MAX_SAFE_INTEGER : _d, _e = _a.decimal, decimalScale = _e === void 0 ? 0 : _e, onChange = _a.onChange;
    var _f = useState(numberToString(value)), inputVal = _f[0], setInputVal = _f[1];
    useEffect(function () {
        setInputVal(function (curr) { return (stringToNumber(curr) !== value ? numberToString(value) : curr); });
    }, [value]);
    var isAllowed = function (_a) {
        var floatValue = _a.floatValue;
        return floatValue === undefined || (floatValue >= min && floatValue <= max);
    };
    var onValueChange = function (_a) {
        var _b = _a.floatValue, floatValue = _b === void 0 ? null : _b, numAsString = _a.value;
        setInputVal(numAsString);
        if (floatValue !== stringToNumber(inputVal)) {
            onChange === null || onChange === void 0 ? void 0 : onChange(floatValue);
        }
    };
    return {
        thousandSeparator: true,
        allowNegative: min < 0,
        decimalScale: decimalScale,
        fixedDecimalScale: true,
        isAllowed: isAllowed,
        onValueChange: onValueChange,
        value: inputVal,
    };
};
