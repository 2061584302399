import { useCallback } from 'react';
import { useAPI } from '../../../state';
import { useWizardParams } from '../selectors';
var ROLE_API = '/api/role/{queueId}';
var ROLES_API = '/api/role';
var TRAINER_QA_API = '/api/report/wizard/trainer-qa';
export var useAddRoleAPI = function () {
    return useAPI(ROLES_API, undefined, 'POST');
};
export var useClearRoleAPI = function (financial) {
    var queueId = useWizardParams().queueId;
    var _a = useAPI(ROLE_API, { queueId: queueId }, 'DELETE'), data = _a[0], fetchData = _a[1], clearData = _a[2];
    var fetchDataWrapper = useCallback(function () {
        fetchData(undefined, { financial: financial });
    }, [fetchData, financial]);
    return [data, fetchDataWrapper, clearData];
};
export var useCopyRoleAPI = function (financial) {
    var queueId = useWizardParams().queueId;
    var _a = useAPI(ROLE_API, { queueId: queueId }, 'POST'), data = _a[0], fetchData = _a[1], clearData = _a[2];
    var fetchDataWrapper = useCallback(function (source) {
        fetchData(undefined, { source: source, financial: financial });
    }, [fetchData, financial]);
    return [data, fetchDataWrapper, clearData];
};
export var useTrainerQaAPI = function () {
    var queueId = useWizardParams().queueId;
    var _a = useAPI(TRAINER_QA_API), data = _a[0], fetchData = _a[1], clearData = _a[2];
    var fetchDataWrapper = useCallback(function () {
        if (queueId) {
            fetchData(undefined, { queueId: queueId });
        }
    }, [fetchData, queueId]);
    return [data, fetchDataWrapper, clearData];
};
