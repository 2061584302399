var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faStarHalfStroke } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Link, Stack, Typography, buttonClasses, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
var QueueSelection = styled(Button)(function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = {},
        _b["&.".concat(buttonClasses.root)] = {
            justifyContent: 'flex-start !important',
            margin: theme.spacing(1, 0),
            gap: theme.spacing(3),
            ':disabled': {
                color: "".concat(theme.palette.text.primary, " !important"),
                backgroundColor: 'transparent',
                borderColor: "".concat(theme.palette.primary.main, " !important"),
            },
        },
        _b);
});
var MultiSkillGroup = function (_a) {
    var queue = _a.queue, index = _a.index, value = _a.value, onChange = _a.onChange;
    var active = index === value;
    return (_jsxs(QueueSelection, __assign({ variant: "outlined", fullWidth: true, disabled: active, onClick: function () { return onChange(index); } }, { children: [_jsx(Typography, __assign({ variant: "h3" }, { children: _jsx(FontAwesomeIcon, { icon: faStarHalfStroke }) })), _jsx(Box, __assign({ textAlign: "left" }, { children: _jsx(Typography, __assign({ fontWeight: "bold" }, { children: queue.businessArea })) }))] })));
};
export var MultiSkillGroups = function (_a) {
    var data = _a.data, onModify = _a.onModify, props = __rest(_a, ["data", "onModify"]);
    var t = useTranslation('multiSkill', { keyPrefix: 'edit' }).t;
    return (_jsxs(Stack, __assign({ bgcolor: "primary.light", p: 8, alignItems: "flex-start" }, { children: [_jsx(Typography, __assign({ variant: "h3", mb: 5 }, { children: t('listMultiSkillGroups') })), _jsxs(Box, __assign({ mb: 5 }, { children: [_jsx(Typography, { children: t('selectGroupToViewOrEdit') }), _jsx(Link, __assign({ component: "button", type: "button", onClick: onModify }, { children: t('modifyGroups') }))] })), data.map(function (q, idx) { return (_jsx(MultiSkillGroup, __assign({}, props, { queue: q, index: idx }), q.businessArea)); })] })));
};
