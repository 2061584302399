var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { LoadingButton } from '@mui/lab';
import { Box, Collapse, Link, Stack, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useToggle } from 'usehooks-ts';
import { ErrorMessageBox, FormField, InputLabel } from '../../common';
import { usePlanAPI } from '../selectors';
export var AddNameForm = function (_a) {
    var _b;
    var maxLength = _a.maxLength, loading = _a.loading, disabled = _a.disabled, props = __rest(_a, ["maxLength", "loading", "disabled"]);
    var t = useTranslation().t;
    var _c = useToggle(), showForm = _c[0], toggle = _c[1];
    var planData = usePlanAPI()[0];
    if (!((_b = planData.data) === null || _b === void 0 ? void 0 : _b.isBaseline))
        return null;
    return (_jsxs(Box, __assign({ mt: 4 }, { children: [_jsx(Link, __assign({ disabled: disabled, component: "button", onClick: toggle }, { children: props.toggleText })), _jsx(Collapse, __assign({ in: showForm }, { children: _jsxs(Box, __assign({ mt: 2, component: "form", onSubmit: props.onSubmit, "data-testid": "addNameForm" }, { children: [_jsx(ErrorMessageBox, { errors: props.errors }), _jsx(InputLabel, __assign({ required: true }, { children: props.label })), _jsxs(Stack, __assign({ direction: "row", sx: { alignItems: 'flex-start' }, spacing: 2 }, { children: [_jsx(FormField, __assign({ flex: 1, maxWidth: "".concat(maxLength * 0.35, "rem"), required: true, control: props.control, name: props.name }, { children: _jsx(TextField, { inputProps: {
                                            maxLength: maxLength,
                                        } }) })), _jsx(LoadingButton, __assign({ type: "submit", variant: "outlined", loading: loading }, { children: t('action.add') }))] }))] })) }))] })));
};
