import * as yup from 'yup';
import { MFAType, MFAValidationType } from '../../enum';
export var validateForm = yup.object().shape({
    type: yup.string(),
    validation: yup.string(),
    deviceMode: yup.number().optional(),
    secretKey: yup.string(),
    userCode: yup.string().when(['type', 'deviceMode'], {
        is: function (type, deviceMode) { return type === MFAType.TOTP && !!deviceMode; },
        then: function (schema) { return schema.required().length(6).label('Code'); },
    }),
    friendlyDeviceName: yup.string(),
    phone: yup.string().when('type', {
        is: MFAType.SMS,
        then: function (schema) { return schema.phone().required(); },
    }),
});
export var MFAOptions = [
    {
        id: MFAType.TOTP,
        name: 'Authenticator App',
    },
    {
        id: MFAType.SMS,
        name: 'SMS',
    },
];
export var MFAValidationOptions = [
    {
        id: MFAValidationType.QRCode,
        name: 'QR Code',
    },
    {
        id: MFAValidationType.SecretKey,
        name: 'Secret Key',
    },
];
