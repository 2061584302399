var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { createSelector } from '@reduxjs/toolkit';
import _ from 'lodash';
import { useCallback, useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useAPI } from '../../state';
import { toInt } from '../../util';
import { pickNumberValues } from '../wizard/useDefaultValue';
import { QueueType } from './types';
var MULTI_SKILL_STEP = 2;
var MULTI_SKILL_API = '/api/multiskill/{planId}';
var selectQueues = function (queues) { return queues !== null && queues !== void 0 ? queues : []; };
var selectQueueMap = createSelector(selectQueues, function (queues) { return _.keyBy(queues, 'id'); });
var selectQueueMapByName = createSelector(selectQueues, function (queues) { return _.keyBy(queues, 'name'); });
var selectMultiSkillGroups = createSelector(selectQueues, function (queues) {
    return queues
        .filter(function (queue) { return queue.queueType === QueueType.Multiskill; })
        .map(function (v) { return (__assign(__assign({}, pickNumberValues(v, ['id', 'businessArea', 'children', 'multiskillType', 'isUseWAP'], 'maxOccupancy')), { _children: __spreadArray([], v.children, true) })); });
});
var selectSingleQueues = createSelector(selectQueues, function (queues) {
    return queues.filter(function (queue) { return queue.id && queue.queueType === QueueType.SingleSkill; });
});
export var selectIsValidGroup = function (ids) {
    return createSelector(selectQueueMap, function (queueMap) {
        var matches = ids.map(function (id) { var _a; return (_a = queueMap[id]) === null || _a === void 0 ? void 0 : _a.matches; });
        return _.intersection.apply(_, __spreadArray([ids], matches, false)).length === ids.length;
    });
};
var createNewMultiSkillGroups = function (desQueueByName) {
    return createSelector([selectMultiSkillGroups, selectQueueMap], function (sourceGroups, queueMap) {
        return sourceGroups.map(function (group) { return (__assign(__assign({}, group), { children: group.children.map(function (childId) { var _a; return (_a = desQueueByName[queueMap[childId].name]) === null || _a === void 0 ? void 0 : _a.id; }), id: undefined })); });
    });
};
export var useMultiSkillAPI = function () {
    var planId = useParams().id;
    return useAPI(MULTI_SKILL_API, { planId: planId });
};
export var useCopyMultiSkillAPI = function () {
    var searchParams = useSearchParams()[0];
    var copyScenarioId = searchParams.get('copyScenarioId');
    var _a = useAPI(MULTI_SKILL_API, { planId: copyScenarioId }), data = _a[0], fetchData = _a[1], clearData = _a[2];
    var fetchDataWrapper = useCallback(function () {
        if (copyScenarioId) {
            fetchData();
        }
    }, [copyScenarioId, fetchData]);
    return [data, fetchDataWrapper, clearData];
};
export var useQueueByIds = function (ids) {
    var data = useMultiSkillAPI()[0].data;
    return ids.map(function (childId) { return selectQueueMap(data)[childId]; });
};
export var useQueueById = function (id) {
    var data = useMultiSkillAPI()[0].data;
    return selectQueueMap(data)[id];
};
export var useSingleSkills = function () {
    var data = useMultiSkillAPI()[0].data;
    return selectSingleQueues(data);
};
export var useMultiSkillGroups = function () {
    var data = useMultiSkillAPI()[0].data;
    return selectMultiSkillGroups(data);
};
export var useGetCopiedGroups = function () {
    var data = useMultiSkillAPI()[0].data;
    var sourceData = useCopyMultiSkillAPI()[0].data;
    var desQueueByName = selectQueueMapByName(data);
    var selectNewGroups = useMemo(function () { return createNewMultiSkillGroups(desQueueByName); }, [desQueueByName]);
    return selectNewGroups(sourceData);
};
export var useMultiSkillParams = function () {
    var _a = useParams(), basePlanId = _a.basePlanId, id = _a.id;
    var searchParams = useSearchParams()[0];
    return useMemo(function () {
        var step = Math.max(Math.min(toInt(searchParams.get('step'), 0), MULTI_SKILL_STEP - 1), 0);
        return {
            basePlanId: toInt(basePlanId),
            id: toInt(id),
            step: step,
        };
    }, [basePlanId, id, searchParams]);
};
