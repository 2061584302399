var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { ErrorMessageBox, FallbackComponent, FormField } from '../common';
import { useDuplicatePlanAPI, usePlansAPI } from './selectors';
var validateForm = yup.object().shape({
    name: yup.string().required().max(128).trim().specialCharacter().quotesNotAllowed().label('Name'),
});
export var DuplicatePlanDialog = function (_a) {
    var open = _a.open, onClose = _a.onClose, plan = _a.plan;
    var t = useTranslation(['myPlans', 'translation']).t;
    var _b = usePlansAPI(), fetchPlans = _b[1];
    var _c = useDuplicatePlanAPI(), duplicatePlanData = _c[0], duplicatePlan = _c[1], clearDuplicatePlanData = _c[2];
    var defaultValues = useMemo(function () {
        var _a;
        return ({
            basePlanId: (_a = plan === null || plan === void 0 ? void 0 : plan.basePlanId) !== null && _a !== void 0 ? _a : 0,
            name: "".concat(t('copyOf'), " ").concat(plan === null || plan === void 0 ? void 0 : plan.name),
        });
    }, [plan, t]);
    var _d = useForm({
        defaultValues: defaultValues,
        resolver: yupResolver(validateForm),
    }), control = _d.control, handleSubmit = _d.handleSubmit, reset = _d.reset;
    useEffect(function () {
        if (open) {
            reset(defaultValues);
            return clearDuplicatePlanData;
        }
    }, [open, reset, clearDuplicatePlanData, defaultValues]);
    useEffect(function () {
        if (duplicatePlanData.data) {
            onClose();
            fetchPlans();
        }
    }, [duplicatePlanData.data, onClose, fetchPlans]);
    return (_jsx(Dialog, __assign({ open: open, onClose: onClose }, { children: _jsxs(Box, __assign({ component: "form", onSubmit: handleSubmit(function (values) { return duplicatePlan(values); }) }, { children: [_jsx(DialogTitle, __assign({ variant: "h2" }, { children: t('duplicatePlanTitle') })), _jsx(DialogContent, { children: _jsxs(FallbackComponent, __assign({ requesting: !!duplicatePlanData.requesting, overlay: true }, { children: [_jsx(ErrorMessageBox, { errors: [duplicatePlanData.error] }), _jsx(FormField, __assign({ required: true, name: "name", label: t('planName'), control: control }, { children: _jsx(TextField, { inputProps: {
                                        maxLength: 128,
                                    } }) }))] })) }), _jsxs(DialogActions, { children: [_jsx(Button, __assign({ variant: "outlined", onClick: onClose }, { children: t('translation:action.cancel') })), _jsx(Button, __assign({ variant: "contained", type: "submit", disabled: duplicatePlanData.requesting }, { children: t('translation:action.save') }))] })] })) })));
};
