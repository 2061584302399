import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfirmDialog } from '../common';
import { useReportAPI } from '../report/selectors';
import { useDeleteMultiskill, useSummaryReportAPI } from './selectors';
export var MultiskillDeleteDialog = function (_a) {
    var open = _a.open, scenario = _a.scenario, onClose = _a.onClose;
    var t = useTranslation(['report', 'translation']).t;
    var _b = useDeleteMultiskill(scenario === null || scenario === void 0 ? void 0 : scenario.id), deleteMultiskillData = _b[0], deleteMultiskill = _b[1], clearDeleteMultiskill = _b[2];
    var _c = useReportAPI(), fetchData = _c[1];
    var _d = useSummaryReportAPI(), fetchSummaryData = _d[1];
    useEffect(function () {
        if (open && deleteMultiskillData.data) {
            onClose();
            fetchData();
            fetchSummaryData();
        }
        return clearDeleteMultiskill;
    }, [deleteMultiskillData.data, fetchSummaryData, onClose, fetchData, open, clearDeleteMultiskill]);
    var handleDeleteMultiskill = function () {
        deleteMultiskill();
    };
    return (_jsx(ConfirmDialog, { open: open, onClose: onClose, title: t('translation:confirmation.title'), message: t('annual.multiskill.deleteScenarioContent'), confirmButtonText: "continue", onConfirm: handleDeleteMultiskill }));
};
