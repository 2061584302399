var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Stack, Typography, styled } from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { usePlatformContext } from '../../app/selectors';
import { Autocomplete } from '../../common';
import { WizardContext } from '../context';
import { useQueuesAPI } from '../queue/selectors';
import { usePlanAPI, useWizardParams } from '../selectors';
import { usePlanColor } from './selectors';
var StyledTitleBar = styled(Stack)(function (_a) {
    var theme = _a.theme;
    return ({
        color: theme.palette.common.white,
    });
});
var StyledAutocomplete = styled(Autocomplete)(function (_a) {
    var theme = _a.theme;
    return ({
        width: theme.typography.pxToRem(440),
    });
});
export var TitleBar = function () {
    var _a, _b;
    var t = useTranslation('wizard', { keyPrefix: 'copyData' }).t;
    var queuesData = useQueuesAPI()[0];
    var queueId = useWizardParams().queueId;
    var bgcolor = usePlanColor()[0];
    var planData = usePlanAPI()[0];
    var _c = useContext(WizardContext), handleSave = _c.handleSave, actionRef = _c.actionRef, requesting = _c.requesting, goToQueue = _c.goToQueue;
    var context = usePlatformContext();
    return (_jsxs(StyledTitleBar, __assign({ bgcolor: bgcolor, padding: 4, borderRadius: 1, direction: "row", alignItems: "center", gap: 4 }, { children: [_jsx(Typography, __assign({ variant: "subtitle1" }, { children: ((_a = planData.data) === null || _a === void 0 ? void 0 : _a.isBaseline) ? 'Baseline' : (_b = planData.data) === null || _b === void 0 ? void 0 : _b.name })), _jsx(Typography, __assign({ marginLeft: "auto" }, { children: t('placeholder', { context: context }) })), _jsx(StyledAutocomplete, { value: queueId, options: queuesData.data, onChange: function (id) { return handleSave('switchQueue', function () { return goToQueue(id); })(); }, loading: actionRef.current === 'switchQueue' && requesting })] })));
};
