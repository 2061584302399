var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import _ from 'lodash';
import { PLAN_COLORS } from '../wizard/constants';
import { ANNUAL_LAYOUT, QUEUE_COLOR } from './constants';
export var handleLayout = function (scenarios, year) {
    if (year === void 0) { year = 1; }
    var tickText = [];
    var textHover = [];
    var legends = [];
    scenarios.forEach(function (_a) {
        var name = _a.name, isBaseline = _a.isBaseline, colorIndex = _a.colorIndex;
        var color = PLAN_COLORS[colorIndex][0];
        var cName = isBaseline ? 'Baseline' : name;
        if (year === 1) {
            tickText.push("<span style=\"fill:".concat(color, "\"><b>").concat(cName, "</b></span>"));
            textHover.push(cName);
        }
        else {
            _.times(year, function (i) {
                tickText.push("<span style=\"fill:".concat(color, "\">Year ").concat(i + 1, "</span>"));
                textHover.push("".concat(cName, " - Year ").concat(i + 1));
            });
        }
        legends.push({ text: cName, color: PLAN_COLORS[colorIndex][0] });
    });
    var colorsByIndex = _.map(legends, 'color');
    var tickvals = _.range(year * scenarios.length);
    var layout = _.cloneDeep(ANNUAL_LAYOUT);
    if (layout.xaxis) {
        layout.xaxis.ticktext = tickText;
        layout.xaxis.tickvals = tickvals;
    }
    return { textHover: textHover, layout: layout, legends: legends, colorsByIndex: colorsByIndex };
};
export var generateQueueRoleLegend = function (data) {
    var colors = QUEUE_COLOR;
    if (data.length > QUEUE_COLOR.length) {
        var loopNumber = Math.ceil(data.length / QUEUE_COLOR.length);
        colors = _.flatMap(_.times(loopNumber), function () { return QUEUE_COLOR; });
    }
    return { legends: data.map(function (item, index) { return ({ text: item, color: colors[index] }); }), colorsway: colors };
};
export var replaceNameInTemplate = function (chartData) {
    return chartData.map(function (data) {
        var hovertemplate = data.hovertemplate, name = data.name;
        if (hovertemplate && typeof hovertemplate === 'string') {
            return __assign(__assign({}, data), { hovertemplate: hovertemplate === null || hovertemplate === void 0 ? void 0 : hovertemplate.replace('value', name !== null && name !== void 0 ? name : '') });
        }
        return data;
    });
};
export var handleTemplate = function (_a) {
    var _b = _a.currency, currency = _b === void 0 ? '' : _b, isCostPerWork = _a.isCostPerWork, isFormatNumber = _a.isFormatNumber, isSimple = _a.isSimple;
    var hovertemplate = "<b style=\"color:#fff\"> %{hovertext}</b> <br> <span style=\"color:#fff\">value</span>: <span style=\"color:#fff\">".concat(currency, "%{y:,.0f}</span>  <extra></extra>");
    var formatNumber = 'y:,';
    if (isSimple)
        hovertemplate = "<b style=\"color:#fff\"> %{hovertext}</b>: <span style=\"color:#fff\">".concat(currency, "%{y:,.0f}</span><extra></extra>");
    if (isCostPerWork) {
        formatNumber = 'y:,.2f';
        hovertemplate = "<b style=\"color:#fff\"> %{hovertext}</b> <br> <span style=\"color:#fff\">value</span>: <span style=\"color:#fff\">".concat(currency, "%{y:,.2f}</span><extra></extra>");
    }
    if (isFormatNumber)
        formatNumber = 'text';
    return { hovertemplate: hovertemplate, texttemplate: "".concat(currency, "%{").concat(formatNumber, "}") };
};
export var formatNumberWithPrefix = function (num) {
    var prefixes = ['', 'k', 'M', 'B', 'T'];
    var i = Math.floor(Math.log(num) / Math.log(1000));
    return num ? (num / Math.pow(1000, i)).toFixed(2) + prefixes[i] : String(num);
};
