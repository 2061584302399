var _a, _b;
import { QueueStatusType } from './queue/types';
export var STEPS_LENGTH = 9;
export var TIME_INTERVAL = [
    { id: 15, name: '15 min' },
    { id: 30, name: '30 min' },
    { id: 60, name: '60 min' },
];
export var WORK_TYPE_ICON = [
    'phone-arrow-down-left',
    'phone-arrow-up-right',
    'envelope',
    'message-dots',
    'message-sms',
    'share-nodes',
    'envelope-open-text',
    'fax',
    'files',
    'square-list',
];
export var WizardStep;
(function (WizardStep) {
    WizardStep[WizardStep["Queues"] = 0] = "Queues";
    WizardStep[WizardStep["WorkVolume"] = 1] = "WorkVolume";
    WizardStep[WizardStep["HandleTime"] = 2] = "HandleTime";
    WizardStep[WizardStep["Shrinkage"] = 3] = "Shrinkage";
    WizardStep[WizardStep["Attrition"] = 4] = "Attrition";
    WizardStep[WizardStep["WorkingDays"] = 5] = "WorkingDays";
    WizardStep[WizardStep["SupportStaff"] = 6] = "SupportStaff";
    WizardStep[WizardStep["Financial"] = 7] = "Financial";
    WizardStep[WizardStep["Review"] = 8] = "Review";
})(WizardStep || (WizardStep = {}));
export var STEP_NAME = (_a = {},
    _a[WizardStep.Queues] = 'queue',
    _a[WizardStep.WorkVolume] = 'workVolume',
    _a[WizardStep.HandleTime] = 'handleTime',
    _a[WizardStep.Shrinkage] = 'shrinkage',
    _a[WizardStep.Attrition] = 'attrition',
    _a[WizardStep.WorkingDays] = 'workingDay',
    _a[WizardStep.SupportStaff] = 'supportStaff',
    _a[WizardStep.Financial] = 'financial',
    _a[WizardStep.Review] = 'review',
    _a);
export var STATUS_BY_STEP = (_b = {},
    _b[WizardStep.WorkVolume] = [QueueStatusType.Volume, QueueStatusType.WAP],
    _b[WizardStep.HandleTime] = [QueueStatusType.HandleTime],
    _b[WizardStep.Attrition] = [QueueStatusType.Attrition],
    _b[WizardStep.Shrinkage] = [QueueStatusType.Shrinkages],
    _b[WizardStep.WorkingDays] = [QueueStatusType.WorkingDays],
    _b);
export var DAY_NAMES = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
export var GENERAL_MONTH = -1;
export var PLAN_COLORS = [
    ['#333366', '#D7E3F1'],
    ['#b25c03', '#f2d5b7'],
    ['#136a21', '#bce1c2'],
    ['#994f4f', '#e8cfd2'],
    ['#666633', '#e1e0cb'],
    ['#5c0276', '#dbd0f5'],
];
