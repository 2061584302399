var _a, _b, _c, _d;
import { PlanType } from '../../enum';
import { REPORT_ROUTER_URL } from '../../router';
export var ActionType;
(function (ActionType) {
    ActionType["Export"] = "export";
    ActionType["Print"] = "print";
})(ActionType || (ActionType = {}));
export var PdfType;
(function (PdfType) {
    PdfType["AnnualChart"] = "annualChart";
    PdfType["PeriodChart"] = "periodChart";
})(PdfType || (PdfType = {}));
export var REPORT_OPTIONS = [
    { name: 'Summary', nameMonthly: 'Annual plan', id: REPORT_ROUTER_URL.Annual },
    { name: 'Weekly plan', nameMonthly: 'Monthly plan', id: REPORT_ROUTER_URL.Period },
    { name: 'Daily plan', id: REPORT_ROUTER_URL.Daily },
];
export var FILE_NAME_BY_REPORT = (_a = {},
    _a[REPORT_ROUTER_URL.Annual] = (_b = {},
        _b[PlanType.Weekly] = 'Summary',
        _b[PlanType.Monthly] = 'Annual',
        _b),
    _a);
export var FILE_NAME_BY_TAB = (_c = {},
    _c[REPORT_ROUTER_URL.Resourcing] = 'Resources',
    _c[REPORT_ROUTER_URL.Financial] = 'Financials',
    _c);
export var FILE_EXT = (_d = {},
    _d[ActionType.Export] = 'xlsx',
    _d[ActionType.Print] = 'pdf',
    _d);
