var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useMultiSkillParams } from '../selectors';
export var CRITERIAL_FIELDS = [
    'interval',
    'targetService',
    'hoursPerDay',
    'agentHours',
    'chatConcurrency',
    'combineChats',
];
export var AttentionDialog = function (_a) {
    var isInvalidGroup = _a.isInvalidGroup;
    var t = useTranslation(['translation', 'multiSkill']).t;
    var _b = useState(isInvalidGroup), dialogOpen = _b[0], setDialogOpen = _b[1];
    var basePlanId = useMultiSkillParams().basePlanId;
    return (_jsxs(Dialog, __assign({ open: dialogOpen, onClose: function () { return setDialogOpen(false); } }, { children: [_jsxs(DialogTitle, __assign({ variant: "h1", display: "flex", flexDirection: "row", alignItems: "center", gap: 3 }, { children: [_jsx(Typography, __assign({ color: "warning.main" }, { children: _jsx(FontAwesomeIcon, { icon: "triangle-exclamation", size: "2x" }) })), t('multiSkill:add.attention')] })), _jsx(DialogContent, { children: _jsxs(Stack, __assign({ display: "flex", flexDirection: "column", gap: 4 }, { children: [_jsx(Typography, { children: t('multiSkill:add.attentionMessage') }), _jsx(Stack, __assign({ pl: 4 }, { children: CRITERIAL_FIELDS.map(function (item) { return (_jsx(Typography, __assign({ component: "li" }, { children: t("multiSkill:add.".concat(item)) }), item)); }) })), _jsx(Typography, __assign({ variant: "subtitle2" }, { children: t('multiSkill:add.confirm') }))] })) }), _jsxs(DialogActions, { children: [_jsx(Button, __assign({ component: Link, to: "/report/".concat(basePlanId), variant: "outlined" }, { children: t('translation:action.cancel') })), _jsx(Button, __assign({ variant: "contained", sx: { ml: 3 }, onClick: function () { return setDialogOpen(false); } }, { children: t("translation:action.continue") }))] })] })));
};
